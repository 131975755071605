import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { WhatsAppIcon } from '../Icons/WhatsAppIcon'
import { AnalyticsAction, logEvent } from '@/lib/analytics'
import { useConfiguration } from '@/lib/configuration'
import { useIsMobile } from '@hooks/responsive'

const useStyles = makeStyles({
  listContainer: {
    '& .MuiListItem-gutters': {
      paddingLeft: '0',
    },
    '& .MuiListItem-root': {
      paddingTop: '0',
    },
  },
  icon: { verticalAlign: 'top' },
})

export const ContactDialog = ({ open, closeHandler }) => {
  const { t } = useTranslation(['common', 'navbar'])

  const isMobile = useIsMobile()
  const classes = useStyles()
  const { helpEmail, contactHours, helpWhatsapp } = useConfiguration()

  if (!contactHours) return null

  const formatWhatsAppNumber = helpWhatsapp?.replace(
    /(\d{2})(\d{1})(\d{2})(\d{4})(\d{4})/,
    '+$1 $2 $3 $4-$5'
  )

  const hasContactRange = rangeKey =>
    contactHours[rangeKey] &&
    contactHours[rangeKey].openingTime &&
    contactHours[rangeKey].closingTime

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="responsive-dialog-title"
    >
      <Box px={3} pt={7} pb={3}>
        <DialogTitle id="responsive-dialog-title">
          {t('navbar:contact.need-help')}
        </DialogTitle>
        <DialogContent>
          <Box pt={2.75} pb={1}>
            <DialogContentText>
              <Trans
                ns="navbar"
                i18nKey="contact.send-whatsapp"
                values={{ whats: formatWhatsAppNumber }}
                components={{
                  strong: <strong />,
                  a: (
                    <a
                      href={`https://api.whatsapp.com/send/?phone=${helpWhatsapp}`}
                      target="_blank"
                      onClick={() =>
                        logEvent(AnalyticsAction.OPEN_WHATSAPP_CONTACT, {
                          email_ayuda: helpEmail,
                        })
                      }
                      rel="noreferrer"
                    />
                  ),
                  WhatsAppIcon: <WhatsAppIcon className={classes.icon} />,
                  br: <br />,
                }}
              ></Trans>
              <br />
              <Trans
                ns="navbar"
                i18nKey="contact.send-mail"
                components={{ strong: <strong /> }}
                values={{ mail: helpEmail }}
              />
            </DialogContentText>
          </Box>
          <Box pb={3} className={classes.listContainer}>
            <List>
              <ListItem>
                <ListItemText
                  secondary={`- ${t('navbar:contact.list.username')}`}
                  secondaryTypographyProps={{ color: 'textPrimary' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={`- ${t('navbar:contact.list.full-name')}`}
                  secondaryTypographyProps={{ color: 'textPrimary' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={`- ${t(
                    'navbar:contact.list.institutions-cohorts'
                  )}`}
                  secondaryTypographyProps={{ color: 'textPrimary' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={`- ${t('navbar:contact.list.contact-reason')}`}
                  secondaryTypographyProps={{ color: 'textPrimary' }}
                />
              </ListItem>
            </List>
          </Box>
          <Divider />
          <Box pt={3}>
            <Typography variant="subtitle2" color="textSecondary">
              <Trans
                ns="navbar"
                i18nKey="contact.hours.week"
                components={{ strong: <strong /> }}
                values={contactHours.weekdays || ''}
              />
            </Typography>
          </Box>
          {hasContactRange('weekends') && (
            <Box pt={1}>
              <Typography variant="subtitle2" color="textSecondary">
                <Trans
                  ns="navbar"
                  i18nKey="contact.hours.weekend"
                  components={{ strong: <strong /> }}
                  values={contactHours.weekends || ''}
                />
              </Typography>
            </Box>
          )}
          {hasContactRange('holidays') && (
            <Box pt={1}>
              <Typography variant="subtitle2" color="textSecondary">
                <Trans
                  ns="navbar"
                  i18nKey="contact.hours.holidays"
                  components={{ strong: <strong /> }}
                  values={contactHours.holidays || ''}
                />
              </Typography>
            </Box>
          )}
          <Box py={3}>
            <Typography variant="subtitle2" color="textSecondary">
              <Trans
                ns="navbar"
                i18nKey="contact.help-hint"
                components={{ strong: <strong /> }}
              />
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth={isMobile}
            onClick={closeHandler}
            color="primary"
            variant="contained"
            autoFocus
          >
            {t('common:acciones.entendido')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
