import { AxiosResponse } from 'axios'
import { AxiosInstance } from '../../contexts/impersonation'
import { Tenant } from '@models/Tenant'

export const getTenant = async (
  institutionId: string,
  axios: AxiosInstance
): Promise<Tenant | null> => {
  const { data } = await axios.post<AxiosResponse>(
    Tenant.getPath(),
    {
      query: Tenant.getQuery(institutionId),
    },
    {}
  )

  if (data?.data?.institution?.tenant.id) {
    return data?.data?.institution?.tenant
  }

  return null
}
