import { Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { format } from 'date-fns'
import NextLink from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Project } from '@models/Project'

interface Props {
  project: Project
  editable: boolean
}

export const ProjectDates = ({ project, editable }: Props) => {
  const { t } = useTranslation('home')

  const formattedDates =
    project.startDate &&
    project.endDate &&
    `${format(project.startDate, 'dd/MM/yyyy')} - ${format(
      project.endDate,
      'dd/MM/yyyy'
    )}`

  if (!editable) {
    return (
      <Typography variant="body2" component="span">
        {formattedDates}
      </Typography>
    )
  }

  return (
    <>
      <NextLink href={project.getAssignmentRoute()} passHref>
        <Link
          color="secondary"
          variant="body2"
          underline="always"
          onClick={e => e.stopPropagation()}
        >
          {formattedDates || t('agregar-fecha')}
        </Link>
      </NextLink>
    </>
  )
}
