import { Box, DialogTitle, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { FC } from 'react'

interface StatusModalLayoutProps {
  icon: React.ReactNode
  iconStyles?: string
  text: string
  textStyles?: string
}

const useStyles = makeStyles({
  typography: {
    fontFamily: 'Roboto',
  },
})

export const StatusModalLayout: FC<StatusModalLayoutProps> = ({
  icon,
  text,
  textStyles,
  iconStyles,
}) => {
  const classes = useStyles()
  return (
    <>
      <Box display="flex" justifyContent="center" className={clsx(iconStyles)}>
        {icon}
      </Box>

      <Box px={9} pt={4} pb={5}>
        <DialogTitle>
          <Typography
            align="center"
            variant="h1"
            className={clsx(classes.typography, textStyles)}
          >
            {text}
          </Typography>
        </DialogTitle>
      </Box>
    </>
  )
}
