import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'
import React from 'react'
import type { FC } from 'react'

export const WhatsAppIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.9996 8.09931C17.9775 10.3236 17.3755 12.0637 16.1236 13.5758C14.7794 15.2001 13.029 16.2315 10.887 16.649C9.23261 16.9683 7.62249 16.8279 6.04191 16.2842C5.69108 16.1614 5.34764 16.0175 5.01897 15.8456C4.91926 15.793 4.83432 15.786 4.72353 15.8211C3.22789 16.2806 1.72856 16.7367 0.229229 17.1893C0.162756 17.2103 0.074126 17.27 0.0224248 17.2244C-0.0366622 17.1682 0.0371966 17.084 0.0593542 17.0174C0.358482 16.1719 0.65761 15.3229 0.960431 14.4774C1.14508 13.9617 1.32234 13.4495 1.51437 12.9372C1.56607 12.8039 1.54761 12.7022 1.47006 12.5794C1.08968 11.9619 0.809021 11.3024 0.609602 10.6112C0.236615 9.31318 0.195993 8.00108 0.487735 6.68898C0.805328 5.2576 1.47006 3.98058 2.493 2.87898C3.73013 1.54583 5.25532 0.665245 7.06855 0.240742C8.22444 -0.0293968 9.39141 -0.0714963 10.5658 0.110935C12.102 0.349499 13.4869 0.928367 14.6945 1.86157C15.9501 2.83337 16.8659 4.04022 17.442 5.48914C17.7596 6.27149 18.0107 7.55202 17.9996 8.09931ZM2.27512 15.1054C3.19097 14.8247 4.06619 14.5581 4.93403 14.2879C5.04851 14.2529 5.14084 14.2599 5.24055 14.323C5.57291 14.5335 5.93113 14.7019 6.30042 14.8493C7.50062 15.3334 8.74884 15.5088 10.0377 15.351C11.6182 15.158 13.0068 14.5546 14.1738 13.5056C15.6436 12.183 16.4301 10.5621 16.5003 8.64661C16.5668 6.76967 15.9316 5.12077 14.6058 3.72097C13.3465 2.39132 11.766 1.63353 9.90103 1.44409C8.27983 1.2792 6.76204 1.61248 5.35872 2.41237C4.15852 3.1 3.23897 4.03671 2.6001 5.21901C1.89474 6.52058 1.6547 7.90285 1.85782 9.35177C2.00923 10.4464 2.42283 11.4532 3.08756 12.3584C3.16142 12.4601 3.18358 12.5443 3.13926 12.6636C3.00263 13.0214 2.88076 13.3828 2.75151 13.7406C2.5964 14.1827 2.44499 14.6247 2.27512 15.1054Z"
        fill="#51C85D"
      />
      <path
        d="M4.7274 6.44888C4.74587 5.61391 5.05607 4.99294 5.64325 4.49827C5.83159 4.33689 6.06425 4.26672 6.32644 4.30181C6.43723 4.31584 6.54802 4.32636 6.65881 4.31935C6.86561 4.30882 7.00594 4.40355 7.07611 4.57545C7.32354 5.1859 7.56358 5.79634 7.80362 6.41029C7.88486 6.62079 7.74453 6.78568 7.62636 6.93653C7.48972 7.11546 7.32354 7.27333 7.16105 7.4312C6.99487 7.59258 6.97271 7.6838 7.08719 7.88026C7.75561 9.03449 8.69362 9.92209 9.96768 10.4694C10.012 10.4869 10.0563 10.5115 10.1006 10.5325C10.2705 10.6062 10.4219 10.5887 10.5512 10.4483C10.7875 10.1852 11.046 9.94314 11.2639 9.66598C11.4079 9.48355 11.5076 9.45198 11.7255 9.54319C12.3755 9.80982 12.9848 10.1466 13.6052 10.4729C13.7197 10.5325 13.7603 10.6237 13.7566 10.75C13.7234 11.785 13.0993 12.3253 12.0025 12.5638C11.4707 12.6796 10.9795 12.5533 10.4921 12.3849C9.46913 12.0341 8.48312 11.6201 7.6559 10.9255C6.99856 10.3711 6.41877 9.75369 5.92761 9.06256C5.55093 8.5293 5.15948 8.00656 4.94159 7.38559C4.81603 7.05581 4.72371 6.71902 4.7274 6.44888Z"
        fill="#51C85D"
      />
    </SvgIcon>
  )
}
