import { gql, useMutation } from 'urql'

const mutationSetUserPassword = gql`
  mutation SetMyUserPassword($password: String!) {
    setMyUserPassword(password: $password) {
      sub
    }
  }
`

export const useMutationSetUserPassword = () => {
  const [, setUserPassword] = useMutation(mutationSetUserPassword)
  return {
    setUserPassword,
  }
}
