import * as amplitude from '@amplitude/analytics-browser'
import { User } from '@models/User'

interface UserIdentifyParams {
  jurisdiction_id: string | null
  jurisdiction_name: string
  tenant_id: string | null
  tenant_name: string | null
  is_teacher: boolean | null
  is_student: boolean | null
  institution_name: string | null
  institution_id: string | null
  rol: string | null
  person_id: string | null
}

interface AnalyticsInitializeParams {
  amplitudeApiKey: string
}

export interface AnalyticsIdentifyParams {
  user: User
  customParams?: UserIdentifyParams
}

type AnalyticsIdentify = (params: AnalyticsIdentifyParams) => void

export enum AnalyticsAction {
  CREATE_PROJECT = 'crear_proyecto', // Crear proyecto en la home
  CREATE_TEMPLATE = 'crear_template', // Crear proyecto en biblioteca
  CLONE_PROJECT = 'clonar_proyecto', // Quiero utilizar este proyecto en un proyecto de biblioteca
  COPY_PROJECT = 'copiar_proyecto', // Crear una copia de un proyecto
  COPY_TEMPLATE = 'copiar_template', // Crear una copia de un proyecto de biblioteca
  CREATE_CRITERION = 'crear_criterio', // Crear nuevo criterio en la configuración de rúbricas
  CHOOSE_CRITERION = 'elegir_criterio', // Elegir criterio(s) en la configuración de rúbricas
  OPEN_COMPETENCY_GRAPH = 'abrir_competencias', // Acceder a dianas competenciales en un proyecto
  OPEN_CURRICULAR_CONTENT = 'abrir_contenido_curricular', // Acceder a Contenidos Curriculares
  OPEN_HIGHSCHOOL_BIDI = 'abrir_bidi_secundaria', // Acceder a Bidi
  OPEN_ACCESS_CONTENT = 'abrir_listado_assets', // Acceder a Listado de Assets
  OPEN_PROJECT_VIEW = 'ver_proyecto', // Ver detalle de un proyecto
  OPEN_RUBRIC_VIEW = 'abrir_vista_rubricas', // Acceder a la visualización de Rúbricas
  OPEN_SELF_ASSISTED_CONTENT = 'contenido_autoasistido', // Acceder a la visualización de Capacitacion Autoasistida
  EVALUATE_HOMEWORK_WITH_RUBRICS = 'evaluar_usando_rubrica', //  Hacer clic en la evaluación con rúbricas (Docente)
  OPEN_TEACHER_TRAINING = 'ver_formacion_continua', // Acceder a formacion docente
  OPEN_WHATSAPP_CONTACT = 'contactar_whatsapp', // Acceder al enlace directo de whatsapp
  DOWNLOAD_RUBRIC_PDF = 'descargar_rubric_pdf', // Descargar pdf de rúbricas
  SEARCH_FILTERS_PROJECTS = 'buscar_proyectos', // filtros con los que se realizo la busqueda
  TEACHER_ATTACH_FILE = 'accion_adjuntar_archivo', // Docente adjunta un archivo en un contenido de tipo Accion
  UNHIDE_PROJECT = 'mostrar_proyecto', // Docente pone visible/muestra un recurso
  GO_NEW_PLATFORM_MODAL = 'ir_nueva_plataforma_modal',
  GO_NEW_PLATFORM_NAV = 'ir_nueva_plataforma_nav',
  VIEW_HOME_PAGE = 'ver_pagina_principal',
  NAVEGATE_PROJECT_FILTERS = 'navegar_filtro_proyectos',
  APPLY_PROJECT_FILTERS = 'aplicar_filtros_proyectos',
  APPLY_TITLE_FILTER = 'aplicar_filtro_titulo_proyectos',
  OPEN_DESCRIPTION_MODAL = 'abrir_saber_mas',

  // Guardar y continuar luego para cada caso en configuración de proyecto
  SAVE_PROJECT_EDITION = 'guardar_edicion_proyecto',
  SAVE_PROJECT_CREATION = 'guardar_creacion_proyecto',
  SAVE_TEMPLATE_EDITION = 'guardar_edicion_template',
  SAVE_TEMPLATE_CREATION = 'guardar_creacion_template',
  SAVE_PROJECT_ASSIGNMENT = 'guardar_assignacion_proyecto',
  SAVE_PROJECT_GROUPS = 'guardar_grupos_proyecto',

  // Fases
  ADD_PHASE = 'agregar_fase',
  EDIT_PHASE = 'editar_fase',
  DELETE_PHASE = 'eliminar_fase',
  SHOW_PHASE = 'mostrar_fase',
  REORDER_PHASE = 'reordenar_fase',
  MOVE_RESOURCE = 'mover_recurso',
  MOVE_PHASE_RESOURCE = 'mover_recurso_fase',

  // Cancelar en grupos de proyecto
  CANCEL_PROJECT_GROUPS = 'cancelar_grupos_proyectos',

  // Siguiente para cada caso en configuración de proyecto
  PROJECT_EDITION_TO_ASSIGNMENT = 'edicion_proyecto_a_asignar',
  PROJECT_CREATION_TO_ASSIGNMENT = 'creacion_proyecto_a_asignar',
  TEMPLATE_EDITION_TO_RUBRIC = 'creacion_template_a_rubrica',
  TEMPLATE_CREATION_TO_RUBRIC = 'edicion_template_a_rubrica',
  PROJECT_ASSIGNMENT_TO_RUBRIC = 'asignacion_proyecto_a_rubrica',
  PROJECT_RUBRIC_TO_GROUPS = 'rubicas_proyecto_a_grupos',

  // Students Events
  STUDENT_ATTACH_DOCUMENT = 'adjuntar_entrega', // El estudiante sube una tarea
  OPEN_SCORE_VIEW = 'ver_evaluacion', // Estudiante ve la evaluacion de tipo accion
  OPEN_CONTENT_DOCUMENT = 'seleccionar_documento', // Estudiante Abre algun documento dentro de un contenido
  VIEW_EXERCISE_TAB = 'ver_tab_ejercicio', // Estudiante ve la tab de ejercicio en recurso tipo accion
  VIEW_DEVOLUTION_TAB = 'ver_tab_devolucion', // Estudiante ve la tab de devolucion en recurso tipo accion
  VIEW_EVALUATION_TAB = 'ver_tab_evaluacion', // Estudiante ve la tab de evaluacion en recurso tipo accion

  //Eventos de recurso
  OPEN_RESOURCE = 'abrir_recurso', // Docente abre un recurso
  DELETE_RESOURCE = 'eliminar_recurso', // Docente elimina un recurso
  UNHIDE_RESOURCE = 'mostrar_recurso', // Docente pone visible/muestra un recurso
  UPDATE_RESOURCE = 'editar_recurso', // Docente edita un recurso
  CREATE_RESOURCE = 'agregar_recurso', // Docente agrega un recurso dentro de las fases
  ADD_CONTENT_TO_RESOURCE = 'agregar_contenido', // Docente agrega contenido en recurso de tipo contenido
  EDIT_CONTENT_IN_RESOURCE = 'editar_contenido', // Docente edita contenido en recurso de tipo contenido
  DOWNLOAD_FILE = 'descargar_adjunto', //Descargar adjunto dentro de recurso accion

  // Eventos de tutorbot
  OPEN_TUTORBOT = 'abrir_tutorbot', // Hacer click en el bot
  CLOSE_TUTORBOT = 'cerrar_tutorbot', // Cerrar tutorbot con click en la X
  SEND_QUESTION_TUTORBOT = 'enviar_pregunta_tutorbot', // Se envia una pregunta al tutorbot
  SATISFACTION_SURVEY_TUTORBOT = 'encuesta_satisfaccion_tutorbot', // Se envia una encuesta de satisfacción
}

export enum CloseAnalyticsAction {
  CLOSED_FROM_X = 'desde botón X',
  CLOSED_FOR_CONVERSATION_FINISHED = 'por conversación terminada',
}

const getDevice = (window: Window) => {
  const { innerWidth: width, innerHeight: height } = window
  let device = 'tablet'
  if (window.innerWidth > 1040) device = 'desktop'
  if (window.innerWidth < 500) device = 'mobile'

  return {
    device,
    resolution: `${width} x ${height}`,
  }
}

export const logEvent = (
  action: string,
  eventProperties?: Record<string, unknown>
) => {
  amplitude.track('ABP_' + action, {
    platform: 'standalone',
    ...getDevice(window),
    ...eventProperties,
  })
}

export const analyticsInitialize = ({
  amplitudeApiKey,
}: AnalyticsInitializeParams) => {
  amplitude.init(amplitudeApiKey, undefined, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
      fileDownloads: true,
    },
  })
}

export const analyticsIdentify: AnalyticsIdentify = ({
  user,
  customParams,
}) => {
  amplitude.setUserId(user.sub)

  if (customParams) {
    const identifyUserParams = new amplitude.Identify()

    for (const paramKey of Object.keys(customParams)) {
      identifyUserParams.set(paramKey, customParams[paramKey])
    }

    amplitude.identify(identifyUserParams, {
      user_id: user.sub,
      extra: {
        ...customParams,
        username: user.preferred_username,
        email: user.email,
      },
    })
  }
}

export const clearAnalyticsIdentify = () => amplitude.reset()

export const createProjectEvent = (isTemplate?: boolean) => {
  logEvent(
    isTemplate
      ? AnalyticsAction.CREATE_TEMPLATE
      : AnalyticsAction.CREATE_PROJECT
  )
}

export const projectNextEvent = (isTemplate: boolean, isUpdate: boolean) => {
  if (isUpdate) {
    logEvent(
      isTemplate
        ? AnalyticsAction.TEMPLATE_EDITION_TO_RUBRIC
        : AnalyticsAction.PROJECT_EDITION_TO_ASSIGNMENT
    )
  } else {
    logEvent(
      isTemplate
        ? AnalyticsAction.TEMPLATE_CREATION_TO_RUBRIC
        : AnalyticsAction.PROJECT_CREATION_TO_ASSIGNMENT
    )
  }
}

export const saveProjectEvent = (isTemplate: boolean, isUpdate: boolean) => {
  if (isUpdate) {
    logEvent(
      isTemplate
        ? AnalyticsAction.SAVE_TEMPLATE_EDITION
        : AnalyticsAction.SAVE_PROJECT_EDITION
    )
  } else {
    logEvent(
      isTemplate
        ? AnalyticsAction.SAVE_TEMPLATE_CREATION
        : AnalyticsAction.SAVE_PROJECT_CREATION
    )
  }
}
