import Head from 'next/head'
import { Activity } from '@models/Activity'
import { Project } from '@models/Project'

export const DocumentTitle = ({
  titles = [],
  project,
  activity,
}: {
  titles?: string[]
  project?: Project | null
  activity?: Activity | null
}) => {
  if (!!activity && !!activity?.getTypeName()) {
    titles = titles.concat(activity?.getTypeName())
  }

  if (!!project && project.exists() && !!project?.tabTitle) {
    titles = titles.concat(project.tabTitle)
  }

  const getTitle = (...titles: string[]) => {
    return titles.concat('ABP').join(' | ')
  }

  const title = getTitle(...titles)
  return (
    <Head>
      <title>{title}</title>
    </Head>
  )
}
