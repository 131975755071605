import { Box, Button, Dialog, Typography, makeStyles } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AnalyticsAction, logEvent } from '@/lib/analytics'
import { useNewTeacherPlatform } from '@hooks/newTeacherPlatform'

const useStyles = makeStyles(theme => ({
  modal: {
    maxWidth: '380px',
    marginRight: 'auto',
    marginLeft: 'auto',
    '& .MuiPaper-rounded': {
      borderRadius: '16px',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 400,
    width: '245px',
    lineHeight: '32px',
  },
  description: {
    fontSize: '14px',
    color: '#49454F',
    lineHeight: '20px',
    width: '292px',
  },
  rocketIcon: {
    width: 24,
    height: 24,
    margin: 'auto',
    marginBottom: theme.spacing(2),
    color: '#000066',
  },
  closeButton: {
    position: 'absolute',
    right: '-8px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '40px',
    minWidth: '40px',
    height: '40px',
    borderRadius: '50px',
    padding: '4px',
    color: '#000066',
  },
  actionButton: {
    color: '#fff',
    backgroundColor: '#000066',
    borderRadius: 50,
    padding: '12px 24px',
    fontSize: '14px',
    fontWeight: 500,
    '&.MuiButton-root:hover': {
      backgroundColor: '#000088',
    },
  },
}))

export const NewPlatformDialog = () => {
  const classes = useStyles()
  const { t } = useTranslation('new-platform')
  const {
    showNewPlatformDialog,
    handleCloseModal,
    handleAcceptNewPlatform,
    user,
  } = useNewTeacherPlatform()

  return (
    <Dialog
      open={showNewPlatformDialog}
      maxWidth="xs"
      className={classes.modal}
    >
      <Box p={3}>
        <Box textAlign="center">
          <RocketLaunchOutlinedIcon className={classes.rocketIcon} />
          <Box
            style={{ position: 'relative' }}
            display="flex"
            justifyContent="center"
          >
            <Typography variant="h1" className={classes.title}>
              {t('title')}
            </Typography>
            <Button className={classes.closeButton} onClick={handleCloseModal}>
              <Close />
            </Button>
          </Box>
        </Box>
        <Box py={1}>
          <Typography variant="body1" className={classes.description}>
            <p>{t('description')}</p>
            <p>{t('description-2')}</p>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end">
          <Button
            className={classes.actionButton}
            onClick={() => {
              logEvent(AnalyticsAction.GO_NEW_PLATFORM_MODAL, {
                usuario_id: user.id,
                nombre_usuario: user.preferred_username,
              })
              handleAcceptNewPlatform()
            }}
          >
            {t('action-btn')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
