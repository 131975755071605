import { Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { format } from 'date-fns'
import NextLink from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  startDate?: Date
  endDate?: Date
  assignmentRoute?: string
}

export const CardDate = ({ startDate, endDate, assignmentRoute }: Props) => {
  const { t } = useTranslation('home')

  const formattedDates =
    startDate &&
    endDate &&
    `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`

  if (!assignmentRoute) {
    return (
      <Typography variant="body2" component="span">
        {formattedDates}
      </Typography>
    )
  }

  return (
    <>
      <NextLink href={assignmentRoute} passHref>
        <Link
          color="secondary"
          variant="body2"
          underline="always"
          onClick={e => e.stopPropagation()}
        >
          {formattedDates || t('agregar-fecha')}
        </Link>
      </NextLink>
    </>
  )
}
