import { UserInfo, Asset } from '@ticmas/common-interfaces'
import { useMemo } from 'react'
import { useFetchedCurricularMetrics } from '@hooks/fetch/curricularMetrics'
import { useFetchedDistributionGroups } from '@hooks/fetch/distributionGroups'

export interface Content {
  _id: string
  title?: string
  coverUrl?: string
  duration?: number
  startDate?: Date
  endDate?: Date
  subject?: string
  owner?: UserInfo
  assets: Asset[]
  registration: string
}

export const useContents = () => {
  const { distributionGroups, loading, error } = useFetchedDistributionGroups()

  const contents = useMemo(
    () =>
      distributionGroups?.flatMap(distributionGroup =>
        distributionGroup.contents.map(content => ({
          _id: content._id,
          title: content.resource.title,
          coverUrl: content.resource.coverUrl,
          startDate: content.getStartDate(),
          endDate: content.getEndDate(),
          subject: distributionGroup.category,
          owner: content.assignedBy || distributionGroup.owners[0],
          assets: content.resource.assets || [],
          registration: distributionGroup.registration,
        }))
      ) ?? [],
    [distributionGroups]
  )

  return {
    loading,
    error,
    contents,
  }
}

export const useCurricularMetrics = () => {
  const { curricularMetrics, loading, error } = useFetchedCurricularMetrics()

  const metrics = useMemo(() => curricularMetrics ?? [], [curricularMetrics])

  return { metrics, loading, error }
}
