import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { ticmasColor } from '@/skins/default'
import EmptyState from '@common/EmptyState'
import { SearchOffIcon } from '@common/Icons/SearchOffIcon'

const useStyles = makeStyles(theme => ({
  emptyState: {
    textAlign: 'center',
    padding: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      fontSize: 76,
      color: ticmasColor.blueBlack,
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    color: ticmasColor.blueBlack,
  },
  subtitle: {
    fontSize: '18px',
    marginBottom: theme.spacing(5),
    fontWeight: 400,
    color: '#5B5B5B',
  },
  ticmasBlueBlack: {
    color: ticmasColor.blueBlack,
  },
  icon: {
    fontSize: '76px',
  },
}))

export const SearchEmptyState = () => {
  const { t } = useTranslation(['common'])
  const classes = useStyles()

  return (
    <EmptyState
      title={
        <Typography variant="h2" className={classes.title}>
          {t('common:empty.no-resultados')}
        </Typography>
      }
      subtitle={
        <Typography variant="h3" className={classes.subtitle}>
          {t('common:empty.no-resultados-filtros-hint')}
        </Typography>
      }
      customIcon={
        <SearchOffIcon
          className={clsx(classes.icon, classes.ticmasBlueBlack)}
        />
      }
      iconSize="lg"
    />
  )
}
