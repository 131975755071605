/* eslint-disable @typescript-eslint/no-explicit-any */
import { useKeycloak } from '@react-keycloak/ssr'
import { fetchExchange, makeOperation } from '@urql/core'
import type { CombinedError } from '@urql/core'
import { devtoolsExchange } from '@urql/devtools'
import { cacheExchange } from '@urql/exchange-graphcache'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import type { FC, PropsWithChildren } from 'react'
import { useMemo } from 'react'
import type { Exchange, Operation } from 'urql'
import { createClient, dedupExchange, Provider } from 'urql'
import { fromPromise, fromValue, map, mergeMap, pipe } from 'wonka'
import { useEnv } from './env'

export interface BaseGraphql {
  isLoading: boolean
  error?: CombinedError
  refresh?: () => void
}

// https://web.archive.org/web/20210412041740/https://formidable.com/open-source/urql/docs/common-questions/
const fetchOptionsExchange =
  (fn: any): Exchange =>
  ({ forward }) =>
  ops$ => {
    return pipe(
      ops$,
      mergeMap((operation: Operation) => {
        const result = fn(operation.context.fetchOptions)
        return pipe(
          typeof result.then === 'function'
            ? fromPromise<RequestInit>(result)
            : fromValue<RequestInit>(result),
          map(fetchOptions => {
            return makeOperation(operation.kind, operation, {
              ...operation.context,
              fetchOptions,
            })
          })
        )
      }),
      forward
    )
  }

const useClient = (url: string, production = false) => {
  const { keycloak } = useKeycloak()
  return useMemo(() => {
    const exchanges = [
      ...(production ? [] : [devtoolsExchange]),
      dedupExchange,
      cacheExchange({
        optimistic: {},
        keys: {},
      }),
      requestPolicyExchange({ ttl: 60_000 }),
      fetchOptionsExchange(async (fetchOptions = {}) => {
        if (!keycloak) return fetchOptions

        await keycloak.updateToken(5)
        return Promise.resolve({
          ...fetchOptions,
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            'apollographql-client-name': 'front-pbl',
          },
        })
      }),
      fetchExchange,
    ]
    return createClient({
      url,
      exchanges,
    })
  }, [production, url, keycloak])
}

export const GraphqlProvider: FC<PropsWithChildren> = ({ children }) => {
  const { graphqlEndpoint, production } = useEnv()
  const client = useClient(graphqlEndpoint, production)
  return <Provider value={client}>{children}</Provider>
}
