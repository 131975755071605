import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'
import React from 'react'
import type { FC } from 'react'

export const AlfabetizacionIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36" width="100%" height="100%">
      <path
        d="M17.501 19.332C17.1725 19.332 16.9058 19.5934 16.9058 19.9154V28.083C16.9058 28.4051 17.1725 28.6665 17.501 28.6665C17.8296 28.6665 18.0963 28.4051 18.0963 28.083V19.9154C18.0963 19.5934 17.8296 19.332 17.501 19.332Z"
        fill="#E40C62"
      />
      <path
        d="M13.8992 22.6505C13.8981 22.6482 13.8981 22.6459 13.8968 22.6435L12.1122 17.3964C12.0348 17.1724 11.8301 17.016 11.5884 16.9997C11.3562 16.9834 11.1217 17.1094 11.0145 17.3206L8.63343 21.9878C8.48699 22.276 8.60603 22.626 8.90012 22.7707C9.19539 22.9131 9.55255 22.7987 9.69899 22.5093L10.0276 21.8653L12.8456 23.2468L13.3634 24.7683C13.448 25.0133 13.6801 25.1673 13.9289 25.1673C13.992 25.1673 14.0539 25.158 14.1158 25.1358C14.4266 25.0343 14.5956 24.7041 14.492 24.3984L13.8992 22.6505ZM10.561 20.8198L11.4372 19.1011L12.3134 21.6785L10.561 20.8198Z"
        fill="#E40C62"
      />
      <path
        d="M15.3862 15.8923L8.24301 12.3919C7.94897 12.2496 7.59181 12.3651 7.44297 12.6533C7.29771 12.9415 7.41557 13.2915 7.70967 13.4363L14.8529 16.9367C14.9397 16.9787 15.0303 16.9985 15.1196 16.9985C15.3374 16.9985 15.547 16.8806 15.6517 16.6753C15.7982 16.3871 15.6791 16.037 15.3862 15.8923Z"
        fill="#E40C62"
      />
      <path
        d="M26.7596 12.3931L19.6164 15.8935C19.3224 16.0382 19.2033 16.3882 19.3497 16.6765C19.4545 16.8806 19.664 16.9985 19.8819 16.9985C19.9712 16.9985 20.0616 16.9787 20.1486 16.9378L27.2918 13.4374C27.5858 13.2928 27.7049 12.9427 27.5585 12.6545C27.4108 12.3651 27.0561 12.2496 26.7596 12.3931Z"
        fill="#E40C62"
      />
      <path
        d="M28.6071 8.62429C28.5832 8.60444 28.5547 8.58694 28.5261 8.57064L18.9662 3.34334C18.9269 3.32233 18.8852 3.30598 18.8423 3.29433C17.9863 2.90343 17.0208 2.90114 16.1625 3.29198C16.1172 3.30483 16.0743 3.32113 16.0339 3.34448L6.4739 8.57179C6.44532 8.58694 6.42032 8.60444 6.39532 8.62429C5.531 9.11085 5 9.96494 5 10.8739V23.1253C5 24.0343 5.531 24.8872 6.39292 25.3737C6.41675 25.3936 6.44532 25.4111 6.4739 25.4274L16.0339 30.6546C16.0732 30.6756 16.1149 30.692 16.1577 30.7036C16.5863 30.9008 17.0375 31 17.5006 31C17.9614 31 18.4102 30.902 18.8376 30.706C18.8828 30.6931 18.9257 30.6768 18.9662 30.6535L28.5262 25.4262C28.5547 25.4111 28.5809 25.3936 28.6059 25.3737C29.4691 24.8872 30 24.0331 30 23.1241V10.8727C30.0012 9.9638 29.469 9.11085 28.6071 8.62429ZM28.8107 23.1241C28.8107 23.641 28.4904 24.1159 27.957 24.3947C27.9249 24.4111 27.8928 24.4309 27.8654 24.4531L18.4756 29.5882C18.4506 29.5963 18.4256 29.6068 18.4006 29.6185C17.8232 29.9032 17.1791 29.9032 16.6029 29.6185C16.5791 29.608 16.5541 29.5975 16.5291 29.5894L7.13821 24.4531C7.11081 24.431 7.07867 24.4111 7.04535 24.3936C6.51078 24.1159 6.19057 23.641 6.19057 23.1241V10.8727C6.19057 10.3558 6.51084 9.88094 7.04418 9.6032C7.07632 9.58685 7.10847 9.56705 7.13587 9.54484L16.5256 4.40975C16.5506 4.40161 16.5756 4.3911 16.6006 4.3794C17.178 4.09472 17.8221 4.09472 18.3983 4.3794C18.4221 4.3899 18.4471 4.4004 18.4721 4.40855L27.863 9.54364C27.8904 9.56579 27.9226 9.58564 27.9559 9.60315C28.4916 9.88083 28.8107 10.3557 28.8107 10.8726V23.1241Z"
        fill="#E40C62"
      />
      <path
        d="M22.0126 10.0372C21.9792 9.32775 21.5708 8.71403 20.9161 8.39314C19.8899 7.89023 18.6505 7.94859 17.6802 8.54366L17.4612 8.67668C17.354 8.17147 17.0207 7.74557 16.535 7.50754C16.0218 7.25436 15.4123 7.26716 14.9099 7.54019L11.2633 9.48527C11.0657 9.59028 10.9442 9.79795 10.9525 10.0185C10.9609 10.239 11.0954 10.4374 11.3014 10.5284L17.8493 13.4454C17.9279 13.4804 18.0124 13.4979 18.0957 13.4979C18.2065 13.4979 18.316 13.4687 18.4124 13.408L21.0864 11.771C21.7018 11.3953 22.047 10.7466 22.0126 10.0372ZM12.8812 9.95082L15.4824 8.56351C15.6408 8.476 15.836 8.47015 16.0015 8.55187C16.2646 8.68023 16.3051 8.91706 16.3098 9.01392C16.3134 9.11078 16.2979 9.34996 16.0408 9.50747L14.3264 10.595L12.8812 9.95082ZM20.4553 10.7816L18.0517 12.2529L15.6313 11.1748L18.3124 9.53307C18.935 9.15153 19.7279 9.11417 20.3922 9.43971C20.6505 9.56572 20.8113 9.8096 20.8256 10.0919C20.8363 10.3744 20.7006 10.6323 20.4553 10.7816Z"
        fill="#E40C62"
      />
      <path
        d="M25.8355 21.1675C25.5069 21.1675 25.2402 21.4289 25.2402 21.7509C25.2402 22.1966 24.9878 22.5968 24.5819 22.7952C24.2081 22.9772 23.7759 22.9585 23.4235 22.7438C23.064 22.5268 22.858 22.1639 22.858 21.7508V20.1908C22.858 19.5246 23.2355 18.9248 23.845 18.6261C24.151 18.4768 24.4903 18.4931 24.7819 18.6681C25.0724 18.8432 25.2391 19.136 25.2391 19.4709C25.2391 19.7929 25.5058 20.0543 25.8344 20.0543C26.1629 20.0543 26.4296 19.7929 26.4296 19.4709C26.4296 18.7334 26.0487 18.0626 25.4082 17.6752C24.7713 17.2878 23.9867 17.2528 23.3128 17.5818C22.2973 18.0789 21.6675 19.0788 21.6675 20.1908V21.7508C21.6675 22.5652 22.0889 23.3073 22.7961 23.7355C23.1807 23.9689 23.614 24.0867 24.0473 24.0867C24.4105 24.0867 24.7748 24.0038 25.1152 23.8382C25.926 23.4403 26.4308 22.641 26.4308 21.7508C26.4308 21.4289 26.1641 21.1675 25.8355 21.1675Z"
        fill="#E40C62"
      />
    </SvgIcon>
  )
}
