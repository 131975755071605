import { logEvent } from '@amplitude/analytics-browser'
import {
  makeStyles,
  TextField,
  IconButton,
  InputAdornment,
  Tab,
  Box,
  Button,
} from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'
import { SearchRounded } from '@mui/icons-material'
import ClearRounded from '@mui/icons-material/ClearRounded'
import React, { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FilterTabPanel } from './filterTabPanel'
import { useFilterFormData } from './hooks'
import { AnalyticsAction } from '@/lib/analytics'

const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  searchInput: {
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '9px',
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
      paddingLeft: '4px',
    },
  },
  clearFiltersButton: {
    backgroundColor: 'rgba(26, 28, 25, 0.08)',
    border: '1px solid #EDEDED',
    padding: '6px 28px',
    textTransform: 'uppercase',
    color: '#5B5B5B',
  },
  tabsContainer: {
    border: '1px solid #E3E3E3',
    background: '#FFF',
    borderRadius: '4px',
    width: '100%',
  },
  tabsHead: {
    width: '100%',
    padding: '0 16px',
    borderBottom: '1px solid #ededed',
  },
})

interface Props {
  onClearAllFilters: () => void
  onClearTitleFilter: () => void
}

export const ProjectFilter: React.FC<Props> = ({
  onClearAllFilters,
  onClearTitleFilter,
}) => {
  const { t } = useTranslation('home')
  const classes = useStyles()

  const { subjects, levels, languages } = useFilterFormData()
  const { control, register, watch } = useFormContext()
  const [currentTabId, setCurrentTabId] = useState('subjects')

  const getTabName = useCallback(
    (transKey, entity) => {
      return watch(entity) && watch(entity).length > 0
        ? `${t(transKey)} (${watch(entity).length})`
        : t(transKey)
    },
    [watch, t]
  )

  const tabs = [
    {
      label: getTabName('subject', 'subjects'),
      value: 'subjects',
    },
    {
      label: getTabName('level', 'levels'),
      value: 'levels',
    },
    {
      label: getTabName('language', 'languages'),
      value: 'languages',
    },
  ]

  const handleTabChange = (event: React.SyntheticEvent, tabId: string) => {
    setCurrentTabId(tabId)
  }

  logEvent(AnalyticsAction.NAVEGATE_PROJECT_FILTERS, {
    tab: currentTabId,
  })
  return (
    <form
      className={classes.form}
      onSubmit={e => {
        e.preventDefault()
        logEvent(AnalyticsAction.APPLY_TITLE_FILTER)
      }}
    >
      <Box display="flex" flexDirection="column">
        <TextField
          name="title"
          autoComplete="off"
          inputRef={register}
          placeholder={t('search')}
          fullWidth
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <SearchRounded
                fontSize="small"
                color="action"
                style={{ pointerEvents: 'none' }}
              />
            ),
            endAdornment: (
              <InputAdornment position="end">
                {watch('title') && (
                  <IconButton size="small" onClick={onClearTitleFilter}>
                    <ClearRounded fontSize="small" color="action" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box
          mt={0.75}
          display="flex"
          flexDirection="column"
          alignSelf="end"
          className={classes.tabsContainer}
        >
          <TabContext value={currentTabId}>
            <Box alignSelf="center" className={classes.tabsHead}>
              <TabList
                onChange={handleTabChange}
                aria-label={t('filters')}
                textColor="inherit"
              >
                {tabs.map(tab => (
                  <Tab
                    label={tab.label}
                    value={tab.value}
                    key={tab.value}
                    style={{ minWidth: '120px' }}
                  />
                ))}
              </TabList>
            </Box>
            <FilterTabPanel
              name="subjects"
              items={subjects}
              control={control}
              selectedTab={currentTabId}
            />
            <FilterTabPanel
              name="levels"
              items={levels}
              control={control}
              selectedTab={currentTabId}
            />
            <FilterTabPanel
              name="languages"
              items={languages}
              control={control}
              selectedTab={currentTabId}
            />
          </TabContext>
          <Box alignSelf="center" my={1}>
            <Button
              className={classes.clearFiltersButton}
              onClick={onClearAllFilters}
              startIcon={<ClearRounded fontSize="small" color="action" />}
            >
              {t('clear-filters')}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  )
}
