import {
  KeycloakTokens,
  TokenPersistor,
  useKeycloak,
} from '@react-keycloak/ssr'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useImpersonation } from '../contexts/impersonation'
import { useTenantConfig } from '../contexts/tenantConfig'
import { clearClientsIdentify, setupClientsIdentify } from '@/lib/clients'
import { useEnv } from '@/lib/env'
import { Project } from '@models/Project'
import { Role } from '@models/Role'
import { User } from '@models/User'

export function dummyTokenPersistor(): TokenPersistor {
  let tokens: Partial<KeycloakTokens> = {}
  return {
    getTokens() {
      return tokens as KeycloakTokens
    },
    setTokens(newTokens: KeycloakTokens) {
      tokens = newTokens
    },
    resetTokens() {
      tokens = {}
    },
  }
}

export function useUserInfo(useOwn = false) {
  const { getEffectiveInstance } = useImpersonation()
  return User.fromData(getEffectiveInstance(useOwn).tokenParsed)
}

export function authGuard(Component: React.ComponentType, roles?: Role[]) {
  const WrappedComponent = function (props) {
    const { amplitudeApiKey, sentryDsn, nodeEnv } = useEnv()
    const [{ idpHint }] = useTenantConfig()
    const impersonatingUser = useUserInfo(false)
    const { isImpersonating } = useImpersonation()
    const router = useRouter()
    const { keycloak, initialized } = useKeycloak()
    const originalUser = useUserInfo(true)

    const authorized =
      initialized && originalUser && roles
        ? roles.some(
            role =>
              originalUser.hasRole(role) ||
              (isImpersonating && impersonatingUser.hasRole(role))
          )
        : true

    useEffect(() => {
      if (!keycloak!.authenticated) {
        const { origin, pathname, search, hash } = window.location
        const next = encodeURIComponent(`${pathname}${search}${hash}`)

        clearClientsIdentify()

        keycloak!.login({
          redirectUri: `${origin}/auth/authCallback?next=${next}`,
          idpHint,
        })
      } else {
        if (!authorized) {
          clearClientsIdentify()
          // TODO ver si conviene mostrar un mensaje o pantalla de error
          router.push('/')
        }
      }
    }, [keycloak, authorized, router, idpHint])

    useEffect(() => {
      if (originalUser?.sub && keycloak?.authenticated) {
        setupClientsIdentify({
          user: originalUser,
          envs: { amplitudeApiKey, sentryDsn, nodeEnv },
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalUser])

    if (!keycloak!.authenticated || !authorized) {
      return null
    }

    return <Component {...props} />
  }

  return WrappedComponent
}

export const AuthorizeProjectEdition = ({
  project,
  children,
}: {
  project: Project
  children: React.ReactNode
}) => {
  const user = useUserInfo()
  const router = useRouter()

  if (!project.userCanEdit(user)) {
    // TODO ver si conviene mostrar un mensaje o pantalla de error
    router.push('/')
    return null
  }

  return <>{children}</>
}
