import { isFunction } from 'lodash/fp'
import React from 'react'
import { SWRConfig } from 'swr'
import { useImpersonation } from '../../impersonation'
import { projectStoreContext } from '../context'
import { ProjectStore } from '../types'
import { RestProjectStore } from './RestProjectStore'
import { useMutate } from '@/lib/http'

const { Provider } = projectStoreContext

const InternalRestProjectStoreProvider = props => {
  const { mutate } = useMutate()
  const { getAxiosInstance } = useImpersonation()
  const axios = getAxiosInstance()

  const store = new RestProjectStore(mutate, axios)

  const providerValue = new Proxy<ProjectStore>(store, {
    get: (target, key: keyof ProjectStore) => {
      const targetElement = target[key]
      if (!targetElement) {
        // eslint-disable-next-line no-console
        console.error(`No key ${key.toString()} in ProjectStore`)
        return
      }
      return isFunction(targetElement)
        ? targetElement.bind(target)
        : targetElement
    },
  })

  return <Provider value={providerValue} {...props} />
}

export const RestProjectStoreProvider = props => {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        refreshInterval: 30000,
      }}
    >
      <InternalRestProjectStoreProvider {...props} />
    </SWRConfig>
  )
}
