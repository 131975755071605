import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Box,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paper': {
      margin: 16,
    },
    '& .MuiDialog-paperFullWidth': {
      width: '100%',
    },
  },
})

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeIcon: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.grey[400],
      padding: theme.spacing(1),
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <IconButton
        aria-label="close-icon"
        className={classes.closeIcon}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

interface Props {
  open: boolean
  closeHandler: (event?) => void
  items?: string[]
  component?: JSX.Element
  maxWidth?
}

export const SimpleDialog: React.FC<Props> = ({
  open,
  closeHandler,
  items,
  maxWidth,
  component,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      onClick={e => e.stopPropagation()}
      className={classes.dialog}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle id="alert-dialog-title" onClose={closeHandler} />
      <DialogContent>
        {items && (
          <List style={{ paddingBottom: '30px' }}>
            {items.map(name => (
              <ListItem key={name}>
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        )}
        {component && (
          <Box pr={6} pl={6} pb={3} pt={3}>
            {component}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
