import { useContext } from 'react'
import { projectStoreContext } from '../../contexts/projectStore'
import { BaseResponse } from './baseResponse'
import { useFetchWithCredentials } from '@/lib/http'
import { CurricularContent } from '@models/CurricularContent'
import { DistributionGroups } from '@models/DistributionGroups'

interface DistributionGroupsReturn extends BaseResponse {
  distributionGroups: DistributionGroups[]
}

type DistributionGroup = () => DistributionGroupsReturn

export const useFetchedDistributionGroups: DistributionGroup = () => {
  const { keys } = useContext(projectStoreContext)

  const { data, error } = useFetchWithCredentials<DistributionGroups[]>(
    () => keys.distibutionsGroups(),
    { refreshInterval: 0 }
  )
  const distributionGroups = data
    ? data.map(dg => {
        const distgroup = DistributionGroups.fromData(dg)
        distgroup.contents = distgroup.contents.map(c =>
          CurricularContent.fromData(c)
        )
        return distgroup
      })
    : []

  return {
    loading: !data && !error,
    error,
    distributionGroups,
  }
}
