import {
  Button,
  Grid,
  makeStyles,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { SearchRounded, Clear } from '@mui/icons-material'
import React from 'react'
import { useTeacherFilterForm } from './hooks'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  searchInput: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '9px',
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
      paddingLeft: '4px',
    },
  },
}))

export const TeacherImpersonationsFilter = () => {
  const classes = useStyles()

  const {
    hookform: { errors, register },
    submitHandler,
    clearFilters,
    search,
  } = useTeacherFilterForm()

  const isSearchEmpty = !search ?? !search.length

  return (
    <form onSubmit={submitHandler} className={classes.form}>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            name="query"
            autoComplete="off"
            inputRef={register}
            placeholder="Buscar docentes por mail o nombre de usuario"
            fullWidth
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <SearchRounded
                  fontSize="small"
                  color="action"
                  style={{ pointerEvents: 'none' }}
                />
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isSearchEmpty}
                    onClick={clearFilters}
                    onMouseDown={event => event.preventDefault()}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item md={2} sm={2} xs={6}>
          <Button
            type="submit"
            disabled={!!Object.keys(errors).length || isSearchEmpty}
            fullWidth
            color="primary"
            variant="contained"
            formAction="/"
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
