import {
  makeStyles,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import type { SvgIconProps } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AlfabetizacionIcon } from '../Icons/AlfabetizacionIcon'
import { BidiIcon } from '../Icons/BidiIcon'
import { BidiModal, BidiModalProps } from './BidiModal'
import { useUserInfo } from '@/lib/auth'
import { StudentSCLIcon } from '@components/common/Icons/StudentSCLIcon'
import { TeacherSCLIcon } from '@components/common/Icons/TeacherSCLIcon'
import { useModal } from '@hooks/use-modal/use-modal'
import { ModuleItemType } from '@models/InstitutionModules'
import { Role } from '@models/Role'

const MENU_WIDTH = 360
// const MENU_WIDTH_MOBILE = 230

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    // La mitad de 3/4 del largo total del menu
    minWidth: `${(1 * MENU_WIDTH) / 4}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '100%',
      minWidth: 'unset',
    },
  },
  listIcon: {
    width: '36px',
    height: '36px',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1.5),
      width: '30px',
      height: '30px',
    },
  },
  loaderContainer: {
    position: 'relative',
    top: '-20px',
  },
}))

export interface ModuleMenuItem {
  [id: string]: {
    icon: React.FC<SvgIconProps<'svg'>>
    title: string
  }
}

// El componente wrapper evita renderizar el componente BidiModal hasta que no se tenga que realizar la request que solicita la url
const Modal: FC<BidiModalProps> = ({ open, id, onClose }) => {
  return open ? <BidiModal id={id} open={open} onClose={onClose} /> : null
}

export const ModuleItem = ({ id, url }: ModuleItemType) => {
  const { t } = useTranslation(['navbar'])
  const classes = useStyles()
  const user = useUserInfo()

  const { isOpened, toggle: handleToogleModal } = useModal()

  const modalId = id === 'bidi_secundaria' ? id : ''

  const modulesConfig: ModuleMenuItem = {
    alfabetizacion: {
      icon: AlfabetizacionIcon,
      title: t('navbar:modules-menu.literacy'),
    },
    clases: {
      icon: user?.hasRole(Role.TEACHER) ? TeacherSCLIcon : StudentSCLIcon,
      title: t('navbar:modules-menu.clases'),
    },
    bidi_secundaria: {
      icon: BidiIcon,
      title: t('navbar:modules-menu.bidi'),
    },
  }
  const { icon: Icon, title } = modulesConfig[id]

  return (
    <>
      <MenuItem
        onClick={url ? () => window.open(url, '_self') : handleToogleModal}
        className={classes.listItem}
      >
        <ListItemIcon className={classes.listIcon}>
          <Icon style={{ width: '100%', height: '100%' }} />
        </ListItemIcon>

        <ListItemText>{title}</ListItemText>
      </MenuItem>
      <Modal open={isOpened} id={modalId} onClose={handleToogleModal} />
    </>
  )
}
