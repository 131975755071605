import { useRouter } from 'next/router'
import React from 'react'
import { authGuard, useUserInfo } from '@/lib/auth'
import { institutionContextGuard } from '@/lib/institution-context'
import { Home, TabIdViews } from '@components/Home'
import { useTabs } from '@hooks/tabs'
import { Role } from '@models/Role'

const Index = () => {
  const { tabsToShow, isLoading, userPermissions } = useTabs()

  const router = useRouter()
  const user = useUserInfo()

  if (user.hasRole(Role.CONTENT_CREATOR)) {
    router.push('/biblioteca-abp')
  }
  if (isLoading || userPermissions) return null

  return (
    <Home
      view={
        user.hasRole(Role.TEACHER) ? TabIdViews.libraryABP : TabIdViews.projects
      }
      tabsToShow={tabsToShow}
    />
  )
}

export default authGuard(institutionContextGuard(Index), [
  Role.TEACHER,
  Role.CONTENT_CREATOR,
  Role.STUDENT,
  Role.TUTOR,
  Role.TUTOR_MASTER,
])
