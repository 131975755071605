import { Type } from 'class-transformer'
import { Activity, BaseActivity } from './Activity'
import { BaseModel } from './BaseModel'
import { activityTypes } from './activityTypes'

export class Phase extends BaseModel {
  _id: string
  title: string
  hidden: boolean

  @Type(() => Date)
  startDate: Date

  @Type(() => Date)
  endDate: Date

  @Type(() => BaseActivity, {
    discriminator: {
      property: 'type',
      subTypes: Array.from(activityTypes.entries()).map(([name, value]) => ({
        name,
        value,
      })),
    },
    keepDiscriminatorProperty: true,
  })
  activities: Activity[]

  get id() {
    return this._id
  }

  addActivity(activity: Activity) {
    this.activities.push(activity)
  }

  updateActivity(newActivity: Activity) {
    this.activities = this.activities.map(activity =>
      activity.id === newActivity.id ? newActivity : activity
    )
  }

  removeActivity(activity: Activity) {
    this.activities = this.activities.filter(
      _activity => _activity._id !== activity._id
    )
  }
}
