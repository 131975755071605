import { getKeycloakInstance, useKeycloak } from '@react-keycloak/ssr'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { KeycloakInstance } from 'keycloak-js'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useState } from 'react'
import { useEnv } from '@/lib/env'
import { Teacher } from '@models/Teacher'

interface Keys {
  impersonations: () => string
  impersonation: (teacher: Teacher) => string
}

export const keys: Keys = {
  impersonations: () => `/v1/is/impersonations`,
  impersonation: (teacher: Teacher) =>
    `/v1/is/impersonations/${teacher.userId}`,
}

interface ImpersonationData {
  access_token: string
  expires_in: number
  refresh_token: string
  refresh_expires_in: number
  session_state: string
}

interface RequestConfig extends AxiosRequestConfig {
  useOwn?: boolean
}

export interface TeacherFilters {
  query: string
}

export interface AxiosInstance {
  fetch<T>(
    useOwn: boolean
  ): (url: string, config?: RequestConfig) => T | Promise<T>
  get<T>(url: string, config?: RequestConfig): Promise<AxiosResponse<T>>
  delete<T>(url: string, config?: RequestConfig): Promise<AxiosResponse<T>>
  post<T>(
    url: string,
    data?: RequestConfig['data'],
    config?: RequestConfig
  ): Promise<AxiosResponse<T>>
  put<T>(
    url: string,
    data?: RequestConfig['data'],
    config?: RequestConfig
  ): Promise<AxiosResponse<T>>
  patch<T>(
    url: string,
    data?: RequestConfig['data'],
    config?: RequestConfig
  ): Promise<AxiosResponse<T>>
}

interface ImpersonationTeacherContext {
  getEffectiveInstance(useOwn?: boolean): KeycloakInstance
  impersonate(teacher: Teacher): Promise<void>
  stopImpersonating(): void
  isImpersonating: boolean
  filters: TeacherFilters
  setFilters: (filters: TeacherFilters) => void
  getAxiosInstance: () => AxiosInstance
  setInstitutionId: (instituionId: string) => void
}

const context = createContext<ImpersonationTeacherContext>(
  null as unknown as ImpersonationTeacherContext
)

const { Provider } = context

export const ImpersonationTeacherProvider = props => {
  const router = useRouter()
  const [ownKeycloak] = useKeycloak()
  const [impersonationKeycloak, setImpersonationKeycloak] =
    useState<KeycloakInstance | null>(null)
  const [filters, setFilters] = useState(null)
  const [institutionId, setInstitutionId] = useState(null)

  const { keycloak: keycloakConfig, apiEndpoint } = useEnv()

  const isImpersonating = !!impersonationKeycloak

  const getEffectiveInstance = (useOwn = false) => {
    return isImpersonating && !useOwn ? impersonationKeycloak! : ownKeycloak
  }

  const axiosConfig = async ({ useOwn, url, ...config }: RequestConfig) => {
    const keycloakInstance = getEffectiveInstance(useOwn)
    await keycloakInstance.updateToken(5)

    const isPbl = url?.includes('pbl')

    return {
      baseURL: apiEndpoint,
      ...config,
      headers: {
        ...(config.headers || {}),

        ...(institutionId && isPbl
          ? { 'X-Institution-Id': institutionId }
          : {}),
        ...(keycloakInstance.authenticated && !!url && !url.startsWith('https')
          ? { Authorization: `Bearer ${keycloakInstance.token}` }
          : {}),
      },
    }
  }

  const getAxiosInstance = () => {
    const fetch: AxiosInstance['fetch'] =
      useOwn =>
      async (url, config = {}) =>
        axios
          .get(url, await axiosConfig({ url, useOwn, ...config }))
          .then(res => res.data)

    const get: AxiosInstance['get'] = async (url, config = {}) =>
      axios.get(url, await axiosConfig({ url, ...config }))

    const axiosDelete: AxiosInstance['delete'] = async (url, config = {}) =>
      axios.delete(url, await axiosConfig({ url, ...config }))

    const post: AxiosInstance['post'] = async (url, data, config = {}) =>
      axios.post(url, data, await axiosConfig({ url, ...config }))

    const put: AxiosInstance['put'] = async (url, data, config = {}) =>
      axios.put(url, data, await axiosConfig({ url, ...config }))

    const patch: AxiosInstance['patch'] = async (url, data, config = {}) =>
      axios.patch(url, data, await axiosConfig({ url, ...config }))

    return {
      get,
      fetch,
      delete: axiosDelete,
      post,
      put,
      patch,
    }
  }

  const impersonate = async (teacher: Teacher) => {
    const axios = getAxiosInstance()

    const { data } = await axios.post<ImpersonationData>(
      keys.impersonation(teacher),
      {},
      { useOwn: true }
    )

    const impKeycloak = getKeycloakInstance(keycloakConfig, true)
    await impKeycloak.init({
      token: data.access_token,
      refreshToken: data.refresh_token,
      checkLoginIframe: false,
    })
    setImpersonationKeycloak(impKeycloak)

    setFilters(null)
  }

  const stopImpersonating = () => {
    setImpersonationKeycloak(null)
    router.push('/docentes')
  }

  return (
    <Provider
      value={{
        getEffectiveInstance,
        impersonate,
        stopImpersonating,
        isImpersonating,
        filters,
        setFilters,
        getAxiosInstance,
        setInstitutionId,
      }}
      {...props}
    />
  )
}

export function useImpersonation() {
  return useContext(context)
}
