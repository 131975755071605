import React, { createContext, useContext, useMemo } from 'react'
import { EnvContext } from '@/lib/env'
import { Activity, ActivityType } from '@models/Activity'
import { activityTypes } from '@models/activityTypes'

interface FeatureFlagsContext {
  availableActivities: Activity[]
}

const context = createContext<FeatureFlagsContext>({
  availableActivities: [],
})

const { Provider } = context

export const FeatureFlagsProvider = props => {
  const env = useContext(EnvContext)

  const availableActivities = useMemo(
    () =>
      Array.from(activityTypes.entries())
        .map(([type, ActivityType]) => ActivityType.fromData({ type }))
        .filter(activity => {
          if (activity.type === ActivityType.EXAM) {
            return env.featureFlags.examActivity
          }
          if (activity.type === ActivityType.QUIZ) {
            return env.featureFlags.quizActivity
          }
          return true
        }),
    [env]
  )

  return (
    <Provider
      value={{
        availableActivities,
      }}
      {...props}
    />
  )
}

export function useFeatureFlags() {
  return useContext(context)
}
