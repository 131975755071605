import { ActivityType, BaseActivity } from './Activity'
import typeTranslations from '@hooks/fetch/activities'

type DeliveryType = 'individual' | 'group'

export class ActionActivity extends BaseActivity {
  type = ActivityType.ACTION as const

  // TODO: sacar los valores por defecto cuando se agreguen los campos tipo y rubricas al form
  description?: string
  deliveryType: DeliveryType = 'individual'
  contentUrls?: string[]
  criteriaIds: string[] = []

  getTypeName() {
    return typeTranslations[ActivityType.ACTION].name
  }

  getTypeDescription() {
    return typeTranslations[ActivityType.ACTION].description
  }
}
