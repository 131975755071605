import { flatten, isFunction, isNull } from 'lodash/fp'
import { useCallback, useState } from 'react'
import useSWR, { ConfigInterface, keyInterface, mutate } from 'swr'
import { useImpersonation } from '../contexts/impersonation'
import { sentry } from '@/lib/sentry'

function useConcatTokenToKey(useOwn = false) {
  const { getEffectiveInstance } = useImpersonation()
  const { token } = getEffectiveInstance(useOwn)

  const concatToken = useCallback(k => flatten([k, token]), [token])
  return useCallback(
    key =>
      isFunction(key)
        ? () => {
            const keyFnResult = key()
            return isNull(keyFnResult) ? null : concatToken(keyFnResult)
          }
        : concatToken(key),
    [concatToken]
  )
}

export function useFetchWithCredentials<Data = unknown, Error = unknown>(
  key: keyInterface,
  config?: ConfigInterface<Data, Error>,
  useOwn = false
) {
  const { getAxiosInstance } = useImpersonation()
  const axios = getAxiosInstance()

  const concatTokenToKey = useConcatTokenToKey(useOwn)
  const newKey = concatTokenToKey(key)
  return useSWR<Data, Error>(
    newKey,
    (url, config) =>
      axios
        .get<Data>(url, { ...config, withCredentials: true })
        .then(res => res.data),
    {
      ...config,
      onError(error, key) {
        sentry.addBreadcrumb({
          category: 'axios',
          message: JSON.stringify(key),
          level: 'error',
        })
        sentry.captureException(error)
      },
    }
  )
}

export function useFetch<Data = unknown, Error = unknown>(
  key: keyInterface,
  config?: ConfigInterface<Data, Error>,
  useOwn = false
) {
  const { getAxiosInstance } = useImpersonation()
  const axios = getAxiosInstance()

  const concatTokenToKey = useConcatTokenToKey(useOwn)
  const newKey = concatTokenToKey(key)
  return useSWR<Data, Error>(newKey, axios.fetch(useOwn), {
    ...config,
    onError(error, key) {
      sentry.addBreadcrumb({
        category: 'axios',
        message: JSON.stringify(key),
        level: 'error',
      })
      sentry.captureException(error)
    },
  })
}

export function useMutate<Data = unknown>() {
  const concatTokenToKey = useConcatTokenToKey()
  return {
    mutate(
      key: keyInterface,
      data?: Data | Promise<Data> | ((current: Data) => Promise<Data>),
      shouldRevalidate?: boolean
    ) {
      const newKey = concatTokenToKey(key)
      return mutate(newKey, data, shouldRevalidate)
    },
  }
}

export function useDownloadFile() {
  const { getAxiosInstance } = useImpersonation()
  const axios = getAxiosInstance()
  const [downloading, setDownloading] = useState<boolean>(false)

  return {
    async downloadFile(url: string, fileName: string) {
      try {
        setDownloading(true)
        const blob = await axios.get<Blob | MediaSource>(url, {
          responseType: 'blob',
        })
        const blobDataUrl = window.URL.createObjectURL(blob.data)
        const anchor = document.createElement('a')
        anchor.href = blobDataUrl
        anchor.download = fileName
        anchor.click()
        anchor.remove()
        setTimeout(() => window.URL.revokeObjectURL(blobDataUrl), 100)
      } finally {
        setDownloading(false)
      }
    },
    downloading,
  }
}
