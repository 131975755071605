import { Box, Chip, makeStyles } from '@material-ui/core'
import React from 'react'
import { useFetchedLanguages } from '@hooks/fetch/languages'
import { Project } from '@models/Project'

interface Props {
  language: Project['language']
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0.75),
    paddingRight: theme.spacing(0),
  },
  chip: {
    borderRadius: 0,
    fontSize: '0.75rem',
  },
}))

export const ProjectLanguage: React.FC<Props> = ({ language: languageId }) => {
  const classes = useStyles()

  const { languages, loading, error } = useFetchedLanguages()

  const findLanguage = (languageId: string) =>
    languages.find(l => l.id === languageId)

  const language = findLanguage(languageId)

  if (error || loading || !language) return null

  return (
    <Box className={classes.root}>
      <Chip className={classes.chip} size="small" label={language?.name} />
    </Box>
  )
}
