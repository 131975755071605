import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { useUserInfo } from '@/lib/auth'
import { useEnv } from '@/lib/env'
import { Role } from '@models/Role'
import { User } from '@models/User'

export const useNewTeacherPlatform = () => {
  const user = useUserInfo()
  const [showNewPlatformDialog, setShowNewPlatformDialog] = useState(false)
  const { teacherPlatformBaseUrl } = useEnv()
  const { query } = useRouter()

  const { redirectToOldPBL } = query

  const NEW_PLATFORM_DEFAULT_PATH = '/biblioteca'

  const nonEquivalentPaths = [
    '/',
    '/mis-proyectos',
    '/biblioteca-abp',
    '/biblioteca-disney',
    '/configuracion',
    '/gestion',
    '/rubrica',
    '/estudiantes',
  ]

  const openNewPlatformDialog = () => {
    setShowNewPlatformDialog(true)
  }

  const closeNewPlatformDialog = () => {
    setShowNewPlatformDialog(false)
  }

  const redirectToNewPlatform = () => {
    const currentPath = window.location.pathname
    const shouldRedirectToDefaultPath = nonEquivalentPaths.some(partialPath =>
      currentPath.endsWith(partialPath)
    )
    const newPlatformUrl = shouldRedirectToDefaultPath
      ? `${teacherPlatformBaseUrl}${NEW_PLATFORM_DEFAULT_PATH}`
      : `${teacherPlatformBaseUrl}${currentPath}`

    window.location.href = `${newPlatformUrl}?referrer=front-pbl&redirectToMicrofront`
  }

  const isOneDayPassedSinceModalClosed = () => {
    const lastClosedTime = localStorage.getItem(
      'newPlatformModal_lastClosedTime'
    )
    // 24 hs
    return (
      !lastClosedTime ||
      Date.now() - parseInt(lastClosedTime) >= 24 * 60 * 60 * 1000
    )
  }

  const updateModalLastClosedTime = () => {
    localStorage.setItem('newPlatformModal_lastClosedTime', `${Date.now()}`)
  }

  useEffect(() => {
    if (user && user.hasRole(Role.TEACHER)) {
      if (typeof redirectToOldPBL === 'string') {
        localStorage.setItem('redirectToMicrofront', 'false')
        updateModalLastClosedTime()
      } else {
        const shouldRedirectToNewPlatform =
          localStorage.getItem('redirectToMicrofront') === 'true'

        if (shouldRedirectToNewPlatform) {
          redirectToNewPlatform()
        } else if (isOneDayPassedSinceModalClosed()) {
          openNewPlatformDialog()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleCloseModal = () => {
    updateModalLastClosedTime()
    closeNewPlatformDialog()
  }

  const handleAcceptNewPlatform = () => {
    localStorage.setItem('redirectToMicrofront', 'true')
    redirectToNewPlatform()
  }

  return {
    showNewPlatformDialog,
    handleCloseModal,
    handleAcceptNewPlatform,
    user,
  }
}

export const useNewTeacherPlatformActivation = (
  user?: User,
  selectedInstitutionId?: string
) => {
  const isNewTeacherPlatformActivated =
    user?.hasRole(Role.TEACHER) && selectedInstitutionId ? true : false

  return {
    isNewTeacherPlatformActivated,
  }
}
