import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  horizontalCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginTop: {
    marginTop: '3em',
  },
})

type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs'

interface Props {
  size?: Size
  marginTop?: string
}

const sizes = {
  xl: '6rem',
  lg: '5rem',
  md: '4rem',
  sm: '3rem',
  xs: '2rem',
}

export const Loader: React.FC<Props> = ({ size = 'md', marginTop }) => {
  const classes = useStyles()

  return (
    <div
      style={{ marginTop }}
      className={`
        ${classes.horizontalCenter} ${classes.marginTop}
      `}
    >
      <CircularProgress size={sizes[size]} color="primary" />
    </div>
  )
}
