import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: false,
    saveMissing: true,
    fallbackLng: {
      'es-AR': ['es'],
      default: ['es'],
    },
    lng: 'es-AR',
    ns: [
      'common',
      'home',
      'project-config',
      'project-detail',
      'activity',
      'action-activity',
      'content-activity',
      'exam-activity',
      'competency-graph',
      'welcome',
      'new-platform',
      'navbar',
      'institution-context',
      'curricular',
      'teachers-training',
      'rubrics',
      'tutorbot',
    ],
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'subdomain', 'path'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: 'languageChanged initialized init loaded',
      useSuspense: false,
    },
  })

export default i18next
