import { BaseContentItem, ContentItemType } from './ContentItem'
import i18n from '@/lib/i18n'

export class FileContentItem extends BaseContentItem {
  type = ContentItemType.FILE as const
  url: string

  getTypeName(): string {
    return i18n.t('content-activity:content.file')
  }
}
