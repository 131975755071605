import { Avatar as MuiAvatar, makeStyles, Theme } from '@material-ui/core'
import { UserInfo } from '@ticmas/common-interfaces'
import { first, pipe, toUpper } from 'lodash/fp'
import * as React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1.5),
  },
}))

export const Avatar: React.FC<
  React.ComponentProps<typeof MuiAvatar> & { user: UserInfo } & {
    picture?: string
  }
> = ({ user, picture, ...props }) => {
  const classes = useStyles()
  const capital = pipe(toUpper, first)

  return (
    <MuiAvatar
      src={picture || user.picture}
      alt={`${user.given_name} ${user.family_name}`}
      className={classes.avatar}
      {...props}
    >
      {capital(user.given_name!)}
      {capital(user.family_name!)}
    </MuiAvatar>
  )
}
