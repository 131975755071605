import { Box, Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useImpersonation } from '../../../contexts/impersonation'
import { SearchOffIcon } from '../Icons/SearchOffIcon'
import { TeacherList } from './TeacherList'
import { EmptyState } from '@common/EmptyState'
import { Loader } from '@common/Loader'
import { useImpersonations } from '@hooks/fetch/institutionsServiceData'

export const ImpersonationTeachers: React.FC = () => {
  const { filters } = useImpersonation()
  const { teachers, loading, error } = useImpersonations(filters)
  const { t } = useTranslation()

  if (error) {
    return null
  }

  return (
    <>
      {loading ? (
        <Box width="100%">
          <Loader />
        </Box>
      ) : teachers.length ? (
        <Grid item xs={12} sm={12} md={8} xl={8}>
          <TeacherList teachers={teachers} />
        </Grid>
      ) : (
        <EmptyState
          title={t('empty.no-resultados')}
          subtitle={t('empty.no-resultados-hint')}
          icon={SearchOffIcon}
          iconSize="lg"
        />
      )}
    </>
  )
}
