import { Grid } from '@material-ui/core'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useImpersonation } from '../../contexts/impersonation'
import { TeacherImpersonationsFilter } from '../TeacherImpersonationsFilter'
import { useTeacherFilterForm } from '../TeacherImpersonationsFilter/hooks'
import EmptyState from '@common/EmptyState'
import { ImpersonationTeachers } from '@common/ImpersonationTeachers'

export const TabTeacher = () => {
  const { filters } = useImpersonation()
  const { hookform } = useTeacherFilterForm()
  const { t } = useTranslation('home')

  return (
    <>
      <Grid container spacing={2} style={{ minHeight: '400px' }}>
        <FormProvider {...hookform}>
          <TeacherImpersonationsFilter />
        </FormProvider>
        {!filters ? (
          <EmptyState
            title={t('tutor.empty.no-busqueda')}
            titleSize="md"
            icon={VisibilityOffIcon}
            iconSize="lg"
          />
        ) : (
          <ImpersonationTeachers />
        )}
      </Grid>
    </>
  )
}
