import { Box, DialogTitle } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '@hooks/responsive'

interface Props extends DialogProps {
  title: string
  description?: string
  content?: string
  cancelLabel?: string
  actionLabel?: string
  closeHandler: (event?) => void
  actionHandler?: (event?) => void
  open: boolean
  fullWidth?: boolean
}

export const CustomDialog: React.FC<Props> = ({
  title,
  description,
  content,
  cancelLabel,
  actionLabel,
  closeHandler,
  actionHandler,
  open,
  fullWidth = false,
  ...dialogProps
}) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={fullWidth}
      {...dialogProps}
    >
      <Box
        px={isMobile ? 2 : 3}
        pb={isMobile ? 2 : 3}
        pt={isMobile ? 3.25 : 6.5}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <Box pt={isMobile ? 2.75 : 2} pb={isMobile ? 4.5 : 3}>
          {description && (
            <DialogContent style={{ maxWidth: '400px' }}>
              <DialogContentText id="alert-dialog-description">
                {description}
              </DialogContentText>
            </DialogContent>
          )}
          {content && <DialogContent>{content}</DialogContent>}
        </Box>
        {actionLabel && (
          <DialogActions>
            <Button
              fullWidth={isMobile}
              onClick={closeHandler}
              variant="contained"
            >
              {cancelLabel ?? t('acciones.cancelar')}
            </Button>
            <Button
              fullWidth={isMobile}
              color="primary"
              autoFocus
              variant="contained"
              onClick={actionHandler}
            >
              {actionLabel}
            </Button>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  )
}
