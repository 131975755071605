import { SubmitHandler, UseFormMethods } from 'react-hook-form'
import { Institution } from '@models/Institution'
import { Jurisdiction } from '@models/Jurisdiction'
export interface DuplicateFormValues extends Record<string, unknown> {
  title: string
}

export interface TemplateDuplicateValues extends DuplicateFormValues {
  jurisdictionIds: Jurisdiction['id'][]
}

export interface ProjectDuplicateValues extends DuplicateFormValues {
  schoolIds: Institution['id'][]
}

export const useDuplicateForm = <
  FormValues extends DuplicateFormValues = DuplicateFormValues
>(
  hookform: UseFormMethods<FormValues>,
  onSubmit: SubmitHandler<FormValues>
) => {
  const submitHandler = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    hookform.handleSubmit<FormValues>(onSubmit)(e)
  }

  return {
    submitHandler,
  }
}
