import { BaseModel } from './BaseModel'

export class Language extends BaseModel {
  _id: string
  name: string
  code: string

  get id() {
    return this._id
  }
}
