import { findIndex } from 'lodash'
import { pull } from 'lodash/fp'
import { useRouter } from 'next/router'
import { useCallback, useContext, useState } from 'react'
import { projectSearchContext } from '../../contexts/projectSearch'
import { projectStoreContext } from '../../contexts/projectStore'
import URLSearchParamsArray, {
  cloneAsRecord,
  UrlParams,
} from '../URLSearchParamsArray'
import { AnalyticsAction, logEvent } from '@/lib/analytics'
import { useFetch } from '@/lib/http'
import { useInstitutionContext } from '@/lib/institution-context'
import { Activity } from '@models/Activity'
import { Project } from '@models/Project'

export type Organization = 'TICMAS' | 'DISNEY'

export function useFetchedProject() {
  const { keys } = useContext(projectStoreContext)
  const { query } = useRouter()
  const projectId = query.id as string
  const { data, error } = useFetch(() =>
    projectId ? keys.projectById(projectId) : null
  )
  return {
    loading: !data && !error,
    error,
    project: data ? Project.fromData(data) : undefined,
  }
}

export function useFetchedActivity() {
  const { query } = useRouter()
  const { loading, project, error } = useFetchedProject()

  let activity: Activity | null = null

  if (project) {
    activity = project.getActivityById(query.activityId as string)!
  }

  return {
    loading,
    error,
    project,
    activity,
  }
}

function useProjectSearch(
  key: string,
  otherParams: UrlParams = {},
  transformParams: { [key: string]: string } = {}
) {
  const [oldFilters, setOldFilters] = useState('')
  const { filters } = useContext(projectSearchContext)

  const { languages, levels, subjects, title } = filters

  const filtersExists = Boolean(
    languages?.length || levels?.length || subjects?.length || title
  )

  const searchFields = cloneAsRecord(filters)

  for (const [transformKey, newKey] of Object.entries(transformParams)) {
    searchFields[newKey] = filters[transformKey]
    delete searchFields[transformKey]
  }

  const queryString = new URLSearchParamsArray({
    ...searchFields,
    ...otherParams,
  }).toString()

  const { data, error, mutate } = useFetch<Project[]>(
    () => `${key}?${queryString}`,
    {
      refreshInterval: 0,
    }
  )

  if (data && filtersExists && oldFilters !== JSON.stringify(filters)) {
    logEvent(AnalyticsAction.SEARCH_FILTERS_PROJECTS, {
      filtro_idioma: filters.languages,
      filtro_grado_sugerido: filters.levels,
      filtro_asignatura: filters.subjects,
      title: filters.title,
    })

    setOldFilters(JSON.stringify(filters))
  }

  const removeProject = useCallback(
    async (project: Project) => {
      await mutate(pull(project))
    },
    [mutate]
  )

  const hideProject = useCallback(
    async (project: Project) => {
      await mutate((projects = []) => {
        const updatedProjects = [...projects]
        updatedProjects.splice(
          findIndex(projects, { _id: project._id }),
          1,
          project
        )
        return updatedProjects
      })
    },
    [mutate]
  )

  const copyProject = useCallback(
    async (project: Project, copiedProjects: Project[]) => {
      const copiedProjectInContext = copiedProjects.find(p =>
        p.isTemplate() && p.jurisdictionIds && project.jurisdictionIds
          ? p.jurisdictionIds[0] === project.jurisdictionIds[0]
          : p.schoolIds &&
            project.schoolIds &&
            p.schoolIds[0] === project.schoolIds[0]
      )

      if (copiedProjectInContext) {
        await mutate((projects = []) => {
          const currentProjects = [...projects]
          currentProjects.splice(
            findIndex(projects, { _id: project._id }),
            0,
            copiedProjectInContext
          )
          return currentProjects
        })
      }
    },
    [mutate]
  )

  return {
    filters,
    loading: !data && !error,
    error,
    projects: data ? data.map(d => Project.fromData(d)) : null,
    removeProject,
    hideProject,
    copyProject,
  }
}

export function useFetchedProjects() {
  const { keys } = useContext(projectStoreContext)
  const { selectedInstitution } = useInstitutionContext()
  return useProjectSearch(
    keys.projects(),
    {
      schoolIds: selectedInstitution ? [selectedInstitution.id] : [],
    },
    { title: 'q' }
  )
}

export function useFetchedLibrary(organization: Organization) {
  const { keys } = useContext(projectStoreContext)
  const { selectedJurisdiction } = useInstitutionContext()
  const projectSearch = useProjectSearch(
    keys.library(),
    {
      jurisdictionId: selectedJurisdiction?.id,
    },
    { title: 'q', levels: 'academicCycleLevels' }
  )

  const projects = projectSearch.projects?.filter(
    project => project.organization === organization
  )

  let exactMatchProjects: Project[] | null = null
  let suggestedProjects: Project[] | null = null

  if (!projectSearch.loading) {
    const academicCycleLevels = projectSearch.filters?.levels

    if (academicCycleLevels && academicCycleLevels.length > 0) {
      exactMatchProjects = []
      suggestedProjects = []
      projects?.forEach(project => {
        if (academicCycleLevels.includes(project.level)) {
          exactMatchProjects!.push(project)
        } else {
          suggestedProjects!.push(project)
        }
      })
    } else {
      exactMatchProjects = projects ?? null
    }
  }

  return {
    ...projectSearch,
    exactMatchProjects,
    suggestedProjects,
  }
}
