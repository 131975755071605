import { Type } from 'class-transformer'
import { BaseModel } from './BaseModel'
import { Criterion } from './Criterion'
import { Descriptor } from './Descriptor'
import { PerformanceLevel } from './PerformanceLevel'

export class Rubric extends BaseModel {
  @Type(() => PerformanceLevel)
  performanceLevels: PerformanceLevel[]

  @Type(() => Criterion)
  criteria: Criterion[]

  @Type(() => Descriptor)
  descriptors: Descriptor[]

  hasCriteria() {
    return this.criteria && this.criteria.length > 0
  }

  updatePerformanceLevel(savedPerformanceLevel: PerformanceLevel) {
    this.performanceLevels = this.performanceLevels.map(performanceLevel =>
      performanceLevel.id === savedPerformanceLevel.id
        ? savedPerformanceLevel
        : performanceLevel
    )
  }

  updateDescriptor(savedDescriptor: Descriptor) {
    this.descriptors = this.descriptors.map(descriptor =>
      descriptor.id === savedDescriptor.id ? savedDescriptor : descriptor
    )
  }

  deleteCriterion(criterionId: string) {
    const criterionIndex = this.criteria.findIndex(c => c._id == criterionId)
    this.criteria.splice(criterionIndex, 1)
    this.descriptors = this.descriptors.filter(
      descriptor => descriptor.criterion !== criterionId
    )
  }
}
