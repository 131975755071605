import { Type } from 'class-transformer'
import { ActivityType, BaseActivity } from './Activity'
import { BaseContentItem, ContentItem } from './ContentItem'
import { contentItemTypes } from './contentItemTypes'
import typeTranslations from '@hooks/fetch/activities'

export class ContentActivity extends BaseActivity {
  type = ActivityType.CONTENT as const

  @Type(() => BaseContentItem, {
    discriminator: {
      property: 'type',
      subTypes: Array.from(contentItemTypes.entries()).map(([name, value]) => ({
        name,
        value,
      })),
    },
    keepDiscriminatorProperty: true,
  })
  items: ContentItem[] = []

  getTypeName() {
    return typeTranslations[ActivityType.CONTENT].name
  }

  getTypeDescription() {
    return typeTranslations[ActivityType.CONTENT].description
  }

  hasItems() {
    return this.items && this.items.length > 0
  }

  addItem(item: ContentItem) {
    this.items.push(item)
  }

  updateItem(newItem: ContentItem) {
    this.items = this.items.map(item =>
      item.id === newItem.id ? newItem : item
    )
  }

  reorderItems(prevIndex: number, newIndex: number): void {
    const { items } = this
    items.splice(newIndex, 0, items.splice(prevIndex, 1)[0])
  }

  removeItem(item: ContentItem) {
    this.items = this.items.filter(it => it.id !== item.id)
  }
}
