import { UserInfo, Resource } from '@ticmas/common-interfaces'
import { addDays } from 'date-fns'
import { BaseModel } from './BaseModel'

export class CurricularContent extends BaseModel {
  _id: string
  title: string
  duration: number
  offset: string
  resource: Resource
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachments: any[]
  assignedAt: Date
  assignedBy: UserInfo
  startDate: string

  getStartDate() {
    return new Date(this.startDate.replace(/-/g, '/'))
  }

  getEndDate() {
    return addDays(this.getStartDate(), this.duration)
  }
}
