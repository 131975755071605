import i18n from '@/lib/i18n'

const typeTranslations = {
  action: { name: '', description: '' },
  exam: { name: '', description: '' },
  content: { name: '', description: '' },
  quiz: { name: '', description: '' },
}

function fill() {
  typeTranslations.action = {
    name: i18n.t('common:recursos.accion.type-name'),
    description: i18n.t('common:recursos.accion.description'),
  }

  typeTranslations.exam = {
    name: i18n.t('common:recursos.evaluacion.type-name'),
    description: i18n.t('common:recursos.evaluacion.description'),
  }

  typeTranslations.content = {
    name: i18n.t('common:recursos.contenido.type-name'),
    description: i18n.t('common:recursos.contenido.description'),
  }

  typeTranslations.quiz = {
    name: i18n.t('common:recursos.quiz.type-name'),
    description: i18n.t('common:recursos.quiz.description'),
  }
}

fill()
i18n.on('languageChanged init', () => {
  fill()
})

export default typeTranslations
