import { useCallback, useEffect, useState } from 'react'
import { getInstitutionModules } from './fetch/institutionsServiceData'
import { useImpersonation } from '@/contexts/impersonation'
import { useInstitutionContext } from '@/lib/institution-context'

export const useDisney = () => {
  const { getAxiosInstance } = useImpersonation()
  const axiosInstance = getAxiosInstance()
  const { selectedInstitution } = useInstitutionContext()
  const [isSubscribeToDisney, setIsSubscribeToDisney] = useState<boolean>(false)

  const findDisneyInModules = useCallback(async () => {
    if (selectedInstitution) {
      const institutionModules = await getInstitutionModules(
        selectedInstitution?.id,
        axiosInstance
      )
      if (institutionModules) {
        setIsSubscribeToDisney(
          institutionModules.modules.some(m => m.id === 'disney')
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstitution])

  useEffect(() => {
    findDisneyInModules()
  }, [selectedInstitution, findDisneyInModules])

  return {
    isSubscribeToDisney,
  }
}
