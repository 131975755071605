import { Type } from 'class-transformer'
import { BaseModel } from './BaseModel'

export class Feedback extends BaseModel {
  _id: string
  text: string
  studentId: string
  teacherId: string
  activityId: string

  @Type(() => Date)
  date: Date

  @Type(() => Date)
  createdAt: Date

  @Type(() => Date)
  updatedAt: Date

  get id() {
    return this._id
  }
}
