import { Box, Typography, DialogTitle } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { Loader } from './Loader'

interface Props {
  loading: boolean
  title: string
}

export const LoaderWithText: React.FC<Props> = ({ loading, title }) => {
  return (
    <Dialog maxWidth="xs" open={loading} aria-labelledby="alert-dialog-title">
      <Loader />
      <Box px={9} pt={4} pb={5}>
        <DialogTitle id="alert-dialog-title">
          <Typography align="center">
            {title.split('\n').map((line, index) => (
              <Box key={index}>{line}</Box>
            ))}
          </Typography>
        </DialogTitle>
      </Box>
    </Dialog>
  )
}
