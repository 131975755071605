import { flatten, isString, isUndefined, merge, omit, values } from 'lodash'
import React, { useContext, useState } from 'react'
import { Nullable, parseHour, useEnv, ClientSideEnvironment } from './env'
import { Configuration } from '@models/Configuration'

type ContactRangeType = 'weekdays' | 'weekends' | 'holidays'

type ConfigurationKeys =
  | 'teacherTutorials'
  | 'studentTutorials'
  | 'clasesTeacherUrl'
  | 'clasesTeacherUrlET'
  | 'teacherPlatformBaseUrl'
  | 'clasesStudentUrl'
  | 'helpEmail'
  | 'helpWhatsapp'
  | 'teacherTrainingHelpEmail'
  | 'trackingIdGA'
  | 'selfAssistedTrainingStudent'
  | 'selfAssistedTrainingTeacher'
  | 'contactHours'
  | 'contactHours_openWeekdays'
  | 'contactHours_closeWeekdays'
  | 'contactHours_openWeekends'
  | 'contactHours_closeWeekends'
  | 'contactHours_openHolidays'
  | 'contactHours_closeHolidays'
  | 'welcomeScreen'

// All keys are forcefully string values per the db's datatype (+ nullable)
type ConfigurationObjectStrings = Partial<
  Nullable<{ [key in ConfigurationKeys]: string }>
>

export interface ConfigurationObject
  extends Omit<ConfigurationObjectStrings, 'contactHours'> {
  contactHours?: Partial<ClientSideEnvironment['contactHours']> | null
}

interface ConfigurationContext extends ConfigurationObject {
  configurationList: Configuration[]
  setConfigurationList(configurations: Configuration[]): void
  setConfiguration: (configuration: ConfigurationObject) => void
}

const configurationContext = React.createContext<ConfigurationContext>(
  null as unknown as ConfigurationContext
)

export const ConfigurationProvider = props => {
  const [configurationList, setConfigurationList] = useState<Configuration[]>(
    []
  )

  const [configuration, setConfiguration] = useState<ConfigurationObject>({})

  return (
    <configurationContext.Provider
      value={{
        configurationList,
        setConfigurationList,
        setConfiguration,
        ...configuration,
      }}
      {...props}
    />
  )
}

export function useConfigurationContext() {
  return useContext(configurationContext)
}

export function useConfiguration() {
  const configuration: ConfigurationObject = merge(
    { ...useEnv() },
    omit(useConfigurationContext(), [
      'setConfigurationList',
      'setConfiguration',
      'configurationList',
      'contactHours',
    ])
  )

  const contactHoursKeys = {
    weekdays: ['contactHours_openWeekdays', 'contactHours_closeWeekdays'],
    weekends: ['contactHours_openWeekends', 'contactHours_closeWeekends'],
    holidays: ['contactHours_openHolidays', 'contactHours_closeHolidays'],
  }

  configuration.contactHours = Object.entries(contactHoursKeys).reduce(
    (acc, [rangeKey, hourKeys]: [string, [string, string]]) => {
      const [openingTime, closingTime] = mergeContactHours(
        configuration,
        rangeKey as ContactRangeType,
        hourKeys
      )
      acc[rangeKey] = { openingTime, closingTime }
      return acc
    },
    {}
  )

  return omit(configuration, flatten(values(contactHoursKeys)))
}

const mergeContactHours = (
  config: ConfigurationObject,
  rangeKey: ContactRangeType,
  keys: [string, string]
): (string | null)[] => {
  const envRange = !!config.contactHours && config.contactHours[rangeKey]
  return keys.map((hourKey, isClosing) => {
    const parsedHour = isString(config[hourKey])
      ? parseHour(config[hourKey])
      : null

    return isUndefined(config[hourKey]) && envRange
      ? envRange[isClosing ? 'closingTime' : 'openingTime']
      : parsedHour
  })
}
