import { Type } from 'class-transformer'
import { BaseModel } from './BaseModel'
import { Competence } from './Competence'
import { Descriptor } from './Descriptor'
import { Level } from './Level'
import { Subject } from './Subject'

export class Criterion extends BaseModel {
  _id: string
  title: string
  key: string
  description: string
  indicator: string
  fromPool: boolean
  subject: Subject['id']
  levels: Level['id'][]
  defaultDescriptors: Descriptor[]

  @Type(() => Competence)
  competences: Competence[]

  get id() {
    return this._id
  }
}
