import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'
import React from 'react'
import type { FC } from 'react'

export const StudentSCLIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 36 24">
      <path
        d="M16.5 0.25L0.916626 8.75L6.58329 11.8383V20.3383L16.5 25.75L26.4166 20.3383V11.8383L29.25 10.2942V20.0833H32.0833V8.75L16.5 0.25ZM26.1616 8.75L16.5 14.02L6.83829 8.75L16.5 3.48L26.1616 8.75ZM23.5833 18.6525L16.5 22.52L9.41663 18.6525V13.3825L16.5 17.25L23.5833 13.3825V18.6525Z"
        fill="#743AC0"
      />
    </SvgIcon>
  )
}
