import { withStyles } from '@material-ui/core'

export const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#ffffff',
    },
    '.flex-1': {
      flex: 1,
    },
    '.italic': {
      fontStyle: 'italic',
    },
    '.full-width': {
      width: '100%',
    },
    '.full-height': {
      height: '100%',
    },
    '.full-size': {
      width: '100%',
      height: '100%',
    },
    '.fit-contain': {
      objectFit: 'contain',
    },
  },
})(() => null)
