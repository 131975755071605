import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { useImpersonation } from '../../../contexts/impersonation'
import { useUserContextSelector } from '../Navbar/hooks'
import { Teacher } from '@models/Teacher'

export const useTeacherExpand = () => {
  const [teachersOpen, setTeachersOpen] = useState<string[]>([])

  const toggleExpand = (teacherId: string) => {
    const teachers = [...teachersOpen]

    if (teachers.includes(teacherId)) {
      const teacherIndex = teachers.findIndex(c => c === teacherId)
      teachers.splice(teacherIndex, 1)
    } else {
      teachers.push(teacherId)
    }

    setTeachersOpen(teachers)
  }

  return {
    toggleExpand,
    teachersOpen,
  }
}

export const useImpersonateTeacher = () => {
  const { enqueueSnackbar } = useSnackbar()
  const router = useRouter()

  const { resetContext } = useUserContextSelector()
  const { impersonate } = useImpersonation()

  const [impersonateDialogOpen, setImpersonateDialogOpen] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState<Teacher>()

  const openImpersonateDialog = (teacher: Teacher) => {
    setImpersonateDialogOpen(true)
    setSelectedTeacher(teacher)
  }
  const closeImpersonateDialog = () => setImpersonateDialogOpen(false)

  const confirmImpersonate = useCallback(
    async (teacher: Teacher) => {
      try {
        await impersonate(teacher)
        resetContext()
        router.push('/')
      } catch (e) {
        enqueueSnackbar('Ocurrió un error, por favor intentá nuevamente.')
      }
    },
    [enqueueSnackbar, impersonate, resetContext, router]
  )

  return {
    confirmImpersonate,
    selectedTeacher,
    impersonateDialogOpen,
    openImpersonateDialog,
    closeImpersonateDialog,
  }
}
