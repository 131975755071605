import { ErrorOutlineRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '@common/EmptyState'

export const ErrorEmptyState = () => {
  const { t } = useTranslation('curricular')

  return (
    <EmptyState
      icon={ErrorOutlineRounded}
      title={t('empty-state.error-title')}
      subtitle={t('empty-state.error-subtitle')}
    />
  )
}
