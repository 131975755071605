import {
  LDProvider,
  useFlags,
  useLDClient,
} from 'launchdarkly-react-client-sdk'
import getConfig from 'next/config'
import {
  useCallback,
  type FunctionComponent,
  type PropsWithChildren,
  type ReactElement,
} from 'react'
import { useUserInfo } from './auth'

const { publicRuntimeConfig } = getConfig()

export const LaunchDarklyFeatureFlagProvider: FunctionComponent<
  PropsWithChildren<{
    children: ReactElement
  }>
> = ({ children }) => {
  return publicRuntimeConfig.launchDarklyClientId ? (
    <LDProvider
      reactOptions={{ useCamelCaseFlagKeys: false }}
      clientSideID={publicRuntimeConfig.launchDarklyClientId}
    >
      {children}
    </LDProvider>
  ) : (
    children
  )
}

export function LaunchdarklyIdentify() {
  const user = useUserInfo()
  const ldClient = useLDClient()

  const identify = useCallback(
    (institution: { id: string; name: string }) => {
      const kind = 'abp-user'

      if (user?.sub) {
        ldClient?.identify({
          kind,
          key: user.sub,
          email: user.email,
          name: user.preferred_username,
          firstName: user.given_name,
          lastName: user.family_name,
          user: {
            email: user.email,
            given_name: user.given_name,
            family_name: user.family_name,
          },
          institution: {
            id: institution?.id,
            name: institution?.name,
          },
        })
      } else {
        ldClient?.identify({
          kind,
          key: 'anonymous',
        })
      }
    },
    [ldClient, user]
  )

  return { identify }
}

export const useLaunchDarklyFeatureFlag = (key: string) => {
  const flags = useFlags()
  return flags[key]
}
