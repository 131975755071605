import { BaseModel } from './BaseModel'
import { Student } from './Student'

export class Group extends BaseModel {
  _id: string
  title: string
  students: Student[] = []
  studentIds?: string[]
  projectId: string

  get id() {
    return this._id
  }

  isGeneralGroup() {
    return this.id === null
  }
}
