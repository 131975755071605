import { BaseModel } from './BaseModel'
import { CohortWithCourses } from './CohortWithCourses'
import { Institution } from './Institution'

export class InstitutionCohorts extends BaseModel {
  static getPath() {
    return 'v2/is/graphql'
  }

  static getQuery(institutionId: Institution['id']) {
    return `{
      institution(id: "${institutionId}") {
        id
        cohorts {
          id
          name
          courses {
            id
            name
          }
        }
      }
    }`
  }

  id: Institution['id']
  cohorts: CohortWithCourses[]
}
