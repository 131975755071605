import { Avatar } from '@material-ui/core'
import { first, pipe, toUpper } from 'lodash/fp'

interface Props {
  className?: string
  givenName: string
  familyName: string
}

const TeacherAvatar: React.FC<Props> = ({
  givenName,
  familyName,
  ...props
}) => {
  const capital = pipe(toUpper, first)

  return (
    <Avatar {...props} alt={`${givenName} ${familyName}`}>
      {capital(givenName)}
      {capital(familyName)}
    </Avatar>
  )
}
export default TeacherAvatar
