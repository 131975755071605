import type { SetStateAction, Dispatch } from 'react'
import { useState } from 'react'

type useModalTypes = {
  isOpened: boolean
  setIsOpened: Dispatch<SetStateAction<boolean>>
  toggle: () => void
}

export const useModal = (initialMode = false): useModalTypes => {
  const [isOpened, setIsOpened] = useState(initialMode)
  const toggle = () => setIsOpened(!isOpened)
  return { isOpened, setIsOpened, toggle }
}
