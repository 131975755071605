import 'reflect-metadata'
import { ClassConstructor, plainToClass } from 'class-transformer'
import { assign } from 'lodash/fp'

export class BaseModel {
  static fromData<T>(this: ClassConstructor<T>, data): T {
    return plainToClass(this, data)
  }

  assign(data) {
    return plainToClass(
      this.constructor as ClassConstructor<this>,
      assign(this, data)
    )
  }

  exists() {
    return !!this['id']
  }
}
