import { BaseModel } from './BaseModel'
import { Criterion } from './Criterion'
import { PerformanceLevel } from './PerformanceLevel'

export class Descriptor extends BaseModel {
  _id: string
  value: string
  performanceLevel: PerformanceLevel['id']
  criterion: Criterion['id']

  get id() {
    return this._id
  }
}
