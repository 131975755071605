import { Box, Grid } from '@material-ui/core'
import { Hidden } from '@mui/material'
import router from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchEmptyState } from './SearchEmptyState'
import { StudentProjectsEmptyState } from './StudentProjectsEmptyState'
import { TeacherProjectsEmptyState } from './TeacherProjectsEmptyState'
import { createProjectEvent } from '@/lib/analytics'
import { useUserInfo } from '@/lib/auth'
import { ProjectCardStudent } from '@/students/components/ProjectCard'
import { DisneyLogotype } from '@/students/components/ProjectCard/disney-logotype'
import { DocumentTitle } from '@common/DocumentTitle'
import { Loader } from '@common/Loader'
import { LoaderWithText } from '@common/LoaderWithText'
import { MobileFabButton } from '@common/MobileFabButton'
import { ProjectCard } from '@common/ProjectCard'
import { useFetchedLanguages } from '@hooks/fetch/languages'
import { useFetchedProjects } from '@hooks/fetch/project'
import { Language } from '@models/Language'
import { Project } from '@models/Project'
import { Role } from '@models/Role'

const ConditionalContent = ({ loading, hasProjects, searching, children }) => {
  const user = useUserInfo()

  if (loading) {
    return (
      <Box width="100%">
        <Loader />
      </Box>
    )
  }
  if (hasProjects) {
    return <>{children}</>
  }
  if (searching) {
    return <SearchEmptyState />
  }
  if (user.hasRole(Role.TEACHER)) {
    return <TeacherProjectsEmptyState />
  }
  if (user.hasRole(Role.STUDENT)) {
    return <StudentProjectsEmptyState />
  }
  return null
}

interface Props {
  setHideCreateButton: (hide: boolean) => void
  searching: boolean
}

export const TabProject: React.FC<Props> = ({
  setHideCreateButton,
  searching,
}) => {
  const { t } = useTranslation(['home', 'common'])
  const [copying, setCopying] = useState(false)
  const { projects, loading, error, removeProject, hideProject, copyProject } =
    useFetchedProjects()

  useEffect(() => {
    if (projects?.length === 0 && !loading && !searching) {
      setHideCreateButton(true)
    } else {
      setHideCreateButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, loading, searching])

  const user = useUserInfo()

  const {
    languages,
    loading: languagesLoading,
    error: languagesError,
  } = useFetchedLanguages()

  const findLanguage = (languageId: string) =>
    languages.find(l => l.id === languageId)

  const disneyProject = (project: Project) => project.organization === 'DISNEY'

  if (error || languagesError) {
    return null
  }

  return (
    <>
      <DocumentTitle titles={[t('home:tabs.proyectos-en-curso')]} />
      <Grid container spacing={2} style={{ minHeight: '400px' }}>
        {copying && (
          <LoaderWithText
            title={t('home:snackbar.copy.loading')}
            loading={copying}
          />
        )}
        <ConditionalContent
          loading={loading || languagesLoading}
          hasProjects={projects?.length}
          searching={searching}
        >
          {projects?.map((project: Project) => {
            return (
              <Grid item key={project.id} xs={12} sm={6} md={4} lg={3}>
                {user.hasRole(Role.TEACHER) ? (
                  <ProjectCard
                    project={project}
                    onDelete={removeProject}
                    onHide={hideProject}
                    onCopy={(project, copiedProjects) => {
                      setCopying(false)
                      return copyProject(project, copiedProjects)
                    }}
                    onBeforeCopy={() => {
                      setCopying(true)
                    }}
                  />
                ) : (
                  <ProjectCardStudent
                    title={project.title}
                    coverUrl={project.coverUrl ?? ''}
                    language={findLanguage(project.language) as Language}
                    institutionLogotype={
                      disneyProject(project) && <DisneyLogotype />
                    }
                    onClick={() => router.push(project.getDetailRoute())}
                  />
                )}
              </Grid>
            )
          })}
        </ConditionalContent>
      </Grid>
      {!loading && Project.userCanCreateProjects(user) && (
        <Hidden lgUp>
          <MobileFabButton
            onClick={() => createProjectEvent()}
            label={t('home:crear-proyecto')}
            href={Project.getCreationRoute()}
          />
        </Hidden>
      )}
    </>
  )
}
