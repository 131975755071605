import { BaseContentItem, ContentItemType } from './ContentItem'
import i18n from '@/lib/i18n'

export class LinkContentItem extends BaseContentItem {
  type = ContentItemType.LINK as const
  url: string

  getTypeName(): string {
    return i18n.t('content-activity:content.web-site')
  }
}
