import { toNumber } from 'lodash'
import { createContext, useContext } from 'react'

// Nullable is deeply nullable :D
export type Nullable<T> = { [K in keyof T]: T[K] | null }

type ContactRange = Nullable<{
  openingTime: string
  closingTime: string
}> | null

export interface ClientSideEnvironment {
  amplitudeApiKey: string
  sentryDsn: string
  nodeEnv: string
  apiEndpoint: string
  helpEmail: string
  helpWhatsapp: string
  launchDarklyClientId: string
  teacherTrainingHelpEmail: string
  studentTutorials: string
  teacherTutorials: string
  selfAssistedTrainingStudent: string
  selfAssistedTrainingTeacher: string
  keycloak: {
    url: string
    realm: string
    clientId: string
  }
  classroomDomainAvailable: string
  featureFlags: {
    examActivity: boolean
    quizActivity: boolean
    classroom: boolean
    tutorbot: boolean
    tutorbotInstitutions: string[]
  }
  hotjar?: {
    id: number
    snippetVersion: number
  }
  trackingIdGA: string
  gtmId: string
  contactHours: {
    weekdays: ContactRange
    weekends: ContactRange
    holidays: ContactRange
  }
  clasesTeacherUrl: string
  clasesTeacherUrlET: string
  teacherPlatformBaseUrl: string
  clasesStudentUrl: string
  graphqlEndpoint: string
  production: boolean
  webSocket: boolean
  webSocketEnv: boolean
}

export const parseHour = (hour: string): string | null =>
  Math.min(toNumber(hour.replace(':', '.')), 24).toFixed(2) || null

export const parseContactRange = (contactRange: string): ContactRange => {
  const [openingHour, closingHour] = contactRange
    .split('-')
    .concat('', '')
    .slice(0, 2)

  return {
    openingTime: parseHour(openingHour),
    closingTime: parseHour(closingHour),
  }
}

export const EnvContext = createContext<ClientSideEnvironment>(
  {} as unknown as ClientSideEnvironment
)

export const useEnv = () => {
  return useContext(EnvContext)
}
