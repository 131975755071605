import { BaseModel } from './BaseModel'

export class Competence extends BaseModel {
  _id: string
  key: string
  description: string

  get id() {
    return this._id
  }
}
