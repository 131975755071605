import { createSkin } from './skinBuilder'

export const colorsScores = {
  1: { background: '#9FAEF9', font: '#2D2D2D' },
  2: { background: '#6882C8', font: '#ffffff' },
  3: { background: '#3B5EA0', font: '#ffffff' },
  4: { background: '#002F6C', font: '#ffffff' },
}

export const activityColors = {
  action: { main: '#18A086', light: '#e5f7e8' },
  content: { main: '#9900FF', light: '#d5effa' },
  exam: { main: '#0975C3', light: '#fff2ff' },
  journal: { main: '#f9a825', light: '#fff2d7' },
  quiz: { main: '#8512bb', light: '#e7defb' },
}

export const defaultSkin = createSkin(
  {
    primaryBtnTextColor: '#fff',
  },
  {
    apple_touch_favicon: '/skins/default/favicon-ticmas-apple-touch.png',
    size_32_favicon: '/skins/default/favicon-ticmas-32.png',
    size_16_favicon: '/skins/default/favicon-ticmas-16.png',
  }
)

export const graphPalette = [
  '#000066',
  '#9900ff',
  '#ff33cc',
  '#ff0000',
  '#ffcc33',
  '#00d5c7',
]

export interface GraphColors {
  backgroundColor: string
  borderColor: string
}

export const graphColors: GraphColors[] = graphPalette.map(color => ({
  // hex '4d' = alpha 30%
  backgroundColor: `${color}4d`,
  borderColor: color,
}))

export const getRandomHslColor = () =>
  `hsl(${Math.floor(Math.random() * 360)}deg 100% 50%)`

export const getRandomGraphColors = (): GraphColors => {
  const rndColor = getRandomHslColor()
  return {
    backgroundColor: rndColor.replace(')', ' / 30%)'),
    borderColor: rndColor,
  }
}

export const ticmasColor = {
  blue: '#000661',
  blueBlack: '#1E2442',
  danger: '#e00043',
  disneyBlue: '#000066',
  toneWhite: '#FFFFFF',
  grey: '#EDEDED66',
  neutralDarkness: '#151617',
  bannerText: '#E5E5F0',
  monochrome: { grey12: '#191B23' },
}

export const gradients = {
  banner:
    'linear-gradient(90.02deg, #1E2442 0.02%, #1E2442 21.4%, #1E2442 51.92%, #1E2442 77.6%, #29BDEF 99.99%)',
}

export const curricularColor = {
  green: '#3CA529',
  yellow: '#F9A825',
  grey: '#808080',
}
