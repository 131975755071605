import { BaseModel } from './BaseModel'
import { FileContentItem } from './FileContentItem'
import { LinkContentItem } from './LinkContentItem'
import { RichTextContentItem } from './RichTextContentItem'
import { VideoContentItem } from './VideoContentItem'

export enum ContentItemType {
  RICH_TEXT = 'richText',
  VIDEO = 'video',
  LINK = 'link',
  FILE = 'file',
}

export type ContentItem =
  | RichTextContentItem
  | VideoContentItem
  | LinkContentItem
  | FileContentItem

export abstract class BaseContentItem extends BaseModel {
  static getRoute() {
    return '/actividades/contenido'
  }

  _id: string
  title: string
  abstract type: ContentItemType

  get id() {
    return this._id
  }

  abstract getTypeName(): string
}
