import { Box, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useIsMobile } from '@hooks/responsive'

interface Props {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmClassroomDialog: React.FC<Props> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation(['common', 'home'])

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box px={3} pt={5} pb={3}>
        <DialogTitle>{t('home:proyecto.classroom-dialog.title')}</DialogTitle>
        <DialogContent>
          <Box py={2}>
            <DialogContentText>
              <Typography color="textSecondary" style={{ fontSize: '16px' }}>
                {t('home:proyecto.classroom-dialog.message')}
              </Typography>
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button fullWidth={isMobile} onClick={onCancel} variant="contained">
            {t('common:acciones.cancelar')}
          </Button>
          <Button
            fullWidth={isMobile}
            color="primary"
            autoFocus
            variant="contained"
            onClick={onConfirm}
          >
            {t('common:acciones.confirmar')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
