import { BaseModel } from './BaseModel'
import { Cohort } from './Cohort'
import { Institution } from './Institution'

interface Course {
  id: Cohort['id']
  name: Cohort['name']
}

export class CohortWithCourses extends BaseModel {
  static getPath() {
    return 'v2/is/graphql'
  }

  static getQuery(institutionId: Institution['id']) {
    const date = new Date().toISOString()
    return `{
      cohorts (limit: 300, individual: true, role: TEACHER, institutionId: "${institutionId}", effectiveAt: "${date}") {
        id
        name
        courses {
          id
          name
        }
      }
    }`
  }

  courses: Course[]
  id: Cohort['id']
  name: Cohort['name']
}
