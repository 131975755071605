import { Fab, FabProps, makeStyles } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import NextLink from 'next/link'
import React, { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  button: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.modal,
  },
}))

interface Prop {
  label: string
  href: string
  onClick?: FabProps['onClick']
}

export const MobileFabButton: React.FC<Prop> = ({ label, href, onClick }) => {
  const classes = useStyles()
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <NextLink href={href} passHref>
      <Fab
        aria-label={`${label}`}
        className={classes.button}
        color="primary"
        onClick={onClick}
        variant={scrollPosition ? 'extended' : 'round'}
      >
        <AddIcon />
        {scrollPosition > 0 && label}
      </Fab>
    </NextLink>
  )
}
