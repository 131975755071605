import { Box, Button } from '@material-ui/core'
import { AutoAwesome } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { AnalyticsAction, logEvent } from '@/lib/analytics'
import { useNewTeacherPlatform } from '@hooks/newTeacherPlatform'

export const NewTeacherPlatformButton = () => {
  const { t } = useTranslation('navbar')
  const { handleAcceptNewPlatform, user } = useNewTeacherPlatform()

  return (
    <Button
      color="primary"
      variant="outlined"
      style={{ borderRadius: '50px' }}
      onClick={() => {
        logEvent(AnalyticsAction.GO_NEW_PLATFORM_NAV, {
          usuario_id: user.id,
          nombre_usuario: user.preferred_username,
          roles: user.roles,
        })
        handleAcceptNewPlatform()
      }}
    >
      <Box mr={1}>{t('new-teacher-platform-btn')}</Box>
      <AutoAwesome />
    </Button>
  )
}
