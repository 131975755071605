import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'
import React from 'react'
import type { FC } from 'react'

export const AllProjectsIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6201 0.721141C17.3848 0.530793 17.0759 0.457256 16.7801 0.521141L9.00006 2.27114L1.22006 0.501141C0.919395 0.433236 0.604216 0.50778 0.365829 0.70318C0.127441 0.89858 -0.00751447 1.193 5.83674e-05 1.50114V13.7011C-0.0115913 14.1778 0.314906 14.5964 0.780058 14.7011L8.78006 16.5011H9.22006L17.2201 14.7011C17.6852 14.5964 18.0117 14.1778 18.0001 13.7011V1.50114C17.9987 1.19687 17.8588 0.909788 17.6201 0.721141ZM2.00006 2.75114L8.00006 4.10114V14.2511L2.00006 12.9011V2.75114ZM10.0001 14.2511L16.0001 12.9011V2.75114L10.0001 4.10114V14.2511Z"
        fill="black"
      />
    </SvgIcon>
  )
}
