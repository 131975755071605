import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import { Hidden } from '@mui/material'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { ticmasColor } from '@/skins/default'
import { Project } from '@models/Project'

const useStyles = makeStyles(theme => ({
  emptyState: {
    textAlign: 'center',
    padding: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
      fontSize: 76,
      color: ticmasColor.blueBlack,
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: ticmasColor.blueBlack,
  },
  subtitle: {
    fontSize: '18px',
    color: ticmasColor.blueBlack,
    marginBottom: theme.spacing(5.5),
  },
  libraryBtn: {
    padding: '7px 16px',
    marginLeft: theme.spacing(6),
  },
}))

export const TeacherProjectsEmptyState = () => {
  const { t } = useTranslation('home')
  const router = useRouter()
  const classes = useStyles()

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12} sm={10} md={6}>
        <Box className={classes.emptyState}>
          <LibraryBooksOutlinedIcon />
          <Typography variant="h2" className={classes.title}>
            {t('empty.no-projects')}
            <Hidden only="xs">
              <br />
            </Hidden>
          </Typography>
          <Typography variant="h3" className={classes.subtitle}>
            {t('empty.no-projects-hint')}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              router.push('/biblioteca-abp')
            }}
          >
            {t('go-to-library')}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className={classes.libraryBtn}
            onClick={() => {
              router.push(Project.getCreationRoute())
            }}
          >
            {t('crear-proyecto')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
