import { map, set } from 'lodash/fp'
import { useSnackbar } from 'notistack'
import { useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useImpersonation } from '../../../contexts/impersonation'
import { projectStoreContext } from '../../../contexts/projectStore'
import { useUserInfo } from '@/lib/auth'
import { useInstitutionContext } from '@/lib/institution-context/index'
import {
  getInstitutionModules,
  useInstitutions,
  useJurisdictions,
} from '@hooks/fetch/institutionsServiceData'
import { useFetchedProject } from '@hooks/fetch/project'
import { Institution } from '@models/Institution'
import { Module } from '@models/InstitutionModules'
import { Jurisdiction } from '@models/Jurisdiction'
import { Role } from '@models/Role'

export const useBurguerMenu = () => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)

  const toggleBurgerMenu =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setBurgerMenuOpen(open)
    }

  return {
    burgerMenuOpen,
    toggleBurgerMenu,
  }
}

export const useLanguageSwitcher = () => {
  const { i18n, t } = useTranslation(['common', 'navbar'])
  const { enqueueSnackbar } = useSnackbar()

  const languages = {
    'es-AR': {
      code: 'es-AR',
      name: 'Español',
      country: 'Argentina',
    },
    es: {
      code: 'es',
      name: 'Español',
      country: 'Neutro',
    },
    en: {
      code: 'en',
      name: 'English',
      country: 'USA',
    },
    /* 'es-MX': {
      code: 'es-MX',
      name: 'Español',
      country: 'Latinoamérica',
    }, */
  }

  let currentLanguage = languages[i18n.language] ?? languages[0]
  const getLanguagePrettyName = (code?: string) => {
    const lng = code ? languages[code] : currentLanguage
    return `${lng.name} (${lng.country})`
  }

  const changeLanguage = (code: string): void => {
    if (code && !!languages[code]) {
      currentLanguage = languages[code]
      i18n.changeLanguage(code, err => {
        if (err) {
          enqueueSnackbar(t('common:snackbar.ocurrio-error'))
        } else {
          enqueueSnackbar(
            `${t(
              'navbar:snackbar.changed-language-to'
            )} ${getLanguagePrettyName(code)}`
          )
        }
      })
    }
  }

  return { languages, currentLanguage, changeLanguage, getLanguagePrettyName }
}

export const useUploadAvatar = () => {
  const { updateProfileAvatar } = useContext(projectStoreContext)

  const { t } = useTranslation(['common', 'navbar'])
  const { enqueueSnackbar } = useSnackbar()

  const uploadAvatar = (file: File): Promise<string | number | void> => {
    return updateProfileAvatar(file)
      .then(data => {
        enqueueSnackbar(t('navbar:snackbar.pic-change-successful'))
        return data
      })
      .catch(() => enqueueSnackbar(t('common:snackbar.ocurrio-error')))
  }

  return { uploadAvatar }
}

export const useModulesMenu = (
  setModules: (institutionModules: Module[]) => void
) => {
  const { t } = useTranslation(['common', 'navbar'])
  const { enqueueSnackbar } = useSnackbar()

  const { getAxiosInstance } = useImpersonation()
  const axiosInstance = getAxiosInstance()

  const fetchModules = useCallback(
    async institutionId => {
      try {
        if (institutionId) {
          const institutionModules = await getInstitutionModules(
            institutionId,
            axiosInstance
          )

          setModules(institutionModules?.modules ?? [])
        }
      } catch (e) {
        enqueueSnackbar(t('common:snackbar.ocurrio-error'))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosInstance]
  )

  return { fetchModules }
}

export const useUserContextSelector = () => {
  const user = useUserInfo()
  const { project } = useFetchedProject()

  const {
    asList: jurisdictions,
    asDict: jurisdictionsById,
    loading: jurisdictionsLoading,
    error: jurisdictionsError,
  } = useJurisdictions()

  const {
    asList: institutionList,
    loading: institutionsLoading,
    error: institutionsError,
  } = useInstitutions()

  const {
    selectJurisdiction,
    selectedJurisdiction,
    selectInstitution,
    selectedInstitution,
    setJurisdictionLoading,
    setInitialized,
    initialized,
  } = useInstitutionContext()

  const { setInstitutionId } = useImpersonation()

  const loading = jurisdictionsLoading || institutionsLoading
  const error = jurisdictionsError || institutionsError
  const isLoaded = !loading && !error

  setJurisdictionLoading(loading)

  const [institutions, setInstitutions] = useState<Institution[]>([])

  useEffect(() => {
    if (
      institutionList.every(inst => !!jurisdictionsById[inst.jurisdictionId])
    ) {
      const institutionsWithJurisdiction = map(
        inst =>
          set('jurisdiction', jurisdictionsById[inst.jurisdictionId], inst),
        institutionList
      )
      setInstitutions(institutionsWithJurisdiction)
    }
  }, [institutionList, jurisdictionsById])

  const resetContext = () => {
    setInitialized(false)
    selectJurisdiction(null)
    selectInstitution(null)
    setInstitutions([])
  }

  const initializeJurisdictionContext = () => {
    if (jurisdictions.length && user.hasRole(Role.CONTENT_CREATOR)) {
      const jurisdictionId =
        project?.jurisdictionIds && project.jurisdictionIds.length > 0
          ? project.jurisdictionIds[0]
          : localStorage.getItem('selectedJurisdiction')

      const currentJurisdiction = jurisdictions.find(
        jurisdiction => jurisdiction.id === jurisdictionId
      )

      selectJurisdiction(currentJurisdiction || jurisdictions[0])
      setInitialized(true)
    }
  }

  const initializeContext = () => {
    if (institutions.length && !user.hasRole(Role.CONTENT_CREATOR)) {
      let projectInstitution
      let storedInstitution

      if (project && project.schoolIds.length > 0) {
        projectInstitution = institutions.find(
          institution => institution.id === project.schoolIds[0]
        )
      } else {
        const institutionId = localStorage.getItem('selectedInstitution')
        storedInstitution = institutions.find(
          institution => institution.id === institutionId
        )
      }

      const currentInstitution =
        projectInstitution || storedInstitution || institutions[0]

      selectInstitution(currentInstitution)
      setInstitutionId(currentInstitution?.id)
      if (currentInstitution) {
        selectJurisdiction(currentInstitution.jurisdiction)
        setInitialized(true)
      }
    }
  }

  useEffect(() => {
    if (!initialized && !selectedJurisdiction) {
      initializeJurisdictionContext()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdictions, initialized])

  useEffect(() => {
    if (!initialized && !selectedJurisdiction && !selectedInstitution) {
      initializeContext()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutions, initialized])

  return {
    isLoaded,
    selectJurisdiction: (jurisdiction: Jurisdiction) => {
      selectJurisdiction(jurisdiction)
      localStorage.setItem('selectedJurisdiction', jurisdiction.id)
    },
    selectInstitution: (institution: Institution) => {
      selectInstitution(institution)
      setInstitutionId(institution.id)
      if (institution.jurisdiction) {
        selectJurisdiction(institution.jurisdiction)
      }
    },
    jurisdictions,
    institutions,
    selectedJurisdiction,
    selectedInstitution,
    resetContext,
  }
}

export const useRolTranslation = () => {
  const { t } = useTranslation(['common'])
  return {
    translateRol: rol => {
      switch (rol) {
        case 'teacher':
          return t('common:rol.teacher')
        case 'student':
          return t('common:rol.student')
        case 'content-creator':
          return t('common:rol.content-creator')
        case 'tutor':
          return t('common:rol.tutor')
        default:
          return ''
      }
    },
  }
}
