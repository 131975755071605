import { BaseContentItem, ContentItemType } from './ContentItem'

export class VideoContentItem extends BaseContentItem {
  type = ContentItemType.VIDEO as const
  url: string

  getTypeName(): string {
    return 'Youtube/Vimeo'
  }
}
