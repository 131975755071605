import { defaultsDeep, get, noop } from 'lodash/fp'
import React, { createContext, useContext, useEffect, useState } from 'react'

export enum Tenant {
  CABA = 'CABA',
}

interface TenantConfig {
  idpHint?: string
}

type TenantConfigContext = [TenantConfig, (tenantName: Tenant) => void]

const defaultConfig: TenantConfig = {}
const extendedConfigs: Record<Tenant, TenantConfig> = {
  [Tenant.CABA]: {
    idpHint: 'google',
  },
}

export const context = createContext<TenantConfigContext>([{}, noop])

const { Provider } = context

export const TenantConfigProvider = props => {
  const [currentConfig, setCurrentConfig] =
    useState<TenantConfig>(defaultConfig)

  const setConfig = (tenantName: Tenant) => {
    if (!((tenantName as string) in Tenant)) {
      // eslint-disable-next-line no-console
      console.warn(`Invalid tenant: ${tenantName}`)
    }
    setCurrentConfig(
      defaultsDeep(defaultConfig, extendedConfigs[tenantName] || {})
    )
  }

  useEffect(() => {
    try {
      const params = new URLSearchParams(get('location.search', window))
      if (params.has('t')) {
        const encodedTenantName = params.get('t')!
        const tenantName = atob(encodedTenantName)
        setConfig(tenantName as Tenant)
      }
      // eslint-disable-next-line no-empty
    } catch (_) {}
  }, [])

  return <Provider value={[currentConfig, setConfig]} {...props} />
}

export function useTenantConfig() {
  return useContext(context)
}
