import { BaseModel } from './BaseModel'

export class PerformanceLevel extends BaseModel {
  _id: string
  label: string
  value: number
  projectId: string

  get id() {
    return this._id
  }
}
