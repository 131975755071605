import { BaseModel } from './BaseModel'

export class Tenant extends BaseModel {
  static getPath() {
    return 'v2/is/graphql'
  }

  static getQuery(institutionId: string) {
    return `{
      institution(id: "${institutionId}") {
        tenant {
          id
          name
        }
      }
    }`
  }

  id: string
  name: string
}
