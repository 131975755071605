import { useContext } from 'react'
import { projectStoreContext } from '../../contexts/projectStore'
import { useFetch } from '@/lib/http'
import { Language } from '@models/Language'

export function useFetchedLanguages() {
  const { keys } = useContext(projectStoreContext)
  const { data, error } = useFetch<Language[]>(() => keys.languages())

  const languages: Language[] = (data as Array<Language>)?.map(language =>
    Language.fromData(language)
  )
  return {
    loading: !data && !error,
    error,
    languages: languages || [],
  }
}
