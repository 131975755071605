import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'

import { Box, TextField, Checkbox, Chip } from '@material-ui/core'
import { compact } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import * as yup from 'yup'

import { LocaleObject } from 'yup/lib/locale'
import { AutocompleteSelectField } from '../../AutocompleteSelectField'

import { DuplicateBaseDialog } from './DuplicateBaseDialog'

import { useDuplicateForm, TemplateDuplicateValues } from './hooks'
import { customErrorMessages } from '@/lib/customErrorMessages'
import { useInstitutionContext } from '@/lib/institution-context'
import { Jurisdiction } from '@models/Jurisdiction'

interface Props {
  defaultTitle: string
  options: Jurisdiction[]
  onDuplicate: (values: TemplateDuplicateValues) => Promise<void>
  open: boolean
  onClose: (event?) => void
}

yup.setLocale(customErrorMessages as LocaleObject)

export const DuplicateTemplateDialog: React.FC<Props> = ({
  defaultTitle,
  options,
  onDuplicate,
  ...dialogProps
}) => {
  const { selectedJurisdiction } = useInstitutionContext()

  const { t } = useTranslation(['common', 'home'])

  const schema = yup.object().shape({
    title: yup.string().required(),
    jurisdictionIds: yup.array().of(yup.string()).min(1).required(),
  })

  const hookform = useForm({
    defaultValues: {
      title: defaultTitle,
      jurisdictionIds: compact([selectedJurisdiction?.id]),
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const { errors, control } = hookform

  const { submitHandler } = useDuplicateForm(hookform, onDuplicate)

  return (
    <DuplicateBaseDialog
      hookform={hookform}
      onSubmit={submitHandler}
      {...dialogProps}
    >
      <Box pb={3}>
        <AutocompleteSelectField<Jurisdiction>
          multiple
          limitTags={3}
          disableCloseOnSelect
          name="jurisdictionIds"
          control={control}
          options={options}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          renderInput={params => (
            <TextField
              {...params}
              label={t('home:proyecto.duplicate-selector.jurisdictions')}
            />
          )}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.name}
            </>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={option.id}
                size="small"
                style={{ maxWidth: 120 }}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          error={!!errors.jurisdictionIds}
          helperText={
            errors.jurisdictionIds && (
              <ErrorMessage
                errors={errors}
                name="jurisdictionIds"
                render={() => (
                  <span>{t('common:formulario.campo-requerido')}</span>
                )}
              />
            )
          }
          size="small"
          clearOnBlur
          fullWidth
          noOptionsText={t(
            'home:proyecto.duplicate-selector.empty-jurisdictions'
          )}
        />
      </Box>
    </DuplicateBaseDialog>
  )
}
