import { BaseModel } from './BaseModel'
import { Institution } from './Institution'

interface ModuleConfig {
  teacherUrl?: string
  studentUrl?: string
}

export interface Module {
  id: string
  name: string
  config: ModuleConfig
}

export interface ModuleItemType extends Omit<Module, 'config'> {
  url?: string
}

export class InstitutionModules extends BaseModel {
  static getPath() {
    return 'v2/is/graphql'
  }

  static getQuery(institutionId: Institution['id']) {
    return `{
      institution(id: "${institutionId}") {
        id
        modules {
          name
          id
          config
        }
      }
    }`
  }

  id: Institution['id']
  modules: Module[]
}
