import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useImpersonation } from '@/contexts/impersonation'
import { useUserInfo } from '@/lib/auth'
import { useInstitutionContext } from '@/lib/institution-context'
import { TabIdViews } from '@components/Home'
import { getInstitutionModules } from '@hooks/fetch/institutionsServiceData'
import { Role } from '@models/Role'

interface FetchTabsToShow {
  tabs: string[]
  loading: boolean
}

interface UseTabsReturn {
  tabsToShow: TabIdViews[]
  isLoading: boolean
  userPermissions: boolean
}

type UseTabs = () => UseTabsReturn

export const useTabs: UseTabs = () => {
  const [data, setData] = useState<FetchTabsToShow>({
    tabs: [TabIdViews.projects],
    loading: true,
  })

  const originalUser = useUserInfo(true)
  const user = useUserInfo()
  const { isImpersonating } = useImpersonation()

  const { selectedInstitution } = useInstitutionContext()
  const { getAxiosInstance } = useImpersonation()

  const axiosInstance = getAxiosInstance()

  const isTeacher = user.hasRole(Role.TEACHER)
  const isContentCreator = user.hasRole(Role.CONTENT_CREATOR)
  const isTutor =
    originalUser.hasRole(Role.TUTOR) || originalUser.hasRole(Role.TUTOR_MASTER)

  const isStudent = originalUser.hasRole(Role.STUDENT)
  const isTutorAndNotImpersonating = isTutor && !isImpersonating
  const isContentCreatorAndNotTeacher = isContentCreator && !isTeacher

  const router = useRouter()

  const fetchModules = useCallback(
    async institutionId => {
      if (institutionId) {
        const institutionModules = await getInstitutionModules(
          institutionId,
          axiosInstance
        )

        const isSubscribeToCurricular = institutionModules?.modules.some(
          m => m.id === 'curricula'
        )

        const isSubscribeToDisney = institutionModules?.modules.some(
          m => m.id === 'disney'
        )

        if (isTeacher || isImpersonating) {
          const tabDisney = isSubscribeToDisney ? TabIdViews.libraryDisney : ''

          return setData({
            tabs: [TabIdViews.projects, TabIdViews.libraryABP, tabDisney],
            loading: false,
          })
        }

        if (isStudent && isSubscribeToCurricular) {
          return setData({
            tabs: [...data.tabs, TabIdViews.curricular],
            loading: false,
          })
        }

        return setData({
          ...data,
          loading: false,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axiosInstance]
  )

  useEffect(() => {
    fetchModules(selectedInstitution?.id)
    if (isContentCreator) {
      return setData({
        tabs: [...data.tabs],
        loading: false,
      })
    }

    if (isTutorAndNotImpersonating) {
      router.push('/docentes')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstitution])

  return {
    isLoading: data.loading,
    tabsToShow: data.tabs as TabIdViews[],
    userPermissions:
      isContentCreatorAndNotTeacher || isTutorAndNotImpersonating,
  }
}
