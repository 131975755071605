import { Box, Grid } from '@material-ui/core'
import { Hidden } from '@mui/material'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectList } from './ProjectList'
import { createProjectEvent } from '@/lib/analytics'
import { useUserInfo } from '@/lib/auth'
import EmptyState from '@common/EmptyState'
import { SearchOffIcon } from '@common/Icons/SearchOffIcon'
import { Loader } from '@common/Loader'
import { LoaderWithText } from '@common/LoaderWithText'
import { MobileFabButton } from '@common/MobileFabButton'
import { Organization, useFetchedLibrary } from '@hooks/fetch/project'
import { Project } from '@models/Project'

interface LibraryProjectsProps {
  organization: Organization
}

export const LibraryProjects: FC<LibraryProjectsProps> = ({ organization }) => {
  const { t } = useTranslation(['home', 'common'])
  const user = useUserInfo()

  const {
    exactMatchProjects,
    suggestedProjects,
    loading,
    error,
    removeProject,
    hideProject,
    copyProject,
  } = useFetchedLibrary(organization)

  const [copying, setCopying] = useState(false)

  const handleCopyProject = (project: Project, copiedProjects: Project[]) => {
    setCopying(false)
    return copyProject(project, copiedProjects)
  }

  if (error) {
    return null
  }

  return (
    <>
      <Grid container style={{ minHeight: '400px' }}>
        {copying && (
          <LoaderWithText
            title={t('home:snackbar.copy.loading')}
            loading={copying}
          />
        )}
        {loading && (
          <Box width="100%">
            <Loader />
          </Box>
        )}
        {!loading &&
        !exactMatchProjects?.length &&
        !suggestedProjects?.length ? (
          <EmptyState
            title={t('common:empty.no-resultados')}
            subtitle={t('common:empty.no-resultados-filtros-hint')}
            icon={SearchOffIcon}
            iconSize="lg"
          />
        ) : (
          <>
            {exactMatchProjects && exactMatchProjects?.length > 0 && (
              <ProjectList
                title={t('home:library.search-exact-match-title')}
                projects={exactMatchProjects}
                onDelete={removeProject}
                onHide={hideProject}
                onCopy={handleCopyProject}
                onBeforeCopy={() => {
                  setCopying(true)
                }}
              />
            )}
            {suggestedProjects && suggestedProjects?.length > 0 && (
              <ProjectList
                title={t('home:library.search-suggested-title')}
                projects={suggestedProjects}
                onDelete={removeProject}
                onHide={hideProject}
                onCopy={handleCopyProject}
                onBeforeCopy={() => {
                  setCopying(true)
                }}
              />
            )}
          </>
        )}
      </Grid>
      {!loading && Project.userCanCreateProjects(user) && (
        <Hidden lgUp>
          <MobileFabButton
            onClick={() => createProjectEvent(true)}
            label={t('home:crear-proyecto')}
            href={Project.getCreationRoute()}
          />
        </Hidden>
      )}
    </>
  )
}
