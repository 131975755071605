import { Box } from '@mui/material'
import Image from 'next/image'
import { FC } from 'react'

interface ImageProps {
  width: number
  height: number
  containerHeigth: number
}

export const DefaultImage: FC<ImageProps> = ({
  containerHeigth,
  width,
  height,
}) => {
  return (
    <Box
      sx={{ backgroundColor: '#e8e8e8' }}
      height={containerHeigth}
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <Image src={'/defaultProject.svg'} width={width} height={height} />
    </Box>
  )
}
