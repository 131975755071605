import { useContext } from 'react'
import { projectStoreContext } from '../../contexts/projectStore'
import { BaseResponse } from './baseResponse'
import { useUserInfo } from '@/lib/auth'
import { useFetch } from '@/lib/http'

export interface Metrics {
  [k: string]: {
    all: {
      [k: string]: {
        metrics: { completed: boolean; assets: { progress: number } }
        relatedMetrics: RelatedMetrics[]
        _id: string
      }
    }
    progress?: number
  }
}

export interface RelatedMetrics {
  completed: boolean
  embed: boolean
  object_id: string
}

interface CurricularMetricsReturn extends BaseResponse {
  curricularMetrics: Metrics | null
}

type CurricularMetrics = () => CurricularMetricsReturn

export const useFetchedCurricularMetrics: CurricularMetrics = () => {
  const { keys } = useContext(projectStoreContext)

  const user = useUserInfo()

  const { data, error } = useFetch<Metrics>(
    () => keys.curricularMetrics(user.preferred_username),
    { refreshInterval: 0 }
  )
  const curricularMetrics = data || null

  return {
    loading: !data && !error,
    error,
    curricularMetrics,
  }
}
