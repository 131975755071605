import { BaseModel } from './BaseModel'
import { Institution } from './Institution'
import { ConfigurationObject } from '@/lib/configuration'

export class Configuration extends BaseModel {
  static getPath(institutionId: string) {
    return `${Institution.getPath()}/${institutionId}/configuration`
  }

  static fromObject(data: ConfigurationObject, institutionId: string | null) {
    return Object.entries(data).map(([key, value]) =>
      this.fromData({ key, value, institutionId })
    )
  }

  key: string
  value: string

  institutionId: Institution['id'] | null
}
