import { makeStyles, Typography, MenuItem } from '@material-ui/core'

import clsx from 'clsx'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { useLanguageSwitcher } from './hooks'

const useStyles = makeStyles(theme => ({
  listButton: {
    display: 'block',
    textAlign: 'start',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      2
    )}px`,
    borderLeft: '4px solid transparent',

    borderRadius: 0,
    fontWeight: 'normal',
    '& p': {
      lineHeight: '1',
    },
  },
  selectedListButton: {
    borderLeftColor: `${theme.palette.primary.main}`,
  },
}))

export const LanguageList: React.FC = () => {
  const { i18n } = useTranslation()
  const { languages, currentLanguage, changeLanguage } = useLanguageSwitcher()
  const classes = useStyles()

  return (
    <>
      {Object.values(languages).map(({ code, name, country }) => (
        <MenuItem
          key={code}
          disabled={code === currentLanguage.code}
          onClick={() => changeLanguage(code)}
          className={clsx(
            classes.listButton,
            code === i18n.language && classes.selectedListButton
          )}
        >
          <Typography>{name}</Typography>
          {country && (
            <Typography variant="caption" color="textSecondary">
              {country}
            </Typography>
          )}
        </MenuItem>
      ))}
    </>
  )
}
