import {
  Box,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import {
  DeleteOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  SettingsOutlined,
  FileCopyOutlined,
} from '@mui/icons-material'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import PersonIcon from '@mui/icons-material/Person'
import SchoolIcon from '@mui/icons-material/School'

import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenu } from '../ContextMenu'
import { useContextMenu } from '../ContextMenu/hooks'
import { CustomDialog } from '../CustomDialog'
import { useDialog } from '../CustomDialog/hooks'

import { ConfirmClassroomDialog } from './ConfirmClassroomDialog'
import { DuplicateProjectDialog } from './DuplicateProject/DuplicateProjectDialog'
import { DuplicateTemplateDialog } from './DuplicateProject/DuplicateTemplateDialog'
import { ProjectDates } from './ProjectDates'
import { ProjectLanguage } from './ProjectLanguage'
import { ProjectSubjects } from './ProjectSubjects'
import { useCopiedProject, useDeleteProject, useHiddenProject } from './hooks'
import { AnalyticsAction, logEvent } from '@/lib/analytics'
import { useUserInfo } from '@/lib/auth'
import {
  useJurisdictions,
  // useInstitutions,
  useLevels,
  useTeacher,
} from '@hooks/fetch/institutionsServiceData'
import { Project } from '@models/Project'

interface Props {
  project: Project
  onDelete(project: Project): Promise<void>
  onHide(project: Project): Promise<void>
  onCopy(project: Project, copiedProjects: Project[]): Promise<void>
  onBeforeCopy: () => void
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  root: {
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow:
        '1px 0px 20px -1px rgba(0,0,0,0.2), 0px 0px 20px 5px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      transform: 'translateY(-3px)',
    },
    cursor: 'pointer',
  },
  media: {
    backgroundColor: '#e8e8e8',
    height: '144px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  iconButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    borderRadius: '50%',
    zIndex: 1000,
    backgroundColor: '#fff',
    borderColor: theme.palette.grey[400],
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  content: {
    paddingTop: theme.spacing(1.5),
    '&:last-child': {
      paddingBottom: theme.spacing(1.5),
    },
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-around',
  },
  icons: {
    paddingRight: theme.spacing(1),
  },
  delete: {
    color: theme.palette.grey[600],
    fontSize: '0.875rem',
  },
  contentItems: {
    paddingTop: theme.spacing(1),
    minHeight: theme.spacing(4),
  },
  dotsIcon: {
    color: theme.palette.grey[600],
  },
  hidden: {
    opacity: '0.4',
  },
  red: {
    color: theme.palette.error.dark,
  },
  truncate: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  disneySubtitle: {
    color: '#006',
    fontFamily: 'Poppins',
    fontSize: '10px',
  },
}))

const CONTEXT_MENU_ID = 'project-card-actions-menu'

const ProjectOwner = ({ ownerId }: { ownerId: string }) => {
  const { teacher: owner } = useTeacher(ownerId)

  return (
    <>
      {owner && (
        <Typography variant="body2">
          {`${owner.givenName} ${owner.familyName}`}
        </Typography>
      )}
    </>
  )
}

export const ProjectCard: React.FC<Props> = ({
  project,
  onDelete,
  onHide,
  onCopy,
  onBeforeCopy,
}) => {
  const { t } = useTranslation('home')

  const classes = useStyles()
  const router = useRouter()

  const currentUser = useUserInfo()
  const editable = project.userCanEdit(currentUser)
  const canDelete = project.belongsTo(currentUser)

  const { asList: jurisdictions } = useJurisdictions()
  // const { asList: institutions } = useInstitutions()

  const { asList: levelsList } = useLevels()
  const levels = levelsList
    .filter(level => project.level === level.id)
    .map(level => level.name)

  const {
    dialogOpen: deleteProjectDialogOpen,
    openDialog: openDeleteProjectDialog,
    closeDialog: closeDeleteProjectDialog,
  } = useDialog()

  const {
    dialogOpen: duplicateDialogOpen,
    openDialog: openDuplicateProjectDialog,
    closeDialog: closeDuplicateDialog,
  } = useDialog()

  const {
    dialogOpen: isClassroomDialogOpen,
    openDialog: openClassroomDialog,
    closeDialog: closeClassroomDialog,
  } = useDialog()

  const { copyProject } = useCopiedProject(project, onCopy)
  const { confirmDelete } = useDeleteProject(onDelete)
  const { toggleProjectVisibility } = useHiddenProject(
    { project, currentUser },
    onHide
  )

  const {
    open: contextMenuOpen,
    openContextMenu,
    closeContextMenu,
    menuAnchor,
  } = useContextMenu()

  const handleCopy = async (
    title: string,
    destination?: { jurisdictionIds?: string[]; schoolIds?: string[] }
  ) => {
    onBeforeCopy()
    closeDuplicateDialog()
    return copyProject(title, destination)
  }

  const duplicateProps = {
    defaultTitle: project.copyTitle,
    open: duplicateDialogOpen,
    onClose: closeDuplicateDialog,
  }

  function handleConfirmClassroom(): void {
    toggleProjectVisibility(hasClassroom)
    closeClassroomDialog()
  }

  const hasClassroom = useMemo(
    () => project.invitedCohorts.some(cohort => !!cohort.classMappingId),
    [project]
  )

  const isDisney =
    project.organization?.includes('DISNEY') && !project.isTemplate()

  return (
    <Box className={classes.container}>
      <Card
        className={clsx(classes.root, classes.container, {
          [classes.hidden]: project.hidden,
        })}
        onClick={() => router.push(project.getDetailRoute())}
      >
        {editable && (
          <IconButton
            size="small"
            className={classes.iconButton}
            aria-label={t('proyecto.opciones')}
            aria-haspopup
            aria-controls={CONTEXT_MENU_ID}
            onClick={openContextMenu}
          >
            <MoreVertIcon className={classes.dotsIcon} />
          </IconButton>
        )}
        {project.coverUrl ? (
          <CardMedia
            className={classes.media}
            image={project.coverUrl}
            title={project.title}
          />
        ) : (
          <Box className={classes.media}>
            <Image src={'/defaultProject.svg'} width={169} height={72} />
          </Box>
        )}
        <CardContent className={classes.content}>
          <Box style={{ height: '16px' }}>
            {isDisney && (
              <Typography className={classes.disneySubtitle}>
                TICMAS ABP with Disney
              </Typography>
            )}
          </Box>
          <Box style={{ height: '68px', marginBottom: '8px' }}>
            <Typography
              title={project.title}
              variant="h2"
              className={classes.truncate}
              style={{
                lineHeight: 'normal',
                color: '#2d2d2d',
                wordBreak: 'break-word',
              }}
            >
              {project.title}
            </Typography>
          </Box>

          <Grid container justify="space-between">
            <Grid item>
              <ProjectSubjects data={project} limit={2} />
            </Grid>
            <Grid item>
              <ProjectLanguage language={project.language} />
            </Grid>
          </Grid>

          {!project.isTemplate() && (
            <Box className={classes.contentItems}>
              <ProjectDates project={project} editable={editable} />
            </Box>
          )}

          <Grid container className={classes.contentItems}>
            <Grid item className={classes.icons}>
              <SchoolIcon color="disabled" fontSize="small" />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {t('proyecto.level')}: {levels}
              </Typography>
            </Grid>
          </Grid>

          {!project.isTemplate() && (
            <Grid container className={classes.contentItems}>
              <Grid item className={classes.icons}>
                <PersonIcon color="disabled" fontSize="small" />
              </Grid>
              <Grid item>
                {project.ownerId && <ProjectOwner ownerId={project.ownerId} />}
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      {editable && (
        <ContextMenu
          menuId={CONTEXT_MENU_ID}
          anchorEl={menuAnchor}
          open={contextMenuOpen}
          onClose={closeContextMenu}
          options={[
            {
              label: t('proyecto.editar'),
              icon: <SettingsOutlined />,
              action: () => {
                router.push(project.getEditRoute())
                closeContextMenu()
              },
            },
            ...(canDelete
              ? [
                  {
                    iconClassName: classes.red,
                    labelClassName: classes.red,
                    secondary: true,
                    label: t('proyecto.eliminar'),
                    icon: <DeleteOutlined />,
                    action: () => {
                      openDeleteProjectDialog()
                      closeContextMenu()
                    },
                  },
                ]
              : []),
            {
              label: project.hidden
                ? t('proyecto.mostrar')
                : t('proyecto.ocultar'),
              icon: project.hidden ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              ),
              action: () => {
                if (project.hidden) {
                  logEvent(AnalyticsAction.UNHIDE_PROJECT, {
                    id_proyecto: project.id,
                    nombre_proyecto: project.title,
                    tipo_proyecto: project.type,
                  })
                }
                if (hasClassroom && project.hidden) {
                  openClassroomDialog()
                } else {
                  toggleProjectVisibility()
                }
                closeContextMenu()
              },
            },
            {
              label: t('proyecto.duplicate'),
              icon: <FileCopyOutlined />,
              action: () => {
                openDuplicateProjectDialog()
                closeContextMenu()
              },
            },
          ]}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )}
      <ConfirmClassroomDialog
        open={isClassroomDialogOpen}
        onConfirm={handleConfirmClassroom}
        onCancel={closeClassroomDialog}
      />
      {canDelete && (
        <CustomDialog
          title={t('proyecto.eliminar-titulo')}
          content={
            (
              <Typography className={classes.delete}>
                {t('proyecto.eliminar-confirmar', { titulo: project.title })}
              </Typography>
            ) as unknown as string
          }
          closeHandler={closeDeleteProjectDialog}
          actionHandler={() => {
            closeDeleteProjectDialog()
            confirmDelete(project)
          }}
          open={deleteProjectDialogOpen}
          actionLabel={t('proyecto.eliminar')}
        />
      )}
      {project.isTemplate() ? (
        <DuplicateTemplateDialog
          {...duplicateProps}
          options={jurisdictions}
          onDuplicate={async ({ title, jurisdictionIds }) =>
            handleCopy(title, { jurisdictionIds })
          }
        />
      ) : (
        <DuplicateProjectDialog
          {...duplicateProps}
          // Pass filtered institutions as options
          // options={institutions}
          onDuplicate={async ({ title /* , schoolIds */ }) =>
            handleCopy(title /* , { schoolIds } */)
          }
        />
      )}
    </Box>
  )
}
