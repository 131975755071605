import { ErrorMessage } from '@hookform/error-message'
import { Box, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useEffect, PropsWithChildren } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props extends PropsWithChildren {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hookform: UseFormMethods<any>
  onSubmit: (event: React.BaseSyntheticEvent) => void
  open: boolean
  onClose: (event) => void
}

export const DuplicateBaseDialog: React.FC<Props> = ({
  children,
  hookform,
  onSubmit,
  open,
  onClose,
}) => {
  const { t } = useTranslation(['common', 'home'])
  const { reset, errors, register, formState } = hookform

  useEffect(() => {
    reset()
  }, [open, reset])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box px={3} pt={6.5} pb={3}>
        <form onSubmit={onSubmit}>
          <DialogTitle id="alert-dialog-title">
            {t('home:proyecto.duplicate-dialog-title')}
          </DialogTitle>
          <DialogContent>
            <Box py={3}>
              <TextField
                required
                autoComplete="off"
                name="title"
                label={t('home:proyecto.duplicate-form-title')}
                inputRef={register}
                fullWidth
                error={!!errors.title}
                helperText={
                  errors.title && (
                    <ErrorMessage
                      errors={errors}
                      name="title"
                      render={() => (
                        <span>{t('common:formulario.campo-requerido')}</span>
                      )}
                    />
                  )
                }
              />
            </Box>

            {children}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained">
              {t('common:acciones.cancelar')}
            </Button>
            <Button
              disabled={!formState.isValid}
              type="submit"
              color="primary"
              autoFocus
              variant="contained"
            >
              {t('home:proyecto.duplicate-dialog-confirm-btn')}
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  )
}
