import { IconButton, makeStyles, Menu, MenuList, Grid } from '@material-ui/core'
import { AppsRounded } from '@mui/icons-material'
import * as React from 'react'
import { useContextMenu } from '../ContextMenu/hooks'
import { ModuleItem } from './ModuleItem'
import { useIsMobile } from '@hooks/responsive'
import { ModuleItemType } from '@models/InstitutionModules'

const useStyles = makeStyles({
  menu: {
    display: 'flex',
    justifyItems: 'center',
    top: '52px !important',
    // width: `${MENU_WIDTH}px`,
    /* [theme.breakpoints.down('sm')]: {
      width: `${MENU_WIDTH_MOBILE}px`,
    }, */
  },
})

const CONTEXT_MENU_ID = 'modules-menu'

interface Props {
  modules?: ModuleItemType[]
}

export const ModulesMenu: React.FC<Props> = ({ modules }) => {
  const classes = useStyles()

  const isMobile = useIsMobile()

  const { openContextMenu, closeContextMenu, menuAnchor, open } =
    useContextMenu()

  if (!modules || !modules.length) return null

  return (
    <>
      <IconButton onClick={openContextMenu}>
        <AppsRounded />
      </IconButton>
      <Menu
        id={CONTEXT_MENU_ID}
        anchorEl={menuAnchor}
        open={open}
        onClose={closeContextMenu}
        className={classes.menu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          style: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        PaperProps={{
          style: {
            overflow: isMobile ? 'scroll' : 'hidden',
            width: 'fit-content',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        {isMobile ? (
          <MenuList>
            {modules.map(module => (
              <ModuleItem key={module.id} {...module} />
            ))}
          </MenuList>
        ) : (
          <Grid container>
            {modules.map(module => (
              <ModuleItem key={module.id} {...module} />
            ))}
          </Grid>
        )}
      </Menu>
    </>
  )
}
