import { AppBar, Box, Grid, IconButton, Link, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MenuRounded } from '@mui/icons-material'
import { useKeycloak } from '@react-keycloak/ssr'
import clsx from 'clsx'
import { t } from 'i18next'
import Image from 'next/image'
import NextLink from 'next/link'
import React, { useEffect, useState } from 'react'
import ChangePasswordModal from '../ChangePassword'
import { BurgerMenu } from './BurgerMenu'
import { ContactDialog } from './ContactDialog'
import { ModulesMenu } from './ModulesMenu'
import { NewTeacherPlatformButton } from './NewTeacherPlatformButton'
import { UserMenu } from './UserMenu'
import { useBurguerMenu, useModulesMenu } from './hooks'
import { useUserInfo } from '@/lib/auth'
import { useConfiguration } from '@/lib/configuration'
import { getSkin } from '@/lib/custom-skin'
import { GraphqlProvider } from '@/lib/graphql'
import { useDisney } from '@hooks/disney'
import { useIsMobile } from '@hooks/responsive'
import { Institution } from '@models/Institution'
import { Module } from '@models/InstitutionModules'
import { Role } from '@models/Role'

const NAVBAR_HEIGHT = '72px'
const NAVBAR_HEIGHT_MOBILE = '48px'
const NAVBAR_HEIGHT_MOBILE_WITH_BUTTON = '120px'

const useStyles = makeStyles(theme => ({
  toolbar: {
    borderBottom: '1px solid #edeff1',
    color: theme.palette.action.active,
  },
  toolbarDesktop: {
    height: NAVBAR_HEIGHT,
  },
  toolbarMobile: {
    height: NAVBAR_HEIGHT_MOBILE,
    alignItems: 'center',
  },
  toolbarMobileWithButton: {
    height: NAVBAR_HEIGHT_MOBILE_WITH_BUTTON,
    alignItems: 'start',
    paddingTop: '6px',
  },
  sign: {
    color: theme.palette.action.active,
    fontSize: 12,
  },
  contactButton: {
    '& .MuiIconButton-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))

interface NavbarProps {
  showNewTeacherPlatformButton: boolean
}

export const Navbar: React.FC<NavbarProps> = ({
  showNewTeacherPlatformButton,
}) => {
  const skin = getSkin()
  const styles = useStyles()
  const isMobile = useIsMobile()

  const [keycloak] = useKeycloak()
  const user = useUserInfo()
  const { isSubscribeToDisney } = useDisney()

  const [modules, setModules] = useState<Module[]>([])

  const { fetchModules } = useModulesMenu(setModules)

  const { burgerMenuOpen, toggleBurgerMenu } = useBurguerMenu()
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [openChangePassword, setOpenChangePasswordDialog] = useState(false)
  const [selectedInstitution, setSelectedInstitution] = useState<Institution>()
  const closeContactDialog = () => setOpenContactDialog(false)

  const {
    teacherTutorials,
    studentTutorials,
    clasesTeacherUrl,
    clasesTeacherUrlET,
    clasesStudentUrl,
  } = useConfiguration()
  const tutorialsUrl = user?.hasRole(Role.STUDENT)
    ? studentTutorials
    : teacherTutorials

  useEffect(() => {
    if (selectedInstitution) fetchModules(selectedInstitution.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstitution])

  if (!keycloak.authenticated) {
    return null
  }

  const getModuleUrl = (config: Module['config']) => {
    return user?.hasRole(Role.TEACHER) ? config?.teacherUrl : config?.studentUrl
  }

  const openChangePasswordModal = () => {
    setOpenChangePasswordDialog(true)
  }

  const closeChangePasswordModal = () => {
    setOpenChangePasswordDialog(false)
  }

  const getAvailableModules = () => {
    const isFromET = sessionStorage.getItem('platformVersion') === 'et'
    return [
      ...modules,
      {
        id: 'clases',
        name: 'clases',
        config: {
          teacherUrl: (isFromET ? clasesTeacherUrlET : clasesTeacherUrl) || '',
          studentUrl: clasesStudentUrl || '',
        },
      },
    ]
      .filter(({ id }) => {
        return ['alfabetizacion', 'bidi_secundaria', 'clases'].includes(id)
      })
      .map(({ config, ...module }) => ({
        ...module,
        url: getModuleUrl(config) ?? '',
      }))
      .filter(module => !!module.url || module.id === 'bidi_secundaria')
  }

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar
        className={clsx(
          styles.toolbar,
          !isMobile && styles.toolbarDesktop,
          isMobile && !showNewTeacherPlatformButton && styles.toolbarMobile,
          isMobile &&
            showNewTeacherPlatformButton &&
            styles.toolbarMobileWithButton
        )}
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={5}>
            <Box display="flex" alignItems="center" style={{ height: '100%' }}>
              <IconButton
                onClick={toggleBurgerMenu(true)}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <MenuRounded />
              </IconButton>
              <BurgerMenu
                toggleBurgerMenu={toggleBurgerMenu}
                burgerMenuOpen={burgerMenuOpen}
              />
              <NextLink href="/" passHref>
                <Link underline="none">
                  <Box mt={0.5}>
                    <Image
                      src={
                        isSubscribeToDisney
                          ? '/logo-ticmas-disney.svg'
                          : skin.logo
                      }
                      width={isMobile ? 128 : 156}
                      height={isMobile ? 38 : 46}
                    />
                  </Box>
                </Link>
              </NextLink>
            </Box>
          </Grid>

          <Grid item xs={7} style={{ display: 'flex' }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
              style={{ flexWrap: 'nowrap' }}
            >
              {showNewTeacherPlatformButton && !isMobile && (
                <Grid item>
                  <NewTeacherPlatformButton />
                </Grid>
              )}
              {!!getAvailableModules()?.length && (
                <Grid item>
                  <ModulesMenu modules={getAvailableModules()} />
                </Grid>
              )}
              <Grid item>
                <UserMenu
                  onSelectContact={() => setOpenContactDialog(true)}
                  {...(tutorialsUrl && {
                    onSelectTutorial: () => window.open(tutorialsUrl, '_blank'),
                  })}
                  onSelectTerms={() =>
                    window.open('https://tyc.ticmas.io', '_blank')
                  }
                  onSelectInstitution={setSelectedInstitution}
                  onSelectChangePassword={openChangePasswordModal}
                />
              </Grid>
            </Grid>
          </Grid>
          {showNewTeacherPlatformButton && isMobile && (
            <Grid item>
              <Box mt={1}>
                <NewTeacherPlatformButton />
              </Box>
            </Grid>
          )}
        </Grid>
      </Toolbar>
      <ContactDialog
        open={openContactDialog}
        closeHandler={closeContactDialog}
      />
      <GraphqlProvider>
        <ChangePasswordModal
          description={t('navbar:user-menu.change-password-modal-description')}
          descriptionTitle={t('navbar:user-menu.change-password-modal-title')}
          isOpen={openChangePassword}
          onCancel={closeChangePasswordModal}
        ></ChangePasswordModal>
      </GraphqlProvider>
    </AppBar>
  )
}
