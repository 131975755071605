import { Box, Chip, makeStyles, Typography } from '@material-ui/core'
import { Hidden } from '@mui/material'
import React from 'react'
import { SimpleDialog } from '../CustomDialog/SimpleDialog'
import { ProjectCardTooltip } from './ProjectCardTooltip'
import { useSubjects } from '@hooks/fetch/institutionsServiceData'
import { Project } from '@models/Project'

interface PropsChip {
  data: Project
  limit: number
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0.75),
    margin: 0,
  },
  chip: {
    marginRight: theme.spacing(1),
    borderRadius: 0,
    fontSize: '0.75rem',
  },
}))

export const ProjectSubjects: React.FC<PropsChip> = ({
  data: project,
  limit,
}) => {
  const classes = useStyles()
  const { asList: subjects } = useSubjects()

  const chipData = subjects.filter(subject =>
    project.subjects.includes(subject.id)
  )
  const visibleChips = chipData.slice(0, limit)
  const hiddenChips = chipData.slice(limit)

  if (visibleChips.length > 1) {
    const longName = visibleChips.filter(v => v.name.length > 18)
    if (longName.length) {
      visibleChips.splice(visibleChips.indexOf(longName[0]), 1)
      hiddenChips.unshift(longName[0])
    }
  }

  const namesHiddenChips = hiddenChips.map(data => data.name)

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = event => {
    event.stopPropagation()
    setOpen(true)
  }
  const handleClose = event => {
    event.stopPropagation()
    setOpen(false)
  }

  return (
    <Box component="ul" className={classes.root}>
      {visibleChips.map(data => (
        <li key={data.id}>
          <Chip className={classes.chip} size="small" label={data.name} />
        </li>
      ))}
      {hiddenChips.length > 0 && (
        <Box>
          <Hidden lgUp>
            <li>
              <Chip
                className={classes.chip}
                size="small"
                label={`+${hiddenChips.length}`}
                onClick={handleClickOpen}
              />
              <SimpleDialog
                open={open}
                closeHandler={handleClose}
                items={namesHiddenChips}
              />
            </li>
          </Hidden>
          <Hidden mdDown>
            <li>
              <ProjectCardTooltip
                title={namesHiddenChips.map(name => (
                  <Typography
                    key={name}
                    color="inherit"
                    style={{ fontSize: '0.75rem' }}
                  >
                    {name}
                  </Typography>
                ))}
                placement="right-start"
              >
                <Chip
                  className={classes.chip}
                  size="small"
                  label={`+${hiddenChips.length}`}
                />
              </ProjectCardTooltip>
            </li>
          </Hidden>
        </Box>
      )}
    </Box>
  )
}
