import { Theme, Tooltip, withStyles } from '@material-ui/core'

export const ProjectCardTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: theme.palette.common.white,
    border: '1px solid #dadde9',
    maxWidth: 222,
  },
}))(Tooltip)
