import { Box, Typography } from '@mui/material'
import { ticmasColor } from '@/skins/default'

export const DisneyLogotype = () => {
  return (
    <Box>
      <Typography
        color={ticmasColor.disneyBlue}
        fontFamily="Poppins"
        fontSize="10px"
        fontWeight={400}
      >
        TICMAS ABP with Disney
      </Typography>
    </Box>
  )
}
