import { PopoverOrigin } from '@material-ui/core'
import { Hidden } from '@mui/material'
import { isFunction } from 'lodash/fp'
import { useRouter } from 'next/router'
import React, { Suspense, useCallback } from 'react'
import { ContextMenuProps, MenuOption } from './types'

const DrawerContextMenu = React.lazy(() => import('./DrawerContextMenu'))
const PopoverContextMenu = React.lazy(() => import('./PopoverContextMenu'))

interface Props extends Omit<ContextMenuProps, 'onSelect'> {
  anchorEl: HTMLElement | null
  anchorOrigin?: PopoverOrigin
}

export const ContextMenu: React.FC<Props> = props => {
  const router = useRouter()
  const onSelect = useCallback(
    ({ action }: MenuOption) => {
      if (isFunction(action)) {
        action()
      } else {
        router.push(action)
      }
    },
    [router]
  )
  return (
    <Suspense fallback={null}>
      <Hidden smUp>
        <DrawerContextMenu onSelect={onSelect} {...props} />
      </Hidden>
      <Hidden only="xs">
        <PopoverContextMenu onSelect={onSelect} {...props} />
      </Hidden>
    </Suspense>
  )
}
