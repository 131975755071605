import { Box, Typography, Grid } from '@material-ui/core'
import { ProjectCard } from '@common/ProjectCard'
import { Project } from '@models/Project'

interface Props {
  title: string
  projects: Project[]
  onDelete(project: Project): Promise<void>
  onHide(project: Project): Promise<void>
  onCopy(project: Project, copiedProjects: Project[]): Promise<void>
  onBeforeCopy: () => void
}

export const ProjectList: React.FC<Props> = ({
  title,
  projects,
  onDelete,
  onHide,
  onCopy,
  onBeforeCopy,
}) => {
  return (
    <Grid container spacing={2} style={{ marginBottom: '20px' }}>
      <Grid item xs={12}>
        <Box my={1.5}>
          <Typography variant="h1" component="h1" style={{ fontWeight: 700 }}>
            {title}
          </Typography>
        </Box>
      </Grid>
      {projects.map((project: Project) => (
        <Grid item key={project._id} xs={12} sm={6} md={4} lg={3}>
          <ProjectCard
            project={project}
            onDelete={onDelete}
            onHide={onHide}
            onCopy={onCopy}
            onBeforeCopy={onBeforeCopy}
          />
        </Grid>
      ))}
    </Grid>
  )
}
