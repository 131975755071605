import { BaseModel } from './BaseModel'

export class Jurisdiction extends BaseModel {
  static getPath() {
    return '/v1/is/jurisdictions'
  }

  id: string
  name: string
}
