import { Box, Chip, makeStyles } from '@material-ui/core'
import React from 'react'

interface PropsChip {
  labels: string[]
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0.75),
    margin: 0,
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 0,
    fontSize: '0.75rem',
  },
}))

export const Chips: React.FC<PropsChip> = ({ labels }) => {
  const classes = useStyles()

  return (
    <Box component="ul" className={classes.root}>
      {labels.map((label, index) => (
        <Chip key={index} className={classes.chip} size="small" label={label} />
      ))}
    </Box>
  )
}
