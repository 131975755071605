import {
  Badge,
  Box,
  Button,
  makeStyles,
  Menu,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  Divider,
} from '@material-ui/core'
import {
  CameraAlt,
  HighlightOff,
  Language,
  ChevronRight,
  ChevronLeft,
} from '@mui/icons-material'
import Lock from '@mui/icons-material/Lock'
import ModelTraining from '@mui/icons-material/ModelTraining'
import { useKeycloak } from '@react-keycloak/ssr'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useImpersonation } from '../../../contexts/impersonation'
import { Avatar } from '../Avatar'
import { useContextMenu } from '../ContextMenu/hooks'
import { Loader } from '../Loader'
import { NewFeatureTooltip } from '../NewFeatureArrow'
import { useHideTooltip } from '../NewFeatureArrow/hooks'
import { LanguageList } from './LanguageList'
import UserContextSelector from './UserContextSelector'
import {
  useUploadAvatar,
  useLanguageSwitcher,
  useUserContextSelector,
  useRolTranslation,
} from './hooks'
import { useUserInfo } from '@/lib/auth'
import { useConfiguration } from '@/lib/configuration'
import { Institution } from '@models/Institution'
import { Role } from '@models/Role'

const MENU_WIDTH = 383

const useStyles = makeStyles(theme => ({
  iconButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    padding: '6px 16px',
    [theme.breakpoints.down('xs')]: {
      border: 'none',
    },
  },
  borderNone: {
    border: 'none',
  },
  userContext: {
    textAlign: 'left',
    minWidth: '80px',
    maxWidth: '230px',
    paddingRight: '20px',
    paddingTop: '4px',
    '& p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiTypography-body2': {
      lineHeight: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  avatar: {
    width: 32,
    height: 32,
  },
  linksContainer: {
    backgroundColor: theme.palette.grey[100],
    width: '100%',
  },
  linkButton: {
    padding: `${theme.spacing(1.1)}px ${theme.spacing(1.5)}px`,
    flexGrow: 1,
    borderRadius: 0,
  },
  link: {
    color: theme.palette.grey[700],
    fontSize: `${theme.spacing(1.5)}px`,
    textAlign: 'center',
  },
  linkPaddingLeft: {
    paddingLeft: `${theme.spacing(3)}px`,
    justifyContent: 'left',
    '& $link': {
      textAlign: 'left',
    },
  },
  linkPaddingRight: {
    paddingRight: `${theme.spacing(3)}px`,
    justifyContent: 'right',
    '& $link': {
      textAlign: 'right',
    },
  },
  logoutIcon: {
    color: theme.palette.grey[400],
    marginRight: `${theme.spacing(0.85)}px`,
  },
  removeOutline: {
    outline: 'none',
  },
  userContainer: {
    padding: `0 ${theme.spacing(2.25)}px`,
  },
  userInfoContainer: {
    marginLeft: `${theme.spacing(3)}px`,
  },
  bottomBox: {
    borderTop: `1.25px solid ${theme.palette.grey[200]}`,
    // marginTop: `${theme.spacing(3)}px`,
    // Uncomment when teacher's school list is enabled
  },
  badgeIcon: {
    color: 'white',
    borderRadius: '100%',
    border: `4px solid ${theme.palette.secondary.main}`,
    backgroundColor: `${theme.palette.secondary.main}`,
  },
  menu: {
    display: 'flex',
    justifyItems: 'center',
    top: '52px !important',
    width: `${MENU_WIDTH}px`,
  },
  menuAvatar: {
    height: 80,
    width: 80,
  },
  username: {
    fontSize: '18px',
  },
  email: {
    color: theme.palette.grey[600],
  },
  verticalDivider: {
    height: '60%',
  },
  logoutButton: {
    width: '100%',
    padding: `${theme.spacing(1.5)}px 0`,
  },
  buttonHover: {
    cursor: 'pointer',
    '& :hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  darkerHover: {
    '& :hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  menuList: {
    marginTop: `${theme.spacing(3)}px`,
    '& .MuiListItem-root': {
      cursor: 'pointer',
      borderBottom: '1px solid',
      borderTopColor: theme.palette.grey[200],
      borderBottomColor: theme.palette.grey[200],
      paddingTop: `${theme.spacing(1.5)}px`,
      paddingBottom: `${theme.spacing(1.5)}px`,
    },
    '& .MuiListItemIcon-root': {
      minWidth: `${theme.spacing(4)}px`,
      color: theme.palette.grey[600],
    },
    '& .MuiList-root': {
      padding: 0,
      borderTop: '1px solid',
      borderTopColor: theme.palette.grey[200],
    },
  },
}))

const CONTEXT_MENU_ID = 'user-menu'

interface Props {
  onSelectContact?: () => void
  onSelectTutorial?: () => void
  onSelectTerms?: () => void
  onSelectInstitution?: (institution: Institution) => void
  onSelectChangePassword: () => void
}

export const UserMenu: React.FC<Props> = ({
  onSelectContact,
  onSelectTutorial,
  onSelectTerms,
  onSelectInstitution,
  onSelectChangePassword,
}) => {
  const { t } = useTranslation(['common', 'navbar', 'institution-context'])
  const { getLanguagePrettyName } = useLanguageSwitcher()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [listLanguages, setListLanguages] = useState(false)
  const [keycloak] = useKeycloak()
  const { uploadAvatar } = useUploadAvatar()
  const btnRef = useRef(null)
  const { isTooltipHidden, hideTooltip } = useHideTooltip('hideTooltip')
  const router = useRouter()
  const { openContextMenu, closeContextMenu, menuAnchor, open } =
    useContextMenu()

  const { selfAssistedTrainingTeacher, selfAssistedTrainingStudent } =
    useConfiguration()

  const { isImpersonating, stopImpersonating } = useImpersonation()
  const {
    isLoaded: isDataLoaded,
    jurisdictions,
    selectJurisdiction,
    selectedJurisdiction,
    institutions,
    selectInstitution,
    selectedInstitution,
    resetContext,
  } = useUserContextSelector()
  const { translateRol } = useRolTranslation()

  const user = useUserInfo(false)
  // TODO: Definir qué datos mostrar del usuario tutor original
  // const originalUser = useUserInfo(true)
  const [userPictureRender, setUserPictureRender] = useState(user.picture)

  useEffect(() => {
    user.picture = userPictureRender
  }, [user, userPictureRender])

  useEffect(() => {
    if (onSelectInstitution && selectedInstitution) {
      onSelectInstitution(selectedInstitution)
    }
  }, [onSelectInstitution, selectedInstitution])

  const studentSelfAssisted =
    user.hasRole(Role.STUDENT) && selfAssistedTrainingStudent

  const teacherSelfAssisted =
    user.hasRole(Role.TEACHER) && selfAssistedTrainingTeacher

  const logOut = () => {
    const { origin } = window.location

    keycloak.logout({
      redirectUri: `${origin}`,
    })
  }

  const handleAvatarClick = (event: React.BaseSyntheticEvent) => {
    event.target.value = null
  }

  const handleAvatarChange = async (event: React.BaseSyntheticEvent) => {
    const { files } = event.target
    if (files.length) {
      const pictureUrl = await uploadAvatar(files[0])
      setUserPictureRender(pictureUrl as string)
    }
  }

  const handleChangeJurisdiction = jurisdiction => {
    selectJurisdiction(jurisdiction)
    enqueueSnackbar(
      t('institution-context:snackbar.change-jurisdiction-success', {
        jurisdiction: jurisdiction.name,
      })
    )
    router.push('/')
  }

  const handleChangeInstitution = institution => {
    selectInstitution(institution)
    enqueueSnackbar(
      t('institution-context:snackbar.change-institution-success', {
        institution: institution.name,
      })
    )
    router.push('/')
  }

  const openLanguageList = () => {
    return setListLanguages(true)
  }

  const openChangePasswordModal = () => {
    onSelectChangePassword()
  }

  const closeLanguageList = () => {
    return setListLanguages(false)
  }

  const handleBtnClick = event => {
    hideTooltip()
    openContextMenu(event)
  }

  const getJurisdictionRolPair = institution => {
    const jurisdictionRolPair: string[] = []
    if (institution?.jurisdiction?.name) {
      jurisdictionRolPair.push(institution.jurisdiction.name)
      if (institution.roles?.length > 0) {
        jurisdictionRolPair.push(translateRol(institution.roles[0]))
      }
    }
    return jurisdictionRolPair.join(' · ')
  }

  return (
    <>
      <Button
        color="inherit"
        aria-label={t('common:options')}
        onClick={handleBtnClick}
        className={clsx(classes.iconButton, {
          [classes.borderNone]: !selectedJurisdiction,
        })}
        ref={btnRef}
      >
        {selectedJurisdiction && (
          <Box display="block" className={classes.userContext}>
            <Typography variant="body2">
              {user.hasRole(Role.CONTENT_CREATOR)
                ? selectedJurisdiction?.name
                : selectedInstitution?.name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {user.hasRole(Role.TEACHER) &&
                getJurisdictionRolPair(selectedInstitution)}
            </Typography>
          </Box>
        )}
        <Avatar
          user={user}
          picture={userPictureRender}
          className={classes.avatar}
        />
      </Button>
      {user.hasRole(Role.TEACHER) && (
        <NewFeatureTooltip
          text={t('navbar:user-menu.new-feature-tooltip')}
          hide={isTooltipHidden}
          anchorEl={btnRef.current}
          onClick={() => hideTooltip()}
        />
      )}

      <Menu
        id={CONTEXT_MENU_ID}
        anchorEl={menuAnchor}
        open={open}
        onClose={closeContextMenu}
        className={classes.menu}
        MenuListProps={{
          style: {
            paddingTop: '1.625em',
            paddingBottom: 0,
          },
        }}
        PaperProps={{
          style: {
            width: MENU_WIDTH,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
            // transform: 'translate(-24px, 0px)',
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              className={clsx(classes.removeOutline, classes.userContainer)}
            >
              <label htmlFor="submit-avatar">
                <Badge
                  style={{ cursor: 'pointer' }}
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={<CameraAlt className={classes.badgeIcon} />}
                >
                  <Avatar
                    user={user}
                    picture={userPictureRender}
                    className={classes.menuAvatar}
                  />
                </Badge>
              </label>
              <input
                id="submit-avatar"
                type="file"
                onClick={handleAvatarClick}
                onChange={handleAvatarChange}
                hidden
              />

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                className={classes.userInfoContainer}
              >
                <Typography variant="subtitle2" className={classes.username}>
                  {user.fullName()}
                </Typography>

                {user.email && (
                  <Typography variant="subtitle2" className={classes.email}>
                    {user.email}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box className={classes.menuList}>
              <List className={classes.buttonHover}>
                {listLanguages ? (
                  <ListItem onClick={closeLanguageList}>
                    <ListItemIcon>
                      <ChevronLeft />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('navbar:user-menu.choose-language')}
                    />
                  </ListItem>
                ) : (
                  <ListItem onClick={openLanguageList}>
                    <ListItemIcon>
                      <Language />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        getLanguagePrettyName()
                          ? getLanguagePrettyName()
                          : t('navbar:user-menu.choose-language')
                      }
                    />
                    <ListItemIcon>
                      <ChevronRight />
                    </ListItemIcon>
                  </ListItem>
                )}
                {(studentSelfAssisted || teacherSelfAssisted) && (
                  <Link href={'/capacitacion-autoasistida'}>
                    <ListItem>
                      <ListItemIcon>
                        <ModelTraining />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('navbar:user-menu.self-assisted-training')}
                      />
                    </ListItem>
                  </Link>
                )}
                <ListItem onClick={openChangePasswordModal}>
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('navbar:user-menu.change-password')}
                  />
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                </ListItem>
              </List>
            </Box>
          </Box>

          {listLanguages ? (
            <LanguageList />
          ) : isDataLoaded ? (
            <>
              {user.hasRole(Role.CONTENT_CREATOR) && (
                <UserContextSelector
                  items={jurisdictions}
                  selected={selectedJurisdiction}
                  onChange={handleChangeJurisdiction}
                />
              )}
              {user.hasRole(Role.TEACHER) && (
                <UserContextSelector
                  items={institutions.map(institution => ({
                    ...institution,
                    caption: getJurisdictionRolPair(institution),
                  }))}
                  selected={selectedInstitution}
                  onChange={handleChangeInstitution}
                />
              )}
            </>
          ) : (
            <Box display="flex" justifyContent="center" pb={5}>
              <Loader size="xs" />
            </Box>
          )}

          <Box
            display="flex"
            className={clsx(classes.removeOutline, classes.bottomBox)}
          >
            {(onSelectContact || onSelectTutorial || onSelectTerms) && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={clsx(classes.removeOutline, classes.linksContainer)}
              >
                {onSelectContact && (
                  <>
                    <Button
                      onClick={onSelectContact}
                      className={clsx(
                        classes.linkButton,
                        classes.linkPaddingLeft
                      )}
                    >
                      <Typography variant="subtitle2" className={classes.link}>
                        {t('navbar:user-menu.contact')}
                      </Typography>
                    </Button>

                    <Divider
                      orientation="vertical"
                      className={classes.verticalDivider}
                    />
                  </>
                )}

                {onSelectTutorial && (
                  <>
                    <Button
                      className={classes.linkButton}
                      onClick={onSelectTutorial}
                    >
                      <Typography variant="subtitle2" className={classes.link}>
                        {t('navbar:user-menu.tutorial')}
                      </Typography>
                    </Button>

                    <Divider
                      orientation="vertical"
                      className={classes.verticalDivider}
                    />
                  </>
                )}

                {onSelectTerms && (
                  <>
                    <Button
                      className={clsx(
                        classes.linkButton,
                        classes.linkPaddingRight
                      )}
                      onClick={onSelectTerms}
                    >
                      <Typography variant="subtitle2" className={classes.link}>
                        {t('navbar:user-menu.terms')}
                      </Typography>
                    </Button>
                  </>
                )}
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={clsx(classes.removeOutline, classes.bottomBox)}
          >
            <Button onClick={logOut} className={classes.logoutButton}>
              <HighlightOff className={classes.logoutIcon} />
              <Typography>{t('navbar:user-menu.logout')}</Typography>
            </Button>
            {isImpersonating && (
              <Button
                onClick={() => {
                  stopImpersonating()
                  resetContext()
                  closeContextMenu()
                }}
                className={classes.logoutButton}
              >
                <Typography>
                  {t('navbar:user-menu.stop-roleplaying')}
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Menu>
    </>
  )
}
