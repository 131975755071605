import { yupResolver } from '@hookform/resolvers/yup'
import { debounce } from 'lodash/fp'
import { useCallback, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { LocaleObject } from 'yup/lib/locale'
import {
  ProjectFilters,
  projectSearchContext,
} from '../../contexts/projectSearch'
import { customErrorMessages } from '@/lib/customErrorMessages'

yup.setLocale(customErrorMessages as LocaleObject)

const schema = yup.object().shape({
  languages: yup.array().nullable(),
  levels: yup.array().nullable(),
  subjects: yup.array().optional(),
  title: yup.string().nullable(),
})

export const useProjectFilterForm = () => {
  const {
    filters,
    clearFilters,
    shouldClearFilters,
    setShouldClearFilters,
    setFilters,
  } = useContext(projectSearchContext)

  const hookform = useForm<ProjectFilters>({
    defaultValues: shouldClearFilters ? {} : filters,
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const formValue = hookform.watch(['languages', 'levels', 'subjects', 'title'])
  const formValueAsString = JSON.stringify(formValue)
  const searching = Object.values(formValue).some(x => !!x && x.length > 0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchValueDebounced = useCallback(
    debounce(500, () => setFilters(formValue)),
    [formValueAsString]
  )

  useEffect(() => {
    updateSearchValueDebounced()
    return updateSearchValueDebounced.cancel
  }, [updateSearchValueDebounced, formValueAsString])

  useEffect(() => {
    if (shouldClearFilters) {
      clearFilters()
    }
    setShouldClearFilters(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters, setShouldClearFilters])

  const resetFilters = () => {
    hookform.setValue('languages', [])
    hookform.setValue('levels', [])
    hookform.setValue('subjects', [])
    hookform.setValue('title', '')
  }

  const clearTitleSearch = () => {
    hookform.setValue('title', '')
  }

  return {
    hookform,
    searching,
    resetFilters,
    clearTitleSearch,
  }
}
