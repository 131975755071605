import { ClassConstructor } from 'class-transformer'
import { ActionActivity } from './ActionActivity'
import { Activity, ActivityType } from './Activity'
import { ContentActivity } from './ContentActivity'
import { ExamActivity } from './ExamActivity'
// import { JournalActivity } from './JournalActivity'
import { QuizActivity } from './QuizActivity'

export const activityTypes = new Map<
  ActivityType,
  ClassConstructor<Activity> & { fromData(data): Activity }
>([
  [ActivityType.ACTION, ActionActivity],
  [ActivityType.CONTENT, ContentActivity],
  [ActivityType.EXAM, ExamActivity],
  // [ActivityType.JOURNAL, JournalActivity],
  [ActivityType.QUIZ, QuizActivity],
])
