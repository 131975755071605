import { FormControl, FormHelperText, makeStyles } from '@material-ui/core'
import { set } from 'lodash/fp'
import React from 'react'
import {
  AutocompleteSelect,
  AutocompleteSelectProps,
} from './AutocompleteSelect'

interface Props<T = unknown> extends AutocompleteSelectProps<T> {
  name: string
  required?: boolean
  error?: boolean
  helperText?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any
  classroomTagForIds?: string[]
}

const useStyles = makeStyles(() => ({
  inputPadding: {
    '& .MuiInputBase-input': {
      paddingTop: '9px !important',
      paddingBottom: '8px !important',
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 18px) scale(1)',
    },
  },
}))

export const AutocompleteSelectField = <T,>({
  name,
  className,
  control,
  error = false,
  fullWidth = false,
  renderInput,
  helperText,
  defaultValue,
  ...props
}: Props<T>) => {
  const classes = useStyles()
  return (
    <FormControl className={className} error={error} fullWidth={fullWidth}>
      <AutocompleteSelect
        className={classes.inputPadding}
        name={name}
        control={control}
        renderInput={props => renderInput(set('error', error, props))}
        defaultValue={defaultValue}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
