import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUserInfo } from '@/lib/auth'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#F9FAFB',
    height: 'calc(100vh - 73px)',
    position: 'relative',
    width: '100%',
    top: '9px',
  },
  positionRelative: {
    position: 'relative',
    top: '-60px',
    width: '59%',
  },
  mainImage: {
    background: 'url(welcome-disney-main.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: '50vh',
  },
  ticmasLogo: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    width: '200px',
    height: '200px',
  },
  ticmasLogoTop: {
    backgroundImage: 'url(welcome-disney-ticmas-top.svg)',
    top: 0,
    left: 0,
  },
  ticmasLogoBottom: {
    backgroundImage: 'url(welcome-disney-ticmas-bottom.svg)',
    position: 'fixed',
    bottom: '-70px',
    right: 0,
  },
  h1: {
    fontSize: '30px',
    fontWeight: 600,
  },
  h2: {
    fontSize: '22px',
    fontWeight: 500,
  },
  text: {
    textAlign: 'center',
    color: '#002F6C',
    width: '950px',
    marginBottom: theme.spacing(4),
  },
  btn: {
    textTransform: 'uppercase',
    minWidth: '204px',
  },
}))

interface Props {
  onClose: () => void
}

export const DisneyWelcomeDialog: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles()
  const user = useUserInfo()
  const { t } = useTranslation('welcome')

  return (
    <Grid
      className={classes.container}
      container
      spacing={2}
      direction="column"
      wrap="nowrap"
      justifyContent="center"
    >
      {user && (
        <>
          <Box
            className={clsx(classes.ticmasLogo, classes.ticmasLogoTop)}
          ></Box>
          <Box
            className={clsx(classes.ticmasLogo, classes.ticmasLogoBottom)}
          ></Box>
          <Box display="flex" flexDirection="column" style={{ height: '90%' }}>
            <Box alignSelf="center">
              <Typography
                variant="h1"
                className={clsx(classes.h1, classes.text)}
              >
                {t('disney-welcome.title', { username: user.given_name })}
              </Typography>
              <Typography
                component="pre"
                className={clsx(classes.h2, classes.text)}
              >
                {t('disney-welcome.message')}
              </Typography>
            </Box>
            <Box
              alignSelf="center"
              className={classes.positionRelative}
              display="flex"
              flexDirection="column"
            >
              <Box className={classes.mainImage}></Box>
              <Box alignSelf="center" mt={3}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.btn}
                  onClick={() => onClose()}
                >
                  {t('disney-welcome.btn')}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Grid>
  )
}
