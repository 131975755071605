import {
  Box,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PersonIcon from '@mui/icons-material/Person'
import SchoolIcon from '@mui/icons-material/School'
import { UserInfo } from '@ticmas/common-interfaces'
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import { Chips } from './Chips'
import { CardDate } from './Dates'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  root: {
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow:
        '1px 0px 20px -1px rgba(0,0,0,0.2), 0px 0px 20px 5px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      transform: 'translateY(-3px)',
    },
    cursor: 'pointer',
  },
  media: {
    backgroundColor: '#e8e8e8',
    height: '144px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  iconButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    borderRadius: '50%',
    zIndex: 1000,
    backgroundColor: '#fff',
    borderColor: theme.palette.grey[400],
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  content: {
    paddingTop: theme.spacing(1.5),
    '&:last-child': {
      paddingBottom: theme.spacing(1.5),
    },
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-around',
  },
  icons: {
    paddingRight: theme.spacing(1),
  },
  contentItems: {
    paddingTop: theme.spacing(1),
    minHeight: theme.spacing(4),
  },
  dotsIcon: {
    color: theme.palette.grey[600],
  },
  truncate: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
}))

interface Props {
  title?: string
  coverUrl?: string
  startDate?: Date
  endDate?: Date
  grade?: string
  chips?: string[]
  owner?: UserInfo
  dateAssignmentRoute?: string
  onClick?: () => void
  showContextMenu?: boolean
  contextMenuBtnLabel?: string
  onContextMenu?: () => void
}

const CONTEXT_MENU_ID = 'card-actions-menu'

export const HomeCard: React.FC<Props> = ({
  title = '',
  coverUrl,
  startDate,
  endDate,
  grade,
  chips,
  owner,
  dateAssignmentRoute,
  onClick,
  showContextMenu,
  contextMenuBtnLabel,
  onContextMenu,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Card className={clsx(classes.root, classes.container)} onClick={onClick}>
        {showContextMenu && (
          <IconButton
            size="small"
            className={classes.iconButton}
            aria-label={contextMenuBtnLabel}
            aria-haspopup
            aria-controls={CONTEXT_MENU_ID}
            onClick={onContextMenu}
          >
            <MoreVertIcon className={classes.dotsIcon} />
          </IconButton>
        )}
        {coverUrl ? (
          <CardMedia className={classes.media} image={coverUrl} title={title} />
        ) : (
          <Box className={classes.media}>
            <Image src={'/defaultProject.svg'} width={169} height={72} />
          </Box>
        )}
        <CardContent className={classes.content}>
          <Box style={{ height: '68px', marginBottom: '8px' }}>
            <Typography
              title={title}
              variant="h2"
              className={classes.truncate}
              style={{
                lineHeight: 'normal',
                color: '#2d2d2d',
                wordBreak: 'break-word',
              }}
            >
              {title}
            </Typography>
          </Box>

          {chips && (
            <Grid container justify="space-between">
              <Grid item>
                {/* TODO: implementar soporte de multiples etiquetas */}
                <Chips labels={chips} />
              </Grid>
              {/* <Grid item>
                <Language language={language} />
              </Grid> */}
            </Grid>
          )}

          {startDate && endDate && (
            <Box className={classes.contentItems}>
              <CardDate
                startDate={startDate}
                endDate={endDate}
                assignmentRoute={dateAssignmentRoute}
              />
            </Box>
          )}

          {grade && (
            <Grid container className={classes.contentItems}>
              <Grid item className={classes.icons}>
                <SchoolIcon color="disabled" fontSize="small" />
              </Grid>
              <Grid item>
                <Typography variant="body2">{grade}</Typography>
              </Grid>
            </Grid>
          )}

          {owner && (
            <Grid container className={classes.contentItems}>
              <Grid item className={classes.icons}>
                <PersonIcon color="disabled" fontSize="small" />
              </Grid>
              <Grid item>
                {owner && (owner.given_name || owner.family_name) && (
                  <Typography variant="body2">
                    {`${owner.given_name || ''} ${owner.family_name || ''}`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
