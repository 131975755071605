import _ from 'lodash'
import { DeepPartial } from 'react-hook-form'
import { ClientSideEnvironment } from './env'

export function overrideEnvByDomain(
  env: ClientSideEnvironment
): ClientSideEnvironment {
  return _.merge(env, customDomains[location.hostname] || {})
}

/* custom domains, don't include default URL since it shouldn't override any env value */
type CustomDomainMap = { [domain: string]: DeepPartial<ClientSideEnvironment> }

const customDomains: CustomDomainMap = {
  'abp.bue.edu.ar': {
    keycloak: {
      url: 'https://usuarios.bue.edu.ar/auth',
      clientId: 'front-pbl-caba',
    },
    helpEmail: 'soporte.abp@bue.edu.ar',
  },
  'abp.ted.lomasdezamora.gov.ar': {
    keycloak: {
      url: 'https://usuarios.ted.lomasdezamora.gov.ar/auth',
      clientId: 'lomas-de-zamora',
    },
  },
  'abp.miescuela.edusalta.gov.ar': {
    keycloak: {
      url: 'https://usuarios.miescuela.edusalta.gov.ar/auth',
      clientId: 'salta-pbl',
    },
    clasesTeacherUrl: 'https://docente.miescuela.edusalta.gov.ar',
    clasesStudentUrl: 'https://estudiante.miescuela.edusalta.gov.ar',
  },
}
