import { UserInfo } from '@ticmas/common-interfaces'
import { Type } from 'class-transformer'
import { has } from 'lodash/fp'
import { Activity } from './Activity'
import { BaseModel } from './BaseModel'
import { Cohort } from './Cohort'
import { Jurisdiction } from './Jurisdiction'
import { Language } from './Language'
import { Level } from './Level'
import { Phase } from './Phase'
import { Role } from './Role'
import { Subject } from './Subject'
import { Teacher } from './Teacher'
import { User } from './User'
import i18n from '@/lib/i18n'

export class InvitedCohort {
  teacherId: Teacher['id']
  id: Cohort['id']
  teachersInCharge: string[] = []
  classMappingId?: string
}

export class Project extends BaseModel {
  static getCreationRoute() {
    return '/nuevo-proyecto'
  }

  static getShowLibrary() {
    return '/biblioteca-abp'
  }

  static userCanCreateProjects(user: User) {
    return user.hasRole(Role.TEACHER) || user.hasRole(Role.CONTENT_CREATOR)
  }

  _id: string
  type: 'project' | 'template'
  title: string
  description: string
  ownerId: string
  level: Level['id']
  subjects: Subject['id'][]
  invitedCohorts: InvitedCohort[]
  teacherIds: Teacher['id'][]
  schoolIds: string[]
  coverUrl?: string
  hidden: boolean
  isCopy?: boolean
  jurisdictionIds?: Jurisdiction['id'][]
  language: Language['id']
  mutable: boolean
  organization: string
  backgroundImage?: string
  tutorbot?: boolean

  @Type(() => Date)
  startDate: Date

  @Type(() => Date)
  endDate: Date

  @Type(() => Phase)
  phases: Phase[]

  get id() {
    return this._id
  }

  get copyTitle() {
    return `${i18n.t('common:copied-project-title')} ${this.title}`
  }

  get tabTitle(): [string, string] {
    return [
      this.title,
      this.isTemplate()
        ? i18n.t('home:tabs.biblioteca')
        : i18n.t('home:tabs.proyectos-en-curso'),
    ]
  }

  get cohortIds(): string[] {
    return this.invitedCohorts.map(({ id }) => id)
  }

  getDetailRoute() {
    return `/proyectos/${this._id}`
  }

  getEditRoute() {
    return `/proyectos/${this._id}/configuracion`
  }

  getCompetencyRadarRoute() {
    return `/proyectos/${this._id}/dianas-competenciales`
  }

  getRubricVisualizationRoute() {
    return `/proyectos/${this._id}/ver/rubrica`
  }

  getAssignmentRoute() {
    return `/proyectos/${this._id}/gestion`
  }

  getRubricRoute() {
    return `/proyectos/${this._id}/rubrica`
  }

  getStudentsRoute() {
    return `/proyectos/${this._id}/estudiantes`
  }

  getActivityDetailRoute(phase: Phase, activity: Activity) {
    return `/proyectos/${this._id}/fases/${phase.id}/actividades/${activity._id}`
  }

  userCanEdit(user: User) {
    return (
      (user.hasRole(Role.CONTENT_CREATOR) && this.isTemplate()) ||
      (user.hasRole(Role.TEACHER) &&
        (this.belongsTo(user) || this.isSharedWith(user)))
    )
  }

  userCanClone(user: User) {
    return user.hasRole(Role.TEACHER) && this.isTemplate()
  }

  viewProjectAsStudent(user: User) {
    return (
      user.hasRole(Role.STUDENT) &&
      !this.belongsTo(user) &&
      !this.isSharedWith(user)
    )
  }

  checkMutability(user: User) {
    return this.exists() && (this.mutable || user.hasRole(Role.CONTENT_CREATOR))
  }

  belongsTo(user: UserInfo | Teacher) {
    if (isUser(user)) {
      return this.ownerId === user.sub
    }
    if (isTeacher(user)) {
      return this.ownerId === user.userId
    }
    return false
  }

  isSharedWith(user: UserInfo | Teacher) {
    if (isUser(user)) {
      return this.teacherIds.includes(user.sub)
    }
    if (isTeacher(user)) {
      return this.teacherIds.includes(user.userId)
    }
    return false
  }

  isTemplate() {
    return this.type === 'template'
  }

  addPhase(savedPhase: Phase) {
    this.phases.push(savedPhase)
  }

  updatePhase(savedPhase: Phase) {
    this.phases = this.phases.map(phase =>
      phase.id === savedPhase.id ? savedPhase : phase
    )
  }

  removePhase(phase: Phase) {
    this.phases = this.phases.filter(p => p._id !== phase._id)
  }

  reorderPhases(prevIndex: number, newIndex: number) {
    this.phases.splice(newIndex, 0, this.phases.splice(prevIndex, 1)[0])
  }

  reorderActivities(
    prevIndex: number,
    newIndex: number,
    sourcePhaseId: string,
    destinationPhaseId: string
  ) {
    const destinationPhase = this.phases.find(
      phase => phase._id === destinationPhaseId
    )!
    const sourcePhase = this.phases.find(phase => phase._id === sourcePhaseId)!
    const changedActivity = sourcePhase.activities.splice(prevIndex, 1)[0]
    destinationPhase.activities.splice(newIndex, 0, changedActivity)
  }

  getPhaseById(phaseIdToFind: Phase['id']): Phase | null {
    for (const phase of this.phases) {
      if (phase.id === phaseIdToFind) {
        return phase
      }
    }

    return null
  }

  getActivityById(activityIdToFind: Activity['id']): Activity | null {
    for (const phase of this.phases) {
      for (const activity of phase.activities) {
        if (activity.id === activityIdToFind) {
          return activity
        }
      }
    }

    return null
  }

  hasCohorts() {
    return this.cohortIds.length > 0
  }
}

function isUser(data): data is UserInfo {
  return has('sub', data)
}

function isTeacher(data): data is Teacher {
  return has('userId', data)
}
