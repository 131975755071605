import { BaseModel } from './BaseModel'

export enum SubmissionType {
  ACTION = 'actionSubmission',
}

interface Responsible {
  id: string
  type: string
}
export abstract class Submission extends BaseModel {
  _id: string
  responsible: Responsible
  date: Date
  abstract type: SubmissionType

  get id() {
    return this._id
  }
}
