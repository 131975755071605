import { Box, Button, Grid, Link, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Hidden } from '@mui/material'
import Image from 'next/image'
import NextLink from 'next/link'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Carousel from 'react-material-ui-carousel'
import { WelcomeContext } from '../../contexts/welcome'
import { useUserInfo } from '@/lib/auth'
import { getSkin } from '@/lib/custom-skin'
import { useIsMobile } from '@hooks/responsive'
import { Project } from '@models/Project'
import { Role } from '@models/Role'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} timeout={1000} />
})

interface Props {
  Content
  handleNotShowAgain(): void
  onNext(): void
  media
}

const useStyles = makeStyles(theme => ({
  carousel: {
    height: '100%',
    '& .CarouselItem': {
      height: '90%',
      '@media (min-width:900px)': {
        marginLeft: '111px',
        marginRight: '111px',
      },
      display: 'flex',
    },
    '& .indicatorContainer': {
      '& .indicatorIconButton': {
        '& svg': {
          fontSize: '25px',
          '&:hover': {
            color: theme.palette.secondary.dark,
          },
        },
      },
    },
  },
  navButton: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 1,
  },
  icon: {
    color: theme.palette.grey[600],
  },
  title: {
    fontSize: 40,
    paddingRight: 40,
    '@media (max-width:400px)': {
      fontSize: '1.5rem',
    },
  },
  description: {
    lineHeight: 1.61,
    fontSize: 18,
    paddingRight: 70,
    '@media (max-width:400px)': {
      fontSize: 14,
    },
  },
  item: {
    height: '100%',
    flexDirection: 'row',
    '@media (max-width:800px)': {
      margin: 16,
    },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const Item: React.FC<Props> = ({
  Content,
  handleNotShowAgain,
  onNext,
  media,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('welcome')
  return (
    <Grid container spacing={2} className={classes.item}>
      <Grid item xs={12} lg={6}>
        <Box>
          <Content onNext={onNext} />
        </Box>
        <Box mt={4}>
          <Link
            href="#"
            color="secondary"
            underline="always"
            onClick={handleNotShowAgain}
          >
            {t('buttons.hide')}
          </Link>
        </Box>
      </Grid>
      <Hidden only={['xs', 'sm']}>
        <Grid item xs={12} lg={6}>
          <Image src={media} width={800} height={450} />
        </Grid>
      </Hidden>
    </Grid>
  )
}

export const WelcomeDialog: React.FC = () => {
  const classes = useStyles()
  const user = useUserInfo()
  const isMobile = useIsMobile()
  const isContentCreator = user.hasRole(Role.CONTENT_CREATOR)
  const [welcome, setWelcome] = useState(localStorage.getItem('showWelcome'))
  const { skip, setSkip } = useContext(WelcomeContext)
  const [carouselIndex, setCarouselIndex] = useState(0)
  const { t } = useTranslation('welcome')

  const handleNotShowAgain = () => {
    localStorage.setItem('showWelcome', 'false')
    setWelcome(localStorage.getItem('showWelcome'))
  }

  const handleClose = () => {
    setSkip(false)
  }

  const slideWelcome = ({ onNext }) => (
    <Grid container direction="row" spacing={3}>
      <Grid item>
        <Typography className={classes.title} variant="h1">
          {t('welcome.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.description}>
          <Trans ns="welcome" i18nKey="welcome.description-abp">
            <b></b>
          </Trans>
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.description}>
          <Trans ns="welcome" i18nKey="welcome.description-strategy">
            <b></b>
          </Trans>
        </Typography>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={onNext}>
          {t('buttons.next')}
        </Button>
      </Grid>
    </Grid>
  )

  const slideCreateProyect = () => (
    <Grid container direction="row" spacing={3}>
      <Grid item>
        <Typography className={classes.title} variant="h1">
          {t('project.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.description}>
          <Trans ns="welcome" i18nKey="project.description">
            <b></b>
          </Trans>
        </Typography>
      </Grid>
      <Grid item>
        <NextLink href={Project.getCreationRoute()} passHref>
          <Button color="primary" variant="contained" onClick={handleClose}>
            {t('buttons.create-project')}
          </Button>
        </NextLink>
      </Grid>
    </Grid>
  )

  const slideLibrary = () => (
    <Grid container direction="row" spacing={3}>
      <Grid item>
        <Typography className={classes.title} variant="h1">
          {t('library.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.description}>
          {t('library.description')}
        </Typography>
      </Grid>
      <Grid item>
        <NextLink href={Project.getShowLibrary()} passHref>
          <Button color="primary" variant="contained" onClick={handleClose}>
            {t('buttons.library')}
          </Button>
        </NextLink>
      </Grid>
    </Grid>
  )

  const slideProyects = () => (
    <Grid container direction="row" spacing={3}>
      <Grid item>
        <Typography className={classes.title} variant="h1">
          {t('project-detail.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.description}>
          {t('project-detail.description')}
        </Typography>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={handleClose}>
          {t('buttons.abp')}
        </Button>
      </Grid>
    </Grid>
  )

  const { welcomeImages } = getSkin()
  const items = [
    {
      content: slideWelcome,
      img: '/welcome-abp.svg',
    },
    {
      content: slideCreateProyect,
      img: welcomeImages.newProject,
    },
    {
      content: slideLibrary,
      img: welcomeImages.library,
    },
    {
      content: slideProyects,
      img: welcomeImages.projects,
    },
  ]

  if (isContentCreator) {
    items.splice(1, 1)
  }

  return (
    <>
      <Dialog
        fullScreen
        open={JSON.parse(welcome!) && skip}
        onClose={handleNotShowAgain}
        disableEscapeKeyDown
        TransitionComponent={Transition}
      >
        <Carousel
          className={classes.carousel}
          autoPlay={false}
          navButtonsAlwaysVisible
          fullHeightHover
          index={carouselIndex}
          NextIcon={
            <NavigateNextIcon fontSize="large" className={classes.icon} />
          }
          PrevIcon={
            <NavigateBeforeIcon fontSize="large" className={classes.icon} />
          }
          navButtonsProps={{
            className: 'Nav-Button',
            style: {
              backgroundColor: '#ededed',
              borderRadius: '3px',
              opacity: 0.87,
            },
          }}
          indicatorIconButtonProps={{
            className: 'indicatorIconButton',
            style: {
              color: '#d8d8d8',
              fontSize: '25px',
            },
          }}
          activeIndicatorIconButtonProps={{
            className: 'activeIndicatorIconButton',
            style: {
              color: '#0389d1',
            },
          }}
          indicatorContainerProps={{
            className: 'indicatorContainer',
            style: {
              fontSize: '25px',
            },
          }}
          navButtonsAlwaysInvisible={isMobile}
        >
          {items.map((item, i) => (
            <Item
              key={i}
              Content={item.content}
              handleNotShowAgain={handleNotShowAgain}
              onNext={() => setCarouselIndex(i + 1)}
              media={item.img}
            />
          ))}
        </Carousel>
      </Dialog>
    </>
  )
}
