import { set, pipe } from 'lodash/fp'
import { es } from 'yup-locales'

/*
 * Se sobreescriben ciertos mensajes de
 * https://github.com/LoicMahieu/yup-locales/blob/master/src/locales/es.ts
 */

// TODO: Implementar traducciones (?) para cada locale manualmente
export const customErrorMessages = pipe(
  set('mixed.required', 'Este campo es requerido'),
  set('array.min', 'Debe seleccionar al menos ${min}'),
  set('string.url', 'El formato de la URL es inválido')
)(es)
