import { UserInfo } from '@ticmas/common-interfaces'
import { BaseModel } from './BaseModel'
import { CurricularContent } from './CurricularContent'

export class DistributionGroups extends BaseModel {
  _id: string
  owners: UserInfo[]
  name: string
  registration: string
  category: string
  contents: CurricularContent[]
  deleted?: boolean
  archived?: boolean

  static getContentListRoute() {
    return `/contenidos-curricular`
  }

  static getContentRoute(contentId: string) {
    return `/contenidos-curricular/${contentId}`
  }

  static getAssetRoute(contentId: string, assetId: string) {
    return `/contenidos-curricular/${contentId}/asset/${assetId}`
  }
}
