import {
  Button,
  IconButton,
  Popover,
  Theme,
  Typography,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { makeStyles } from '@material-ui/core/styles'
import { Search } from '@mui/icons-material'
import { Hidden } from '@mui/material'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProjectFilter } from '../ProjectFilter'
import { TabLibraryDisney } from './TabLibraryDisney'
import { TabLibraryTicmas } from './TabLibraryTicmas'
import { TabProject } from './TabProject'
import { TabTeacher } from './TabTeacher'
import { useProjectFilterForm } from './hooks'
import { AnalyticsAction, createProjectEvent, logEvent } from '@/lib/analytics'
import { useUserInfo } from '@/lib/auth'
import { getSkin } from '@/lib/custom-skin'
import { Skin } from '@/skins/types'
import { TabCurricular } from '@components/Home/TabCurricular'
import { useContextMenu } from '@components/common/ContextMenu/hooks'
import { NewFeatureTooltip } from '@components/common/NewFeatureArrow'
import { useHideTooltip } from '@components/common/NewFeatureArrow/hooks'
import { Project } from '@models/Project'
import { Role } from '@models/Role'

export enum TabIdViews {
  projects = 'projects',
  libraryABP = 'libraryABP',
  libraryDisney = 'libraryDisney',
  teachers = 'teachers',
  curricular = 'curricular',
}

interface Props {
  view: keyof typeof TabIdViews
  tabsToShow: Array<keyof typeof TabIdViews>
}

interface View {
  tabId: Props['view']
  href: string
  label: string
  Component: () => JSX.Element | null
}

const FILTERS_MENU_WIDTH = 440

const useStyles = makeStyles<Theme, Skin>(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  subheadContainer: {
    background: skin => skin.subhead.background,
    marginBottom: theme.spacing(3),
    borderRadius: '8px',
  },
  tab: {
    color: skin => skin.subhead.textColor,
  },
  tabIndicator: {
    background: skin => skin.subhead.home.tabIndicatorColor,
  },
  title: {
    color: skin => skin.subhead.textColor,
  },
  filtersBtn: {
    color: '#fff',
    border: '1px solid #fff',
    padding: '8px',
  },
  filtersMenu: {
    width: `${FILTERS_MENU_WIDTH}px`,
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    height: skin => skin.subhead.home.height,
    paddingRight: skin => theme.spacing(skin.subhead.paddingRight),
  },
}))

export const Home = ({ view, tabsToShow }: Props) => {
  const skin = getSkin()
  const classes = useStyles(skin)
  const router = useRouter()
  const user = useUserInfo()
  const [curricularTabRef, setCurricularTabRef] = useState<HTMLAnchorElement>()
  const { t } = useTranslation('home')
  const { isTooltipHidden, hideTooltip } = useHideTooltip(
    'tabCurricularTooltip'
  )
  const [hideCreateButton, setHideCreateButton] = useState<boolean>(false)
  const {
    openContextMenu: openFiltersMenu,
    closeContextMenu: closeFiltersMenu,
    menuAnchor: filtersMenuAnchor,
    open: isFiltersMenuOpen,
  } = useContextMenu()
  const { hookform, searching, resetFilters, clearTitleSearch } =
    useProjectFilterForm()

  const isContentCreator = user.hasRole(Role.CONTENT_CREATOR)

  const views: View[] = useMemo(() => {
    const views = [
      {
        tabId: 'projects',
        href: '/mis-proyectos',
        label: t('tabs.proyectos-en-curso'),
        Component: function TabProjectWrapper() {
          return (
            <TabProject
              setHideCreateButton={setHideCreateButton}
              searching={searching}
            />
          )
        },
      },
      {
        tabId: 'libraryABP',
        href: '/biblioteca-abp',
        label: t('tabs.biblioteca'),
        Component: TabLibraryTicmas,
      },
      {
        tabId: 'libraryDisney',
        href: '/biblioteca-disney',
        label: t('tabs.biblioteca-disney'),
        Component: TabLibraryDisney,
      },
      {
        tabId: 'teachers',
        href: '/docentes',
        label: t('tabs.docentes'),
        Component: TabTeacher,
      },
      {
        tabId: 'curricular',
        href: '/contenidos-curricular',
        label: t('tabs.contenidos'),
        Component: TabCurricular,
      },
    ]

    return views.filter(v =>
      tabsToShow.includes(v.tabId as TabIdViews)
    ) as View[]
  }, [tabsToShow, t, searching])

  const currentView = views.find(({ tabId }) => tabId === view)

  const handleTabClick = (e, tabId) => {
    e.preventDefault()
    if (tabId === 'curricular') {
      hideTooltip()
    }
  }

  const handleToggleFilters = event => {
    openFiltersMenu(event)
  }

  // This is needed because we're using disableScrollLock={true}
  useEffect(() => {
    const body = window.document.body
    if (body.scrollHeight > body.offsetHeight) {
      body.style.overflow = isFiltersMenuOpen ? 'hidden' : ''
      body.style.paddingRight = isFiltersMenuOpen ? '15px' : ''
    }
  }, [isFiltersMenuOpen])

  logEvent(AnalyticsAction.VIEW_HOME_PAGE, {
    id_usuario: user.id,
    nombre_usuario: user.fullName,
    nombre_tab: view,
  })

  return (
    <Box className={classes.root}>
      <FormProvider {...hookform}>
        <Popover
          id="filters-menu"
          anchorEl={filtersMenuAnchor}
          onClose={closeFiltersMenu}
          className={classes.filtersMenu}
          /* This is necessary to prevent the content from being reloaded when hidden/shown */
          style={{ display: isFiltersMenuOpen ? 'block' : 'none' }}
          open={true}
          disableScrollLock={true}
          /* **** */
          PaperProps={{
            style: {
              backgroundColor: '#EDEDED',
              width: `${FILTERS_MENU_WIDTH}px`,
              boxShadow: 'none',
              padding: '8px 6px',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ProjectFilter
            onClearAllFilters={resetFilters}
            onClearTitleFilter={clearTitleSearch}
          />
        </Popover>
      </FormProvider>

      {curricularTabRef && (
        <NewFeatureTooltip
          text={t('tooltips.curricular-tab')}
          hide={isTooltipHidden}
          anchorEl={curricularTabRef}
          onClick={() => {
            hideTooltip()
          }}
        />
      )}
      {!currentView ? null : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.subheadContainer}
          >
            <Box
              mb={1.5}
              alignSelf="end"
              display="flex"
              pl={skin.subhead.paddingLeft}
            >
              {tabsToShow.length > 1 ? (
                <Tabs
                  value={views.indexOf(currentView)}
                  onChange={(e, index) => router.push(views[index].href)}
                  aria-label={t('busqueda-proyectos')}
                  TabIndicatorProps={{
                    className: classes.tabIndicator,
                  }}
                >
                  {views
                    .filter(({ tabId }) => tabsToShow.includes(tabId))
                    .map(({ tabId, label, href }) => (
                      <Tab
                        id={tabId}
                        key={tabId}
                        label={label}
                        component="a"
                        href={href}
                        className={classes.tab}
                        onClick={e => handleTabClick(e, tabId)}
                        ref={newRef => {
                          if (tabId === 'curricular' && newRef) {
                            setCurricularTabRef(newRef)
                          }
                        }}
                      />
                    ))}
                </Tabs>
              ) : (
                <Box mb={2}>
                  <Typography variant="h1" className={classes.title}>
                    {currentView?.label}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              className={classes.buttonsContainer}
              display="flex"
              alignItems="center"
            >
              <Box display="inline-block" pr={4}>
                <IconButton
                  onClick={handleToggleFilters}
                  className={classes.filtersBtn}
                >
                  <Search />
                </IconButton>
              </Box>
              {Project.userCanCreateProjects(user) &&
                !hideCreateButton &&
                currentView.tabId !== 'teachers' && (
                  <Hidden mdDown>
                    <NextLink href={Project.getCreationRoute()} passHref>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          createProjectEvent(
                            isContentCreator && view === 'libraryABP'
                          )
                        }
                      >
                        {t('crear-proyecto')}
                      </Button>
                    </NextLink>
                  </Hidden>
                )}
            </Box>
          </Box>

          <currentView.Component />
        </>
      )}
    </Box>
  )
}
