import { cloneDeep, castArray } from 'lodash/fp'

export type UrlParams = Record<string, string | string[] | undefined>

export const cloneAsRecord = filters => cloneDeep(filters) as UrlParams

class URLSearchParamsArray {
  private params: URLSearchParams

  constructor(fields: UrlParams) {
    this.params = new URLSearchParams()

    Object.entries(fields)
      .filter(([_, v]) => !!v)
      .forEach(([k, v]) => {
        castArray(v).forEach(item => this.params.append(k, item!))
      })
  }

  isEmpty() {
    return this.params.toString() === ''
  }

  toString() {
    return this.params.toString()
  }
}

export default URLSearchParamsArray
