import { Box, Container, ContainerProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { DisneyWelcomeDialog } from './DisneyWelcomeDialog'
import EmptyUser from './EmptyUser'
import { Navbar } from './Navbar'
import { NewPlatformDialog } from './NewPlatformModal'
import { WelcomeDialog } from './WelcomeDialog'
import { useUserInfo } from '@/lib/auth'
import { useConfigurationContext, useConfiguration } from '@/lib/configuration'
import { useInstitutionContext } from '@/lib/institution-context'
import { useDisney } from '@hooks/disney'
import { usePlatformConfiguration } from '@hooks/fetch/institutionsServiceData'
import { useNewTeacherPlatformActivation } from '@hooks/newTeacherPlatform'
import { Role } from '@models/Role'

const NAVBAR_HEIGHT = '72px'

const useStyles = makeStyles(_theme => ({
  container: {
    height: `calc(100vh - ${NAVBAR_HEIGHT})`,
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem',
  },
}))

export const Layout: React.FC<
  Pick<ContainerProps, 'maxWidth' | 'children'>
> = ({ maxWidth = 'lg', children }) => {
  const classes = useStyles()
  const user = useUserInfo()
  const { selectedInstitution } = useInstitutionContext()
  const { setConfigurationList, setConfiguration } = useConfigurationContext()
  const { welcomeScreen } = useConfiguration()
  const { isSubscribeToDisney } = useDisney()
  const [showDisneyWelcomeDialog, setShowDisneyWelcomeDialog] =
    useState<boolean>(false)
  const { isNewTeacherPlatformActivated } = useNewTeacherPlatformActivation(
    user,
    selectedInstitution?.id
  )

  const {
    asDict: configuration,
    asList: configurationList,
    error,
    loading,
  } = usePlatformConfiguration()

  useEffect(() => {
    if (!loading && !!selectedInstitution && !error) {
      setConfigurationList(configurationList)
      setConfiguration(configuration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, configurationList, selectedInstitution])

  useEffect(() => {
    setShowDisneyWelcomeDialog(
      !localStorage.getItem('showDisneyWelcome') &&
        isSubscribeToDisney &&
        user &&
        user.hasRole(Role.STUDENT)
    )
  }, [isSubscribeToDisney, user])

  if (user && !user.hasAnyRole()) {
    return <EmptyUser />
  }

  const shouldShowWelcomeDialog =
    welcomeScreen && user && !user.hasRole(Role.STUDENT)

  const handleCloseDisneyDialog = () => {
    setShowDisneyWelcomeDialog(false)
    localStorage.setItem('showDisneyWelcome', 'true')
  }

  return (
    <>
      {shouldShowWelcomeDialog && <WelcomeDialog />}
      <Navbar showNewTeacherPlatformButton={isNewTeacherPlatformActivated} />
      {showDisneyWelcomeDialog ? (
        <DisneyWelcomeDialog onClose={handleCloseDisneyDialog} />
      ) : (
        <Container
          maxWidth={maxWidth}
          component="main"
          className={classes.container}
        >
          {isNewTeacherPlatformActivated && <NewPlatformDialog />}
          <Box className={classes.innerContainer}>{children}</Box>
        </Container>
      )}
    </>
  )
}
