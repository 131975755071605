import styled from '@emotion/styled'
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  CardMediaProps,
} from '@mui/material'
import React from 'react'
import { CardChip } from './card-chip'
import { DefaultImage } from './default-image'
import { ticmasColor } from '@/skins/default'
import { Language } from '@models/Language'

interface Props {
  title: string
  coverUrl: string
  language: Language
  institutionLogotype?: React.ReactNode
  onClick: () => void
}

const CardContainer = styled(Card)`
  height: 324px;
  width: 100%;
  border-radius: 0.75rem;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 0px 20px -1px rgba(0, 0, 0, 0.2),
      0px 0px 20px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    transform: translateY(-3px);
  }
`

const CardMediaContainer = styled(({ ...props }: CardMediaProps<'img'>) => (
  <CardMedia {...props} component="img" />
))`
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'center';
`

const CardContentStyled = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-content: center;
`

const CardTitle = styled(Typography)`
  margin-top: -0.5rem;
  color: ${ticmasColor.disneyBlue};
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`

export const ProjectCardStudent: React.FC<Props> = ({
  title,
  coverUrl,
  language,
  institutionLogotype,
  onClick,
}) => {
  return (
    <Box position="relative" height="100%" width="100%">
      <CardContainer onClick={onClick}>
        {coverUrl ? (
          <CardMediaContainer image={coverUrl} height="144px" />
        ) : (
          <DefaultImage width={169} height={72} containerHeigth={144} />
        )}
        <CardContentStyled>
          <Box height="16px" marginTop="-0.75rem">
            {institutionLogotype}
          </Box>
          <Box height="40px" marginTop="0.5rem">
            <CardTitle>{title}</CardTitle>
          </Box>
          <Box display="flex" paddingTop="0.5rem">
            <CardChip
              label={language.name}
              color={ticmasColor.neutralDarkness}
            />
          </Box>
        </CardContentStyled>
      </CardContainer>
    </Box>
  )
}
