import { UserInfo } from '@ticmas/common-interfaces'
import { capitalize, concat, get, isEmpty, memoize, negate } from 'lodash/fp'
import { BaseModel } from './BaseModel'
import { Role } from './Role'

const getUserRoles = memoize(
  ({ resource_access = {}, realm_access }, includeRealmRoles = true) =>
    Object.values(resource_access)
      .map(get('roles'))
      .reduce<Role[]>(
        concat,
        includeRealmRoles ? get('roles', realm_access) : []
      )
)

export class User extends BaseModel implements UserInfo {
  sub: string
  email: string
  family_name: string
  given_name: string
  name: string
  picture: string
  preferred_username: string

  get id() {
    return this.sub
  }

  get roles() {
    return getUserRoles(this)
  }

  get firstRole() {
    return this.roles
      .filter(r =>
        [Role.STUDENT, Role.TEACHER, Role.CONTENT_CREATOR].includes(r)
      )
      .shift()
  }

  hasRole(role: Role) {
    const hasStudent = this.roles.includes(Role.STUDENT)
    const hasTeacher = this.roles.includes(Role.TEACHER)

    if (hasStudent && hasTeacher && role === Role.STUDENT) return false

    return this.roles.includes(role)
  }

  hasSomeRole(roles: Role[]) {
    return this.roles.some(r => roles.includes(r))
  }

  hasAnyRole() {
    return (
      this.roles.includes(Role.STUDENT) ||
      this.roles.includes(Role.TEACHER) ||
      this.roles.includes(Role.CONTENT_CREATOR) ||
      this.roles.includes(Role.TUTOR) ||
      this.roles.includes(Role.TUTOR_MASTER)
    )
  }

  fullName() {
    return (
      [this.given_name, this.family_name]
        .filter(negate(isEmpty))
        .map(capitalize)
        .join(' ')
        .trim() || this.preferred_username
    )
  }
}
