import { createSkin } from './skinBuilder'

export const cabaSkin = createSkin(
  {
    primaryColor: '#fcda59',
    primaryHoverColor: '#fdd306',
    secondaryColor: '#0389d1',
    primaryBtnTextColor: '#685723',
  },
  {
    logo: '/skins/caba/logoed.svg',
    favicon: '/skins/caba/favicon-ba.ico',
    welcomeImages: {
      newProject: '/skins/caba/new-project-caba.gif',
      library: '/skins/caba/library-caba.gif',
      projects: '/skins/caba/projects-caba.gif',
    },
    subhead: {
      background:
        'linear-gradient(91deg, #FFD500 0%, #FFDF43 21.39%, #EEEDA6 51.91%, #A7D5C2 77.60%, #29BDEF 100%)',
      textColor: '#3C3C3B',
      paddingLeft: 3,
      paddingRight: 3,
      home: {
        tabIndicatorColor: '#3C3C3B',
        height: '106px',
      },
      rubrics: {
        height: '80px',
      },
    },
    projectDetail: {
      phaseBackgroundColor: '#e3e4e8',
      textColor: '#000',
      iconColor: '#757575',
      buttonAddResourceBgColor: '#f5f5f5',
      buttonAddResourceTextColor: '#616161',
      buttonAddPhaseBgColor: '#e3e4e8',
      buttonAddPhaseTextColor: '#616161',
    },
  }
)
