import { CssBaseline, ThemeProvider } from '@material-ui/core'

import { SSRKeycloakProvider } from '@react-keycloak/ssr'
import axios from 'axios'
import { get, isNull, isUndefined } from 'lodash/fp'

import App, { AppContext, AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'

import { SnackbarProvider } from 'notistack'

import '@/lib/i18n'

import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { FeatureFlagsProvider } from '../contexts/featureFlags'
import { ImpersonationTeacherProvider } from '../contexts/impersonation'
import { ProjectSearchProvider } from '../contexts/projectSearch'
import { RestProjectStoreProvider } from '../contexts/projectStore'
import { TenantConfigProvider } from '../contexts/tenantConfig'
import { WelcomeProvider } from '../contexts/welcome'
import { setupClients } from '@/lib/clients'
import { ConfigurationProvider } from '@/lib/configuration'
import { overrideEnvByDomain } from '@/lib/custom-domains'
import { getSkin } from '@/lib/custom-skin'
import { ClientSideEnvironment, EnvContext } from '@/lib/env'
import { LaunchDarklyFeatureFlagProvider } from '@/lib/featureFlag'
import { InstitutionContextProvider } from '@/lib/institution-context'
import { GlobalCss } from '@components/common/GlobalCss'
import { Layout } from '@components/common/Layout'

const SSRKeycloakClientProvider = dynamic(
  () => import('@components/Auth/SSRKeycloakClientProvider'),
  {
    ssr: false,
    suspense: false,
  }
)

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const [env, setEnv] = useState<ClientSideEnvironment | null>(null)
  const { ready } = useTranslation()

  useEffect(() => {
    const queryPv = new URLSearchParams(window.location.search).get('pv')

    if (queryPv) {
      sessionStorage.setItem('platformVersion', queryPv)
    }

    axios
      .get('/api/env', { baseURL: '' })
      .then(({ data }) => setEnv(overrideEnvByDomain(data)))
  }, [])

  useEffect(() => {
    setupClients({
      amplitudeApiKey: env?.amplitudeApiKey ?? '',
      sentryDsn: env?.sentryDsn ?? '',
      nodeEnv: env?.nodeEnv ?? '',
      hotjar: env?.hotjar,
      launchDarklyClientId: env?.launchDarklyClientId ?? '',
    })
  }, [env])

  if (!env || !ready) {
    return <div />
  }

  if (!localStorage.getItem('showWelcome')) {
    localStorage.setItem('showWelcome', 'true')
  }

  const skin = getSkin()

  const ComponentDeclaredLayout = get('layout', Component)
  const ComponentWrapper = isUndefined(ComponentDeclaredLayout)
    ? Layout
    : isNull(ComponentDeclaredLayout)
    ? React.Fragment
    : ComponentDeclaredLayout

  return (
    <>
      <Head>
        <title>ABP</title>

        <link rel="icon" href={skin.favicon} />
        {skin.apple_touch_favicon && (
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={skin.apple_touch_favicon}
          />
        )}
        {skin.size_32_favicon && (
          <link rel="icon" sizes="32x32" href={skin.apple_touch_favicon}></link>
        )}
        {skin.size_16_favicon && (
          <link rel="icon" sizes="16x16" href={skin.apple_touch_favicon}></link>
        )}
      </Head>
      <EnvContext.Provider value={env}>
        <SSRKeycloakClientProvider
          KeycloakProvider={SSRKeycloakProvider}
          keycloakConfig={env.keycloak}
        >
          <ThemeProvider theme={skin.theme}>
            <CssBaseline />
            <GlobalCss />
            <SnackbarProvider preventDuplicate>
              <TenantConfigProvider>
                <LaunchDarklyFeatureFlagProvider>
                  <FeatureFlagsProvider>
                    <ImpersonationTeacherProvider>
                      <RestProjectStoreProvider>
                        <ProjectSearchProvider>
                          <WelcomeProvider>
                            <InstitutionContextProvider>
                              <ConfigurationProvider>
                                <ComponentWrapper>
                                  <Component {...pageProps} />
                                </ComponentWrapper>
                              </ConfigurationProvider>
                            </InstitutionContextProvider>
                          </WelcomeProvider>
                        </ProjectSearchProvider>
                      </RestProjectStoreProvider>
                    </ImpersonationTeacherProvider>
                  </FeatureFlagsProvider>
                </LaunchDarklyFeatureFlagProvider>
              </TenantConfigProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </SSRKeycloakClientProvider>
      </EnvContext.Provider>
    </>
  )
}

CustomApp.getInitialProps = async (context: AppContext) => {
  context?.ctx?.res?.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=3600'
  )
  const appProps = await App.getInitialProps(context)

  return appProps
}

export default withTranslation()(CustomApp)
