import { UserInfo } from '@ticmas/common-interfaces'
import { BaseModel } from './BaseModel'

export class Teacher extends BaseModel {
  static getPathByUser(userId: string) {
    return `/v1/is/people?userId=${userId}`
  }

  static getPathByInstitution(institutionId: string) {
    return `/v1/is/people?institutionId=${institutionId}&role=teacher&orderCol=familyName&limit=200`
  }

  id: string
  userId: string
  familyName: string
  givenName: string
  email?: string

  get name() {
    return `${this.givenName} ${this.familyName}`
  }

  is(user: UserInfo) {
    return this.userId === user.sub
  }
}
