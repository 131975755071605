import { useState } from 'react'

export const useHideTooltip = (key: string) => {
  const [isTooltipHidden, setTooltipHidden] = useState(
    !!localStorage.getItem(key)
  )

  return {
    hideTooltip() {
      localStorage.setItem(key, 'true')
      setTooltipHidden(true)
    },
    isTooltipHidden,
  }
}
