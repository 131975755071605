import { makeStyles, ListItemText, ListItem, List } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles(theme => ({
  listContainer: {
    height: '270px',
    overflowY: 'auto',
    '& :hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  listItem: {
    borderLeft: '4px solid transparent',
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selected: {
    borderLeftColor: `${theme.palette.primary.main}`,
  },
}))

interface Item {
  id: string
  name: string
  caption?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

interface Props {
  items: Item[]
  selected: Item | null
  onChange: (entity: Item) => void
}

export const UserContextSelector: React.FC<Props> = ({
  items,
  selected,
  onChange,
}) => {
  const classes = useStyles()

  return (
    <List className={classes.listContainer}>
      {items.map(item => (
        <ListItem
          className={clsx(
            classes.listItem,
            selected?.id === item.id && classes.selected
          )}
          key={item.id}
          onClick={() => onChange(item)}
        >
          <ListItemText primary={item.name} secondary={item.caption} />
        </ListItem>
      ))}
    </List>
  )
}

export default UserContextSelector
