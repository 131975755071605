import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'
import React from 'react'
import type { FC } from 'react'

export const BidiIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.245361 19.2151C0.684107 18.5388 1.47061 18.3013 2.04626 17.8036C3.00748 16.9734 3.94213 16.81 5.03636 17.5605C5.56063 17.9233 6.20278 18.1133 6.88096 18.4249C6.32812 18.7914 5.84188 19.1144 5.30039 19.4773C7.0747 20.2884 8.79396 21.073 10.5112 21.8576C12.8612 22.9291 15.2111 23.9909 17.5515 25.0794C18.3057 25.429 18.9439 25.41 19.6715 24.9408C24.1074 22.0646 28.5715 19.2227 33.0226 16.3655L33.4937 15.9818C31.7498 15.184 30.0154 14.3899 28.2125 13.5654C28.6191 13.3013 28.9762 13.0581 29.3371 12.8302C30.1826 12.2851 31.0355 11.7588 31.8676 11.2022L32.6807 11.1243C34.6696 12.0513 36.6699 12.9537 38.6666 13.8693L39.1644 14.1562C39.9698 14.7679 39.9506 15.6437 39.1092 16.1927C37.6978 17.114 36.273 18.0164 34.852 18.9283C30.1445 21.9392 25.4315 24.9408 20.7373 27.9746C19.6411 28.687 18.5279 28.7459 17.3882 28.2292C12.0443 25.8071 6.7137 23.3602 1.37742 20.9324C0.898856 20.714 0.509386 20.4101 0.245361 19.956V19.2151Z"
        fill="#1C85C8"
      />
      <path
        d="M0.245361 25.2371C1.12863 24.2836 2.33497 23.7859 3.37027 23.0507L3.61906 23.0221C4.68493 23.4971 5.74691 23.9834 6.89231 24.502C6.46292 24.7813 6.12482 24.992 5.79429 25.2219L5.43329 25.545L5.81331 25.7824C7.87255 26.7265 9.9319 27.6688 11.9929 28.6073C13.8775 29.4678 15.7677 30.3151 17.6446 31.1908C18.3247 31.51 18.9136 31.4947 19.5709 31.0692C24.0694 28.1627 28.5907 25.2828 33.1043 22.3915L33.5487 22.097C33.1328 21.8899 32.7585 21.7075 32.3159 21.4929C33.5221 20.7197 34.6924 19.9941 35.8323 19.2209C36.2254 18.955 36.5446 18.9207 36.9701 19.1544C37.5685 19.4849 38.2183 19.7148 38.8299 20.0263C40.0077 20.6248 40.0629 21.6563 38.9573 22.3648C32.8801 26.2649 26.7935 30.1479 20.7182 34.0593C19.5822 34.7908 18.45 34.787 17.2665 34.2493C13.5773 32.5681 9.8882 30.8869 6.199 29.2018C4.68104 28.5104 3.16707 27.8093 1.64166 27.133C1.09448 26.8861 0.600476 26.5822 0.245361 26.0806L0.245361 25.2371Z"
        fill="#1978B6"
      />
      <path
        d="M0.245361 31.5676C1.13252 30.6538 2.30461 30.1409 3.33234 29.4247L3.678 29.3849C4.72474 29.8598 5.77527 30.3423 6.88842 30.8551C6.33579 31.2142 5.85701 31.5259 5.29661 31.8905C6.48015 32.4357 7.5686 32.9411 8.66662 33.4426C11.6359 34.797 14.6127 36.1364 17.5742 37.506C18.317 37.8537 18.9326 37.8404 19.6449 37.3825C24.1244 34.4742 28.6305 31.6075 33.129 28.7238L33.5487 28.4312C33.1252 28.2337 32.7471 28.0532 32.3063 27.848C33.6457 26.9874 34.9261 26.1706 36.1989 25.3461L36.6416 25.365C37.3634 25.6919 38.0815 26.011 38.7939 26.353C39.2877 26.5904 39.6886 26.9305 39.7399 27.525C39.7874 28.1235 39.3941 28.4388 38.9458 28.7238C32.806 32.658 26.6682 36.5923 20.5246 40.5189C19.4778 41.1876 18.3797 41.1021 17.3026 40.612C12.2703 38.323 7.24186 36.0243 2.20963 33.7314C1.48774 33.397 0.701232 33.148 0.245361 32.4129V31.5676Z"
        fill="#1C85C8"
      />
      <path
        d="M17.1383 -0.0012846L17.5429 0.355826C18.3066 0.711046 19.0798 1.04546 19.8397 1.40835C20.4456 1.69896 20.4722 2.03716 19.9251 2.39994C19.2394 2.85403 18.527 3.27186 17.8392 3.71828L16.7888 3.77344C15.8465 3.34604 14.908 2.90908 13.9658 2.4779L13.5459 1.99145L13.9468 1.43483C14.53 1.07583 15.1075 0.711046 15.6794 0.336915L15.9738 -0.0012846H17.1383Z"
        fill="#CECCCC"
      />
      <path
        d="M21.2054 7.92969C20.7457 7.90689 20.4075 7.6561 20.0408 7.50607C19.2791 7.19834 18.5363 6.83545 17.7878 6.49357C17.2636 6.25603 17.2085 5.87233 17.6871 5.54748C18.4261 5.05357 19.1898 4.58625 19.9441 4.11136L20.7494 4.08468C21.7771 4.53687 22.8012 4.99274 23.8175 5.46196C24.2714 5.67292 24.3417 6.04516 23.9523 6.31118C23.162 6.85636 22.3414 7.36171 21.5245 7.87085L21.2054 7.92969Z"
        fill="#CECCCC"
      />
      <path
        d="M10.666 10.2403C11.2302 10.2043 11.6423 10.5955 12.1287 10.7855C12.7784 11.0382 13.4052 11.3611 14.0436 11.6537L14.4558 12.1476L14.091 12.6169C13.4167 13.031 12.7479 13.447 12.0944 13.8896C11.724 14.1404 11.3631 14.1974 10.9623 14.0113C9.98968 13.5647 9.01122 13.1279 8.04621 12.6644C7.51816 12.4079 7.50104 12.0698 8.00062 11.7316C8.69214 11.2605 9.41204 10.8198 10.1093 10.3486L10.666 10.2403Z"
        fill="#CECCCC"
      />
      <path
        d="M12.656 20.2852C11.9245 20.2587 11.4345 19.873 10.8854 19.6621C10.266 19.4208 9.66765 19.1226 9.07499 18.8187C8.58109 18.5698 8.55829 18.2108 9.01605 17.9087C9.72082 17.4452 10.4447 17.0158 11.1457 16.5618C11.4913 16.3357 11.8352 16.3244 12.1962 16.4915C13.1346 16.9228 14.0807 17.3388 15.0096 17.7872C15.6136 18.074 15.6289 18.4558 15.0723 18.8262C14.4151 19.2613 13.7577 19.6906 13.0947 20.118L12.656 20.2852Z"
        fill="#CECCCC"
      />
      <path
        d="M24.8014 14.4608C25.3389 14.4419 25.6999 14.7609 26.112 14.9395C26.832 15.2549 27.5558 15.5683 28.2607 15.916C28.7755 16.1687 28.7793 16.484 28.3005 16.8031C27.6299 17.2515 26.946 17.6789 26.2792 18.1272C25.8956 18.3875 25.5099 18.4217 25.0901 18.2278C24.1706 17.7947 23.2398 17.3883 22.326 16.9475C21.7237 16.655 21.7124 16.2788 22.2709 15.9084C22.913 15.4809 23.5589 15.063 24.2066 14.6432L24.8014 14.4608Z"
        fill="#CECCCC"
      />
      <path
        d="M22.2983 14.1406C21.8157 14.0552 21.4377 13.8214 21.0369 13.6544C20.294 13.3466 19.5664 12.9951 18.835 12.657C18.3601 12.4328 18.3165 12.0358 18.7457 11.7471C19.4563 11.2645 20.1725 10.7934 20.9057 10.3412L21.9638 10.3355C22.9118 10.7459 23.854 11.1639 24.7926 11.5989C25.3871 11.8762 25.408 12.2257 24.859 12.5924C24.1769 13.0464 23.4856 13.4909 22.7884 13.9261L22.2983 14.1406Z"
        fill="#CECCCC"
      />
      <path
        d="M16.3327 10.9805L15.6791 10.8323C14.8851 10.4639 14.0873 10.1048 13.2893 9.73998L12.7593 9.40378L12.803 8.73505C13.5781 8.22591 14.3551 7.7111 15.1568 7.24945L16.1256 7.26469C17.0831 7.69597 18.0443 8.13094 18.9904 8.58302C19.4691 8.80912 19.5279 9.17579 19.1062 9.47018C18.3899 9.97364 17.6358 10.4201 16.8969 10.8874L16.3327 10.9805Z"
        fill="#CECCCC"
      />
      <path
        d="M7.56182 10.0312L6.63095 9.77479C5.79507 9.41768 4.97064 9.02821 4.14431 8.63884C3.76051 8.45456 3.67888 8.04418 4.02832 7.7934C4.80348 7.2539 5.6089 6.75043 6.42198 6.27554L7.24263 6.30023C8.23422 6.74287 9.23159 7.17983 10.2118 7.64326C10.6982 7.86757 10.7134 8.26072 10.2632 8.5571C9.57347 9.0073 8.86502 9.43291 8.17728 9.88311L7.56182 10.0312Z"
        fill="#CECCCC"
      />
      <path
        d="M17.4618 17.207L16.6449 16.9468C15.7729 16.5726 14.9163 16.168 14.0575 15.767C13.5693 15.5373 13.5275 15.1517 13.9797 14.8476C14.7224 14.3499 15.4842 13.8826 16.244 13.4076L17.0951 13.3925C18.0564 13.8294 19.0138 14.2645 19.9636 14.7204C20.5792 15.0111 20.5906 15.3701 20.0226 15.7462C19.392 16.1604 18.7612 16.5726 18.1229 16.9734L17.4618 17.207Z"
        fill="#CECCCC"
      />
    </SvgIcon>
  )
}
