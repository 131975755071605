import { AxiosResponse } from 'axios'
import { cloneDeep, map } from 'lodash/fp'
import { AxiosInstance } from '../../impersonation'
import {
  AvatarUrls,
  Keys,
  ProjectStore,
  UploadUrls,
  UserPicture,
} from '../types'
import { ActionSubmission } from '@models/ActionSubmission'
import { Activity } from '@models/Activity'
import { ContentActivity } from '@models/ContentActivity'
import { ContentItem } from '@models/ContentItem'
import { Criterion } from '@models/Criterion'
import { Descriptor } from '@models/Descriptor'
import { Feedback } from '@models/Feedback'
import { Group } from '@models/Group'
import { PerformanceLevel } from '@models/PerformanceLevel'
import { Phase } from '@models/Phase'
import { Project } from '@models/Project'
import { Rubric } from '@models/Rubric'
import { Score } from '@models/Score'
import { Submission } from '@models/Submission'
import { activityTypes } from '@models/activityTypes'

export class RestProjectStore implements ProjectStore {
  constructor(
    private mutate: (key, data, revalidate) => Promise<void>,
    private axios: AxiosInstance
  ) {}

  keys: Keys = {
    projects: () => `/v1/pbl/projects`,
    project: (project: Project) => `/v1/pbl/projects/${project.id}`,
    projectById: id => `/v1/pbl/projects/${id}`,
    cloneProject: (project: Project) => `/v1/pbl/projects/${project.id}/clone`,
    copyProject: (project: Project) => `/v1/pbl/projects/${project.id}/copy`,
    phases: (project: Project) => `/v1/pbl/projects/${project.id}/phases`,
    phase: (project: Project, phase: Phase) =>
      `/v1/pbl/projects/${project.id}/phases/${phase.id}`,
    activities: (project: Project, phase: Phase) =>
      `/v1/pbl/projects/${project.id}/phases/${phase.id}/activities`,
    activity: (project: Project, phase: Phase, activity: Activity) =>
      `/v1/pbl/projects/${project.id}/phases/${phase.id}/activities/${activity.id}`,
    uploads: () => `/v1/pbl/uploads`,
    reorderPhases: (project: Project, phaseId: string) =>
      `/v1/pbl/projects/${project.id}/phases/${phaseId}/position`,
    groups: (project: Project) => `/v1/pbl/projects/${project.id}/groups`,
    group: (project: Project, group: Group) =>
      `/v1/pbl/projects/${project.id}/groups/${group.id}`,
    library: () => `/v1/pbl/library`,
    reorderActivities: (
      project: Project,
      phaseId: string,
      activityId: string
    ) =>
      `/v1/pbl/projects/${project.id}/phases/${phaseId}/activities/${activityId}/position`,
    criteriaByJurisdiction: (jurisdictionId: string, languageId: string) =>
      `/v1/pbl/criteria?jurisdiction=${jurisdictionId}&language=${languageId}`,
    criteria: () => `/v1/pbl/criteria`,
    updateCriteria: (criterionId: Criterion['id']) =>
      `/v1/pbl/criteria/${criterionId}`,
    competences: (jurisdictionId: string, languageId: string) =>
      `/v1/pbl/competences?jurisdiction=${jurisdictionId}&language=${languageId}`,
    competencyMetrics: (project: Project) =>
      `/v1/pbl/projects/${project.id}/metrics/competences`,
    rubric: (project: Project) => `/v1/pbl/projects/${project.id}/rubric`,
    rubricDownload: (project: Project) =>
      `/v1/pbl/projects/${project.id}/rubric/download`,
    performanceLevels: (project: Project, performanceLevel: PerformanceLevel) =>
      `/v1/pbl/projects/${project.id}/performance-levels/${performanceLevel.id}`,
    descriptors: (project: Project, descriptor: Descriptor) =>
      `/v1/pbl/projects/${project.id}/performance-levels/${descriptor.performanceLevel}/descriptors/${descriptor.id}`,
    profile: () => `/v1/me`,
    profilePicture: () => `/v1/me/profile-picture`,
    assignCriteria: (project: Project) =>
      `/v1/pbl/projects/${project.id}/rubric/criteria`,
    criterion: (project: Project, criterionId: string) =>
      `/v1/pbl/projects/${project.id}/rubric/criteria/${criterionId}`,
    deleteCriterionFromActivity: (
      projectId: string,
      phaseId: string,
      activityId: string,
      criterionId: string
    ) =>
      `/v1/pbl/projects/${projectId}/phases/${phaseId}/activities/${activityId}/rubric/criteria/${criterionId}`,
    submission: (
      projectId: string,
      phaseId: string,
      activityId: string,
      submissionId: string
    ) =>
      `/v1/pbl/projects/${projectId}/phases/${phaseId}/activities/${activityId}/submissions/${submissionId}`,
    submissions: (projectId: string, phaseId: string, activityId: string) =>
      `/v1/pbl/projects/${projectId}/phases/${phaseId}/activities/${activityId}/submissions`,
    studentSubmissions: (
      projectId: string,
      phaseId: string,
      activityId: string
    ) =>
      `/v1/pbl/projects/${projectId}/phases/${phaseId}/activities/${activityId}/submissions/student`,
    feedback: (projectId: string, phaseId: string, activityId: string) =>
      `/v1/pbl/projects/${projectId}/phases/${phaseId}/activities/${activityId}/feedback`,
    scores: (projectId: string, phaseId: string, activityId: string) =>
      `/v1/pbl/projects/${projectId}/phases/${phaseId}/activities/${activityId}/scores`,
    languages: () => `/v1/pbl/languages`,
    distibutionsGroups: () => `/v1/distribution-groups`,
    curricularMetrics: (user: string) => `/v1/stats/my-metrics?actor=${user}`,
    teacherTrainingResources: () => `/v1/resources`,
    teacherTrainingResource: (resourceId: string) =>
      `/v1/resources/${resourceId}`,
  }

  async saveScores(
    project: Project,
    phase: Phase,
    activity: Activity,
    scores: Score[]
  ): Promise<Score[]> {
    const { data } = await this.axios.put<Score[]>(
      this.keys.scores(project.id, phase.id, activity.id),
      { scores }
    )

    const updatedScores = data.map(s => Score.fromData(s))
    await this.mutateScores(project, phase, activity, updatedScores)
    return updatedScores
  }

  async saveSubmission(
    project: Project,
    phase: Phase,
    activity: Activity,
    submission: Submission
  ): Promise<Submission> {
    const { data } = await this.axios.post(
      this.keys.submissions(project.id, phase.id, activity.id),
      submission
    )

    const newSubmission = ActionSubmission.fromData(data)
    await this.mutateSubmissions(project, phase, activity, submissions => {
      submissions.push(newSubmission)
      return submissions
    })
    return newSubmission
  }

  async saveFeedback(
    project: Project,
    phase: Phase,
    activity: Activity,
    feedback: Pick<Feedback, 'text' | 'studentId'>
  ): Promise<Feedback> {
    const { data } = await this.axios.post(
      this.keys.feedback(project.id, phase.id, activity.id),
      feedback
    )

    const newFeedback = Feedback.fromData(data)
    await this.mutateFeedback(project, phase, activity, [newFeedback])
    return newFeedback
  }

  async deleteSubmission(
    project: Project,
    phase: Phase,
    activity: Activity,
    submission: Submission
  ): Promise<void> {
    await this.axios.delete(
      this.keys.submission(project.id, phase.id, activity.id, submission._id)
    )
    await this.mutateSubmissions(project, phase, activity, submissions => {
      return submissions.filter(
        eachSubmission => eachSubmission._id !== submission._id
      )
    })
  }

  async saveProject(project: Project): Promise<Project> {
    const projectToSave = {
      title: project.title,
      language: project.language,
      description: project.description,
      coverUrl: project.coverUrl,
      level: project.level,
      subjects: project.subjects,
      type: project.type,
      hidden: project.hidden,
      schoolIds: project.schoolIds,
      jurisdictionIds: project.jurisdictionIds,
    }
    const { data } = await (project.exists()
      ? this.axios.put(this.keys.project(project), projectToSave)
      : this.axios.post(this.keys.projects(), projectToSave))
    const savedProject = Project.fromData(data)
    return savedProject
  }

  async savePerformanceLevel(
    project: Project,
    rubric: Rubric,
    performanceLevel: PerformanceLevel,
    newValue: string
  ): Promise<PerformanceLevel> {
    const isUpdate = performanceLevel.exists()
    const { data } = await this.axios.put(
      this.keys.performanceLevels(project, performanceLevel),
      { label: newValue }
    )
    const savedPerformanceLevel = PerformanceLevel.fromData(data)

    if (isUpdate) {
      rubric.updatePerformanceLevel(savedPerformanceLevel)
    }
    await this.mutateRubric(project, rubric)
    return savedPerformanceLevel
  }

  async saveDescriptor(
    project: Project,
    rubric: Rubric,
    descriptor: Descriptor,
    newValue: string
  ): Promise<Descriptor> {
    const isUpdate = descriptor.exists()
    const { data } = await this.axios.put(
      this.keys.descriptors(project, descriptor),
      { value: newValue }
    )
    const savedDescriptor = Descriptor.fromData(data)

    if (isUpdate) {
      rubric.updateDescriptor(savedDescriptor)
    }
    await this.mutateRubric(project, rubric)
    return savedDescriptor
  }

  async assignProject(
    project: Project,
    cohortsToAdd: string[],
    cohortsToRemove: string[]
  ): Promise<Project> {
    const projectToSave = {
      startDate: project.startDate,
      endDate: project.endDate,
      teacherIds: project.teacherIds,
      schoolIds: project.schoolIds,
      cohortsToAdd,
      cohortsToRemove,
    }
    const { data } = await this.axios.post(
      `${this.keys.project(project)}/assignments`,
      projectToSave
    )
    const savedProject = Project.fromData(data)
    return savedProject
  }

  async cloneProject(project: Project): Promise<Project> {
    delete project.jurisdictionIds
    const { data } = await this.axios.post(this.keys.cloneProject(project), {
      schoolIds: project.schoolIds,
    })
    const clonedProject = Project.fromData(data)
    await this.mutateProject(clonedProject)
    return clonedProject
  }

  async copyProject(
    project: Project,
    title: string,
    destination?: { jurisdictionIds?: string[]; schoolIds?: string[] }
  ): Promise<Project[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { data } = await this.axios.post<any[]>(
      this.keys.copyProject(project),
      {
        title,
        ...destination,
      }
    )
    const copiedProjects = data ? data.map(d => Project.fromData(d)) : []
    await this.mutateProject(project)
    return copiedProjects
  }

  async deleteProject(project: Project): Promise<void> {
    await this.axios.delete(this.keys.project(project))
  }

  async savePhase(project: Project, phase: Phase): Promise<Phase> {
    const isUpdate = phase.exists()

    const { data } = await (isUpdate
      ? this.axios.patch(this.keys.phase(project, phase), phase)
      : this.axios.post(this.keys.phases(project), phase))

    const savedPhase = Phase.fromData(data)

    if (isUpdate) {
      project.updatePhase(savedPhase)
    } else {
      project.addPhase(savedPhase)
    }

    await this.mutateProject(project)
    return savedPhase
  }

  async deletePhase(project: Project, phase: Phase): Promise<void> {
    await this.axios.delete(this.keys.phase(project, phase))
    project.removePhase(phase)
    await this.mutateProject(project)
  }

  async saveActivity(
    project: Project,
    phase: Phase,
    activity: Activity
  ): Promise<Activity> {
    const isUpdate = activity.exists()

    const { data } = await (isUpdate
      ? this.axios.put<Activity>(
          this.keys.activity(project, phase, activity),
          activity
        )
      : this.axios.post<Activity>(
          this.keys.activities(project, phase),
          activity
        ))

    const ActivitySubclass = activityTypes.get(data.type)!
    const savedActivity = ActivitySubclass.fromData(data)

    if (isUpdate) {
      phase.updateActivity(savedActivity)
    } else {
      phase.addActivity(savedActivity)
    }

    await this.mutateProject(project)
    return savedActivity
  }

  async saveItem(
    project: Project,
    phase: Phase,
    activity: ContentActivity,
    item: ContentItem
  ): Promise<Activity> {
    // se clona la actividad para no mutarla antes de haberla persistido
    const clonedActivity = cloneDeep(activity)
    if (item.exists()) {
      clonedActivity.updateItem(item)
    } else {
      clonedActivity.addItem(item)
    }
    return this.saveActivity(project, phase, clonedActivity as Activity)
  }

  async getSignedUrl(
    fileName: string,
    contentType: string,
    prefix: string
  ): Promise<UploadUrls> {
    const { data } = await this.axios.post<UploadUrls>(this.keys.uploads(), {
      filePath: fileName,
      contentType,
      prefix,
    })
    return data
  }

  async uploadFile(signedUrl: string, file: File): Promise<void> {
    await this.axios.put(signedUrl, file)
  }

  async reorderPhases(
    project: Project,
    phaseId: string,
    prevIndex: number,
    newIndex: number
  ): Promise<Project> {
    const clonedProject = cloneDeep(project)
    clonedProject.reorderPhases(prevIndex, newIndex)
    await this.mutateProject(clonedProject)
    try {
      await this.axios.put(this.keys.reorderPhases(project, phaseId), {
        newIndex,
      })
      return clonedProject
    } catch (e) {
      this.mutateProject(project)
      throw e
    }
  }

  async saveGroup(project: Project, group: Group): Promise<Group> {
    const isUpdate = group.exists()
    const { data } = await (isUpdate
      ? this.axios.patch(this.keys.group(project, group), {
          title: group.title,
        })
      : this.axios.post(this.keys.groups(project), {
          title: group.title,
        }))

    const updatedGroup = Group.fromData(data)
    const savedGroup = Group.fromData({
      ...updatedGroup,
      students: [...group.students],
    })
    const addGroup = (group: Group) => (groups: Group[]) => {
      groups.push(group)
      return groups
    }

    const updateGroup = (group: Group) => (groups: Group[]) => {
      const clonedGroups = cloneDeep(groups)
      const updatedIndex = clonedGroups.findIndex(
        g => Group.fromData(g).id === group.id
      )
      clonedGroups.splice(updatedIndex, 1, group)
      return clonedGroups
    }

    await this.mutateGroups(
      project,
      isUpdate ? updateGroup(savedGroup) : addGroup(savedGroup)
    )

    return updatedGroup
  }

  async reorderActivities(
    project: Project,
    phaseId: string,
    activityId: string,
    newPhaseId: string,
    prevIndex: number,
    newIndex: number
  ): Promise<Project> {
    const clonedProject = cloneDeep(project)
    clonedProject.reorderActivities(prevIndex, newIndex, phaseId, newPhaseId)
    await this.mutateProject(clonedProject)
    try {
      await this.axios.put(
        this.keys.reorderActivities(project, phaseId, activityId),
        { newIndex, newPhase: newPhaseId !== phaseId ? newPhaseId : null }
      )
      return clonedProject
    } catch (e) {
      this.mutateProject(project)
      throw e
    }
  }

  async saveStudents(
    project: Project,
    groups: Group[],
    originalGroups: Group[]
  ): Promise<Group[]> {
    try {
      const [, ...groupsWithoutGeneral] = groups
      const dataGroups = groupsWithoutGeneral.map(group => ({
        groupId: group.id,
        studentIds: map('userId', group.students),
      }))
      const { data } = await this.axios.put<Group[]>(
        this.keys.groups(project),
        {
          groups: dataGroups,
        }
      )

      const updatedGroups = data.map(g => Group.fromData(g))
      return updatedGroups
    } catch (e) {
      this.mutateGroups(project, originalGroups)
      throw e
    }
  }

  async reorderStudents(project: Project, groups: Group[]): Promise<Group[]> {
    await this.mutateGroups(project, groups)
    return groups
  }

  async deleteActivity(
    project: Project,
    phase: Phase,
    activity: Activity
  ): Promise<void> {
    await this.axios.delete(this.keys.activity(project, phase, activity))
    phase.removeActivity(activity)
    this.mutateProject(project)
  }

  async saveCriteria(
    criteria: Criterion,
    defaultDescriptors: Descriptor[]
  ): Promise<Criterion> {
    const { data } = await this.axios.post(this.keys.criteria(), {
      ...criteria,
      defaultDescriptors,
    })
    const savedCriteria = Criterion.fromData(data)
    return savedCriteria
  }

  async updateCriteria(
    criteria: Criterion,
    project: Project,
    rubric: Rubric
  ): Promise<Criterion> {
    const clonedRubric = cloneDeep(rubric)
    try {
      const { data } = await this.axios.put(
        this.keys.updateCriteria(criteria._id),
        criteria
      )
      const updatedCriteria = Criterion.fromData(data)

      const updatedCriteriaIndex = clonedRubric.criteria.findIndex(
        c => c._id === updatedCriteria._id
      )
      clonedRubric.criteria[updatedCriteriaIndex] = updatedCriteria

      await this.mutateRubric(project, clonedRubric)
      return updatedCriteria
    } catch (e) {
      this.mutateRubric(project, rubric)
      throw e
    }
  }

  async assignCriteria(
    project: Project,
    criteriaIds: Criterion['id'][],
    rubric: Rubric
  ): Promise<Rubric> {
    const clonedRubric = cloneDeep(rubric)
    try {
      const { data } = await this.axios.post(
        this.keys.assignCriteria(project),
        {
          criteriaIds,
        }
      )
      const rubricData = Rubric.fromData(data)

      rubricData.descriptors.map(c =>
        clonedRubric.descriptors.push(Descriptor.fromData(c))
      )
      rubricData.criteria.map(c =>
        clonedRubric.criteria.push(Criterion.fromData(c))
      )
      await this.mutateRubric(project, clonedRubric)
      return clonedRubric
    } catch (e) {
      this.mutateRubric(project, rubric)
      throw e
    }
  }

  async deleteCriterion(project: Project, criterionId: string) {
    const { data } = await this.axios.delete(
      this.keys.criterion(project, criterionId)
    )
    const deleteCriterion = (criterionId: string) => (rubric: Rubric) => {
      const clonedRubric = cloneDeep(Rubric.fromData(rubric))
      clonedRubric.deleteCriterion(criterionId)
      return clonedRubric
    }
    await this.mutateRubric(project, deleteCriterion(criterionId))
    return Rubric.fromData(data)
  }

  async deleteCriterionFromActivity(
    project: Project,
    phase: Phase,
    activity: Activity,
    criterionId: string
  ): Promise<Activity> {
    const { data } = await this.axios.delete<Activity>(
      this.keys.deleteCriterionFromActivity(
        project._id,
        phase._id,
        activity._id,
        criterionId
      )
    )
    const ActivitySubclass = activityTypes.get(data.type)!
    const updatedActivity = ActivitySubclass.fromData(data)
    const removeScores = (criterionId: string) => (scores: Score[]) => {
      return scores.filter(s => s.criterion !== criterionId)
    }
    this.mutateScores(project, phase, activity, removeScores(criterionId))

    return updatedActivity
  }

  async deleteGroup(project: Project, group: Group): Promise<Group[]> {
    const { data } = await this.axios.delete<Group[]>(
      this.keys.group(project, group)
    )

    const removeGroup = (group: Group) => (groups: Group[]) => {
      const clonedGroups = cloneDeep(groups)
      const groupIndex = clonedGroups.findIndex(
        g => Group.fromData(g).id === group.id
      )
      clonedGroups.splice(groupIndex, 1)
      const generalGroup = clonedGroups.find(g =>
        Group.fromData(g).isGeneralGroup()
      )!
      generalGroup.students.push(...group.students)
      return clonedGroups
    }

    this.mutateGroups(project, removeGroup(group))
    const groups = data.map(g => Group.fromData(g))
    return groups
  }

  private mutateProject(project: Project) {
    return this.mutate(this.keys.project(project), project, false)
  }

  private mutateScores(
    project: Project,
    phase: Phase,
    activity: Activity,
    scores: Score[] | ((scores: Score[]) => Score[])
  ) {
    return this.mutate(
      this.keys.scores(project.id, phase.id, activity.id),
      scores,
      false
    )
  }

  private mutateFeedback(
    project: Project,
    phase: Phase,
    activity: Activity,
    feedback: Feedback[]
  ) {
    return this.mutate(
      this.keys.feedback(project.id, phase.id, activity.id),
      feedback,
      false
    )
  }

  private mutateSubmissions(
    project: Project,
    phase: Phase,
    activity: Activity,
    submission: Submission | ((submissions: Submission[]) => Submission[])
  ) {
    return this.mutate(
      this.keys.studentSubmissions(project.id, phase.id, activity.id),
      submission,
      false
    )
  }

  private mutateGroups(
    project: Project,
    groups: Group[] | ((groups: Group[]) => Group[])
  ) {
    return this.mutate(this.keys.groups(project), groups, false)
  }

  private mutateRubric(
    project: Project,
    rubric: Rubric | ((rubric: Rubric) => Rubric)
  ) {
    return this.mutate(this.keys.rubric(project), rubric, false)
  }

  private mutateProjects(
    projects: Project[] | ((projects: Project[]) => Project[])
  ) {
    return this.mutate(this.keys.projects(), projects, false)
  }

  async updateProfileAvatar(file: File): Promise<string> {
    const { data: avatarUrls }: { data: AvatarUrls } = await this.getAvatarUrls(
      file
    )
    await this.submitAvatar(avatarUrls.url, file)

    const { data: userInfo } = await this.axios.put<UserPicture>(
      this.keys.profile(),
      {
        userinfo: { picture: avatarUrls.publicUrl },
      },
      {
        useOwn: true,
      }
    )
    return userInfo.picture
  }

  private submitAvatar(
    avatarUrl: string,
    img: File
  ): Promise<AxiosResponse<unknown>> {
    return this.axios.put(avatarUrl, img)
  }

  private getAvatarUrls(file: File): Promise<AxiosResponse<AvatarUrls>> {
    const body = {
      filename: file.name,
      contentType: file.type,
    }
    return this.axios.post(this.keys.profilePicture(), body)
  }
}
