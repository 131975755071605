import { useLevels, useSubjects } from '@hooks/fetch/institutionsServiceData'
import { useFetchedLanguages } from '@hooks/fetch/languages'

export const useFilterFormData = () => {
  const { languages, loading: languagesLoading } = useFetchedLanguages()
  const { asList: subjects, loading: subjectsLoading } = useSubjects()
  const { asList: levels, loading: levelsLoading } = useLevels()

  return {
    languages,
    languagesLoading,
    subjects,
    subjectsLoading,
    levels,
    levelsLoading,
  }
}
