import React from 'react'

export const useContextMenu = () => {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null)
  const openContextMenu = (event: React.SyntheticEvent<HTMLElement>) => {
    event.stopPropagation()
    setMenuAnchor(event.currentTarget)
  }
  const closeContextMenu = () => setMenuAnchor(null)
  const open = !!menuAnchor

  return {
    openContextMenu,
    closeContextMenu,
    menuAnchor,
    open,
  }
}
