import { Box, makeStyles, Typography, Popper } from '@material-ui/core'
import { NewFeatureArrow } from '../Icons/NewFeatureArrow'
import { ticmasColor } from '@/skins/default'

const useStyles = makeStyles({
  container: {
    backgroundColor: ticmasColor.blue,
    borderRadius: '8px',
    padding: '10px 18px',
    marginTop: '10px',
    opacity: '0.83',
    cursor: 'pointer',
    zIndex: 100,
  },
  arrow: {
    width: '50px',
    height: '50px',
    marginLeft: '8px',
  },
  text: {
    color: '#fff',
    fontSize: '18px',
  },
  textContainer: {
    maxWidth: '250px',
  },
})

interface Props {
  text: string
  hide?: boolean
  anchorEl: HTMLElement | null
  onClick: () => void
}

export const NewFeatureTooltip: React.FC<Props> = ({
  text,
  hide,
  anchorEl,
  onClick,
}) => {
  const classes = useStyles()

  return (
    <Popper
      id="new-feature-tooltip"
      open={!hide}
      onClick={onClick}
      anchorEl={anchorEl}
      placement="bottom-end"
      className={classes.container}
    >
      <Box display="flex">
        <Box flexGrow={1} className={classes.textContainer}>
          <Typography variant="body1" className={classes.text}>
            {text}
          </Typography>
        </Box>
        <Box>
          <NewFeatureArrow className={classes.arrow} />
        </Box>
      </Box>
    </Popper>
  )
}

export default NewFeatureTooltip
