import React, { useState } from 'react'

export interface Welcome {
  skip: boolean
  setSkip(value: boolean): void
}

export const WelcomeContext = React.createContext<Welcome>(
  null as unknown as Welcome
)

const { Provider } = WelcomeContext

export const WelcomeProvider = props => {
  const [skip, setSkip] = useState(true)

  return (
    <Provider
      value={{
        skip,
        setSkip,
      }}
      {...props}
    />
  )
}
