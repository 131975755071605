import { Box } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import { LibraryBooksOutlined } from '@mui/icons-material'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AllProjectsIcon } from '../Icons/AllProjectsIcon'
import { TeacherTrainingIcon } from '../Icons/TeacherTrainingIcon'
import { useUserInfo } from '@/lib/auth'
import { useIsMobile } from '@hooks/responsive'
import { Role } from '@models/Role'

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(5),
  },
  itemLabel: {
    fontSize: '1.125rem',
    color: '#2D2D2D',
    fontWeight: 400,
    lineHeight: '1.31rem',
  },
  active: {
    borderRadius: '1.5rem',
    backgroundColor: '#0000661A',
  },
  iconColor: {
    color: '#000000',
  },
}))

type Anchor = 'top' | 'left' | 'bottom' | 'right'

export const BurgerMenu = ({ toggleBurgerMenu, burgerMenuOpen }) => {
  const { t } = useTranslation('navbar')

  const user = useUserInfo()

  const classes = useStyles()
  const isMobile = useIsMobile()
  const router = useRouter()
  const anchor: Anchor = 'left'
  const handleClick = (path: string) => () => {
    router.push(path)
  }

  const itemsList = useMemo(
    () => [
      user.hasSomeRole([Role.TEACHER, Role.STUDENT])
        ? {
            title: t('burger.my-projects'),
            icon: <AllProjectsIcon />,
            path: '/mis-proyectos',
            disabled: false,
          }
        : {},
      user.hasSomeRole([Role.TEACHER, Role.CONTENT_CREATOR])
        ? {
            title: t('burger.library'),
            icon: <LibraryBooksOutlined />,
            path: '/biblioteca-abp',
            disabled: false,
          }
        : {},
      user.hasRole(Role.TEACHER)
        ? {
            title: t('burger.teacher-training'),
            icon: <TeacherTrainingIcon />,
            path: '/formacion-docente',
            disabled: false,
          }
        : {},
    ],
    [user, t]
  )

  const list = () => (
    <div
      role="presentation"
      onClick={toggleBurgerMenu(false)}
      onKeyDown={toggleBurgerMenu(false)}
    >
      <List>
        {itemsList.map(({ title, icon, path, disabled }) => (
          <ListItem
            className={`${classes.listItem} ${
              router.pathname === path ? classes.active : ''
            }`}
            button
            key={title}
            onClick={handleClick(path ?? '')}
            disabled={disabled ?? true}
          >
            <ListItemIcon className={classes.iconColor}>{icon}</ListItemIcon>
            <ListItemText
              disableTypography={true}
              className={`${classes.itemLabel} `}
              primary={title}
            />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <Drawer
      anchor={anchor}
      open={burgerMenuOpen}
      onClose={toggleBurgerMenu(false)}
    >
      <Box pt={isMobile ? 4.5 : 3}>{list()}</Box>
    </Drawer>
  )
}
