import styled from '@emotion/styled'
import { Box, Chip } from '@mui/material'
import { FC } from 'react'

interface ChipProps {
  label: string
  color?: string
  bgColor?: string
}

const ChipStyled = styled(Chip)`
  font-size: 12px;
  font-weight: 500;
  padding: 0 8px;
`

export const CardChip: FC<ChipProps> = ({ label, color, bgColor }) => {
  return (
    <Box paddingRight="8px">
      <ChipStyled
        style={{ color: `${color}`, backgroundColor: `${bgColor}` }}
        size="small"
        label={label}
      />
    </Box>
  )
}
