import { ActivityType, BaseActivity } from './Activity'
import typeTranslations from '@hooks/fetch/activities'

export class ExamActivity extends BaseActivity {
  type = ActivityType.EXAM as const

  description?: string

  criteriaIds: string[] = []

  getTypeName() {
    return typeTranslations[ActivityType.EXAM].name
  }

  getTypeDescription() {
    return typeTranslations[ActivityType.EXAM].description
  }
}
