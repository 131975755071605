import { ActivityType, BaseActivity } from './Activity'
import typeTranslations from '@hooks/fetch/activities'

export class QuizActivity extends BaseActivity {
  type = ActivityType.QUIZ as const

  description?: string

  getTypeName() {
    return typeTranslations[ActivityType.QUIZ].name
  }

  getTypeDescription() {
    return typeTranslations[ActivityType.QUIZ].description
  }
}
