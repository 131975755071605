import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import { useKeycloak } from '@react-keycloak/ssr'
import { Trans, useTranslation } from 'react-i18next'
import { useUserInfo } from '@/lib/auth'
import { useConfiguration } from '@/lib/configuration'
import EmptyState from '@common/EmptyState'
import { WhatsAppIcon } from '@components/common/Icons/WhatsAppIcon'
import { useIsMobile } from '@hooks/responsive'
import { Project } from '@models/Project'

const useStyles = makeStyles((theme: Theme) => ({
  highlightedText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  alignedText: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  fontWeight500: {
    fontWeight: 500,
  },
  contactText: { textAlign: 'center' },
  icon: { verticalAlign: 'top' },
}))

interface Props {
  project?: Project
}
interface SubtitleProps {
  email: string
  className: string
  isMobile?: boolean
}

const Subtitle: React.FC<SubtitleProps> = ({
  email,
  className,
  isMobile = false,
}) => {
  return (
    <Box mt={2}>
      <Typography className={className} variant="body1" color="textSecondary">
        <Trans
          ns="common"
          i18nKey={
            isMobile ? 'empty-user.subtitle.xsDown' : 'empty-user.subtitle.smUp'
          }
          components={{ b: <b /> }}
          values={{ email }}
        />
      </Typography>
    </Box>
  )
}

const EmptyUser: React.FC<Props> = () => {
  const { t } = useTranslation(['common'])
  const user = useUserInfo()
  const classes = useStyles()
  const [keycloak] = useKeycloak()
  const isMobile = useIsMobile()
  const { helpEmail, helpWhatsapp } = useConfiguration()

  const logOut = () => {
    const { origin } = window.location

    keycloak.logout({
      redirectUri: `${origin}`,
    })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={'center'}
      height={'100vh'}
    >
      <EmptyState
        title={
          <Typography className={classes.fontWeight500} variant="inherit">
            {t('common:empty-user.unloged')}
          </Typography>
        }
        subtitle={
          <Subtitle email={user?.email} className={classes.alignedText} />
        }
        subtitleXsDown={
          <Subtitle
            email={user?.email}
            className={classes.alignedText}
            isMobile={true}
          />
        }
        titleSize="lg"
        icon={PersonSearchIcon}
        iconSize="lg"
      />
      <Box mt={1} alignItems="center" className={classes.contactText}>
        <Trans
          ns="common"
          i18nKey={
            isMobile ? 'empty-user.contact.xsDown' : 'empty-user.contact.smUp'
          }
          components={{
            strong: <strong />,
            a: (
              <a
                href={`https://api.whatsapp.com/send/?phone=${helpWhatsapp}`}
                target="_blank"
                rel="noreferrer"
              />
            ),
            WhatsAppIcon: <WhatsAppIcon className={classes.icon} />,
            br: <br />,
          }}
          values={{ mail: helpEmail, whats: helpWhatsapp }}
        />
      </Box>
      <Box mt={2}>
        <Button color="primary" variant="contained" onClick={logOut}>
          {t('common:empty-user.logout')}
        </Button>
      </Box>
    </Box>
  )
}

export default EmptyUser
