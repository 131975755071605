import { Grid } from '@material-ui/core'
import { Content } from './hooks'
import { HomeCard } from '@components/common/HomeCard'

interface Props {
  contents: Content[]
  onClick: (content: Content) => void
}

export const ContentList: React.FC<Props> = ({ contents, onClick }) => {
  return (
    <Grid container spacing={2} style={{ minHeight: '370px' }}>
      {contents.map((content: Content) => (
        <Grid item key={content._id} xs={12} sm={6} md={4} lg={3}>
          <HomeCard
            title={content.title}
            coverUrl={content.coverUrl}
            startDate={content.startDate}
            endDate={content.endDate}
            chips={content.subject ? [content.subject] : []}
            owner={content.owner}
            onClick={() => {
              onClick(content)
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}
