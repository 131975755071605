import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'
import React from 'react'
import type { FC } from 'react'

export const TeacherSCLIcon: FC<SvgIconProps> = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75235 1.35303H31.2477C32.38 1.35303 33.2979 2.2374 33.2979 3.32832V18.9954C33.2979 20.0863 32.38 20.9707 31.2477 20.9707H18.0121H3.75235C2.62004 20.9707 1.70213 20.0863 1.70213 18.9954V3.32832C1.70213 2.2374 2.62004 1.35303 3.75235 1.35303Z"
        stroke="#7158D8"
        strokeWidth="2"
        fill="none"
      />
      <path
        d="M22.766 0H12.234C11.8463 0 11.5319 0.302866 11.5319 0.676471C11.5319 1.05007 11.8463 1.35294 12.234 1.35294H22.766C23.1537 1.35294 23.4681 1.05007 23.4681 0.676471C23.4681 0.302866 23.1537 0 22.766 0Z"
        fill="#7158D8"
      />
      <path
        d="M19.0798 20.9707H15.9202C15.7263 20.9707 15.5692 20.8193 15.5692 20.6325C15.5692 20.4457 15.7263 20.2942 15.9202 20.2942H19.0798C19.2737 20.2942 19.4309 20.4457 19.4309 20.6325C19.4309 20.8193 19.2737 20.9707 19.0798 20.9707Z"
        fill="#7158D8"
      />
      <path
        d="M14.8881 20.9707H20.2104H20.7338C21.0363 20.9707 21.2815 21.2069 21.2815 21.4984V21.997C21.2815 22.2884 21.0363 22.5247 20.7338 22.5247H17.5852H14.8881C14.5856 22.5247 14.3404 22.2884 14.3404 21.997V21.4984C14.3404 21.2069 14.5856 20.9707 14.8881 20.9707Z"
        fill="#7158D8"
      />
    </SvgIcon>
  )
}
