import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { LocaleObject } from 'yup/lib/locale'
import { TeacherFilters, useImpersonation } from '../../contexts/impersonation'
import { customErrorMessages } from '@/lib/customErrorMessages'

yup.setLocale(customErrorMessages as LocaleObject)

const schema = yup.object().shape({
  query: yup.string(),
})

export const useTeacherFilterForm = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { setFilters } = useImpersonation()

  const hookform = useForm<TeacherFilters>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const search = hookform.watch('query')
  const clearFilters = () => {
    if (search) {
      hookform.setValue('query', '')
    }
  }

  const onSubmit = useCallback(
    async (data: TeacherFilters) => {
      try {
        setFilters(data)
      } catch (e) {
        enqueueSnackbar('Ocurrió un error, por favor intentá nuevamente.')
      }
    },
    [enqueueSnackbar, setFilters]
  )

  const submitHandler = hookform.handleSubmit(onSubmit)

  return {
    hookform,
    search,
    submitHandler,
    clearFilters,
  }
}
