import { cabaSkin } from '@/skins/caba'
import { defaultSkin } from '@/skins/default'
import { lomasSkin } from '@/skins/lomas'
import { saltaSkin } from '@/skins/salta'
import { Skin } from '@/skins/types'

type CustomSkin = { [domain: string]: Skin }

const skinsByDomain: CustomSkin = {
  'abp.bue.edu.ar': cabaSkin,
  'abp.ted.lomasdezamora.gov.ar': lomasSkin,
  'abp.miescuela.edusalta.gov.ar': saltaSkin,
}

export const getSkin = () => {
  const domain = window.location.hostname
  return skinsByDomain[domain] || defaultSkin
}
