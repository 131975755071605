import { Grid, Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { GridProps } from '@material-ui/core/Grid'
import { Hidden } from '@mui/material'

// import { SvgIconComponent } from '@mui/icons-material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon'
import clsx from 'clsx'
import { ReactNode } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
  xxsIcon: {
    fontSize: '24px !important',
  },
  xsIcon: {
    fontSize: '40px !important',
  },
  smIcon: {
    fontSize: '56px !important',
  },
  mdIcon: {
    fontSize: '72px !important',
  },
  lgIcon: {
    fontSize: '88px !important',
  },
  center: {
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    letterSpacing: '0em',
    lineHeight: '32px',
    fontWeight: 600,
    marginBottom: '8px',
  },
  subtitle: {
    color: '#747579',
    fontWeight: 400,
    lineHeight: '24px',
  },
  xxsTitle: {
    fontSize: '12px',
    color: theme.palette.grey[500],
  },
  xsTitle: {
    fontSize: '14px',
    color: theme.palette.grey[600],
    fontWeight: 'bold',
  },
  smTitle: {
    fontSize: '16px',
  },
  mdTitle: {
    fontSize: '20px',
  },
  lgTitle: {
    fontSize: '24px',
  },
  xxsSubtitle: {
    fontSize: '12px',
    color: theme.palette.grey[600],
  },
  xsSubtitle: {
    fontSize: '12px',
  },
  smSubtitle: {
    fontSize: '14px',
  },
  mdSubtitle: {
    fontSize: '16px',
  },
  lgSubtitle: {
    fontSize: '18px',
  },
  mt1: {
    marginTop: '4px',
  },
}))

type Size = 'lg' | 'md' | 'sm' | 'xs' | 'xxs'

interface Props {
  title: string | React.ReactNode
  titleXsDown?: string | React.ReactNode
  titleSize?: Size
  subtitle?: string | React.ReactNode
  subtitleXsDown?: string | React.ReactNode
  subtitleSize?: Size
  // TODO: Switch to SvgIconComponent when @mui/icons-material SVGIcon types are fixed
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  iconSize?: Size
  customIcon?: ReactNode
  children?: ReactNode
  pt?: number
  titleGridBreakpoints?: GridProps
}

export const EmptyState: React.FC<Props> = ({
  title,
  titleXsDown,
  titleSize = 'md',
  subtitle,
  subtitleXsDown,
  subtitleSize = 'md',
  icon,
  iconSize = 'md',
  customIcon,
  children,
  pt = 0,
  titleGridBreakpoints = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
}) => {
  const classes = useStyles()
  const Icon = icon

  return (
    <Box display="block" style={{ width: '100%' }} pt={pt}>
      <Grid container item xs={12}>
        {Icon && (
          <Grid item xs={12} className={classes.center}>
            <Icon className={clsx(classes.icon, classes[`${iconSize}Icon`])} />
          </Grid>
        )}
        {customIcon && (
          <Grid item xs={12} className={classes.center}>
            {customIcon}
          </Grid>
        )}
        <Grid item xs={12} className={clsx(classes.center, classes.mt1)}>
          <Box display="flex" justifyContent="center">
            <Box maxWidth="550px">
              <Typography
                variant="body1"
                className={clsx(classes.text, classes[`${titleSize}Title`])}
              >
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item {...titleGridBreakpoints}>
                    <Hidden xsDown>{title}</Hidden>
                    <Hidden smUp>{titleXsDown || title}</Hidden>
                  </Grid>
                </Grid>
              </Typography>
              {subtitle && (
                <Typography
                  className={clsx(
                    classes.subtitle,
                    classes[`${subtitleSize}Subtitle`]
                  )}
                >
                  <Hidden xsDown>{subtitle}</Hidden>
                  <Hidden smUp>{subtitleXsDown || subtitle}</Hidden>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        {children && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={2}>
              {children}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default EmptyState
