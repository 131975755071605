import { createSkin } from './skinBuilder'

export const saltaSkin = createSkin(
  {
    primaryColor: '#1D6EA9',
    // Se suman 6 unidades a cada valor para crear una sombra mas clara
    primaryHoverColor: '#2374AF',
    secondaryColor: '#4097BC',
    primaryBtnTextColor: '#FFF',
  },
  {
    logo: '/skins/salta/logo-salta.svg',
    favicon: '/skins/salta/favicon-salta.ico',
  }
)
