import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, makeStyles, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useMutationSetUserPassword } from './hook'
import { sentry } from '@/lib/sentry'
import { useIsMobile } from '@hooks/responsive'

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}))

const schema = yup.object().shape({
  password: yup.string().required('La contraseña es obligatoria'),
})

interface Props {
  description: string
  isOpen: boolean
  onCancel(): void
  descriptionTitle: string
}

export const ChangePasswordModal = ({
  isOpen,
  description,
  onCancel,
  descriptionTitle,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useIsMobile()
  const classes = useStyles()
  const { t } = useTranslation(['common', 'content-activity'])
  const { setUserPassword } = useMutationSetUserPassword()

  const { handleSubmit, register, watch } = useForm({
    resolver: yupResolver(schema),
  })

  const watchPassword = watch('password')

  const handleChangePassword = async (data: { password: string }) => {
    try {
      await setUserPassword({ password: data.password })
      enqueueSnackbar('Se ha cambiado la contraseña correctamente')
      onCancel()
    } catch (error) {
      enqueueSnackbar('Ha ocurrido un error al modificar la contraseña')
      sentry.addBreadcrumb({
        category: 'change-password',
        message: 'Ha ocurrido un error al modificar la contraseña',
        level: 'error',
      })
      sentry.captureException(error)
    }
  }

  return (
    <Dialog open={isOpen}>
      <Box px={isMobile ? 2 : 3} pb={3} pt={isMobile ? 5.5 : 6.5}>
        <form onSubmit={handleSubmit(handleChangePassword)}>
          <DialogTitle id="alert-dialog-title">{descriptionTitle}</DialogTitle>
          <Box pb={isMobile ? 2.5 : 1}>
            <DialogContent>
              <Box pt={isMobile ? 1.5 : 1.625} pb={isMobile ? 2.5 : 2}>
                <DialogContentText id="alert-dialog-description">
                  {description}
                </DialogContentText>
              </Box>
              <Box py={1}>
                <TextField
                  required
                  name="password"
                  fullWidth
                  className={classes.marginBottom}
                  type="password"
                  inputRef={register}
                />
              </Box>
              <DialogContentText className="italic">
                {t('common:formulario.campos-obligatorios')}
              </DialogContentText>
            </DialogContent>
          </Box>
          <DialogActions>
            <Button fullWidth={isMobile} onClick={onCancel}>
              {t('common:acciones.cancelar')}
            </Button>
            <Button
              fullWidth={isMobile}
              type="submit"
              color="primary"
              autoFocus
              variant="contained"
              disabled={!watchPassword || watchPassword === ''}
            >
              {t('common:acciones.confirmar')}
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  )
}

export default ChangePasswordModal
