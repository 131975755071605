import { BaseModel } from './BaseModel'

export class Subject extends BaseModel {
  static getPath() {
    return '/v1/is/subjects?limit=1000'
  }

  id: string
  name: string
}
