// import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'

// import { Box, TextField, Checkbox, Chip } from '@material-ui/core'
// import { compact } from 'lodash'
import { useForm } from 'react-hook-form'
// import { useTranslation } from 'react-i18next'

import * as yup from 'yup'

// import { useInstitutionContext } from '@/lib/institution-context'

// import { Institution } from '@models/Institution'

// import { AutocompleteSelectField } from '../../AutocompleteSelectField'

import { DuplicateBaseDialog } from './DuplicateBaseDialog'

import {
  useDuplicateForm,
  DuplicateFormValues,
  // ProjectDuplicateValues,
} from './hooks'
import { customErrorMessages } from '@/lib/customErrorMessages'

interface Props {
  defaultTitle: string
  // Uncomment and pass down options to selector
  // options: Institution[]
  onDuplicate: (
    // Switch to ProjectDuplicateValues
    values: DuplicateFormValues
  ) => Promise<void>
  open: boolean
  onClose: (event) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
yup.setLocale(customErrorMessages as any)

export const DuplicateProjectDialog: React.FC<Props> = ({
  defaultTitle,
  // options,
  onDuplicate,
  ...dialogProps
}) => {
  // const { selectedInstitution } = useInstitutionContext()
  // const { t } = useTranslation(['common', 'home'])

  // Uncomment new schema when feature is made available for teachers
  const schema = yup.object().shape({
    title: yup.string().required(),
    /* 
    schoolIds: yup.array().of(yup.string()).min(1).required(),
   */
  })

  const hookform = useForm({
    defaultValues: {
      title: defaultTitle,
      // Uncomment institution default value (IDEM)
      // schoolIds: compact([selectedInstitution?.id]),
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  // const { errors, control } = hookform
  const { submitHandler } = useDuplicateForm(hookform, onDuplicate)

  return (
    <DuplicateBaseDialog
      hookform={hookform}
      onSubmit={submitHandler}
      {...dialogProps}
    >
      {
        // Uncomment selector and fill in translations (IDEM)
      }
      {/* <Box pb={3}>
        <AutocompleteSelectField<Institution>
          multiple
          limitTags={3}
          disableCloseOnSelect
          name="schoolIds"
          control={control}
          options={options}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          renderInput={params => (
            <TextField
              {...params}
              label={t('home:proyecto.duplicate-selector.institutions')}
            />
          )}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.name}
            </>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={option.id}
                size="small"
                style={{ maxWidth: 120 }}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          error={!!errors.schoolIds}
          helperText={
            errors.schoolIds && (
              <ErrorMessage
                errors={errors}
                name="schoolIds"
                render={() => (
                  <span>{t('common:formulario.campo-requerido')}</span>
                )}
              />
            )
          }
          size="small"
          clearOnBlur
          fullWidth
          noOptionsText={t(
            'home:proyecto.duplicate-selector.empty-institutions'
          )}
        />
      </Box> */}
    </DuplicateBaseDialog>
  )
}
