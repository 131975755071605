import {
  Box,
  Typography,
  DialogTitle,
  Link,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BidiIcon } from '../Icons/BidiIcon'
import { Loader } from '../Loader'
import { StatusModalLayout } from './StatusModalLayout'
import { AnalyticsAction, logEvent } from '@/lib/analytics'
import { useBidiServiceIntegration } from '@hooks/fetch/institutionsServiceData'

const useStyles = makeStyles({
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  typography: {
    fontFamily: 'Roboto',
  },
})

export interface BidiModalProps {
  id: string
  open: boolean
  onClose: () => void
}

export const BidiModal: FC<BidiModalProps> = ({ id, open, onClose }) => {
  const { t } = useTranslation(['navbar'])
  const classes = useStyles()
  const { asString: url, loading, error } = useBidiServiceIntegration(id)

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} disableBackdropClick>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      {loading ? (
        <StatusModalLayout
          icon={<Loader marginTop="0" />}
          text={t('navbar:modal-bidi.loading-text')}
        />
      ) : error ? (
        <StatusModalLayout
          icon={<ErrorOutlineOutlinedIcon style={{ fontSize: '4rem' }} />}
          text={t('navbar:modal-bidi.error-text')}
        />
      ) : (
        <>
          <Box className={classes.iconContainer}>
            <BidiIcon style={{ fontSize: '4rem' }} />
          </Box>

          <Box px={9} pt={4} pb={5}>
            <DialogTitle>
              <Typography
                align="center"
                variant="h2"
                className={classes.typography}
              >
                <Link
                  href={url}
                  target="_blank"
                  onClick={() => logEvent(AnalyticsAction.OPEN_HIGHSCHOOL_BIDI)}
                >
                  {t('navbar:modal-bidi.link-text')}
                </Link>
              </Typography>
            </DialogTitle>
          </Box>
        </>
      )}
    </Dialog>
  )
}
