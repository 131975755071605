import { Box } from '@material-ui/core'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentList } from '../Curricular/ContentList'
import { ErrorEmptyState } from '../Curricular/ErrorEmptyState'
import { useContents, Content } from '../Curricular/hooks'
import { AnalyticsAction, logEvent } from '@/lib/analytics'
import { DocumentTitle } from '@common/DocumentTitle'
import EmptyState from '@common/EmptyState'
import { Loader } from '@common/Loader'
import { DistributionGroups } from '@models/DistributionGroups'

export const TabCurricular = () => {
  const { t } = useTranslation(['home', 'curricular'])
  const router = useRouter()
  const { contents, loading, error } = useContents()

  const handleClick = (content: Content) => {
    router.push(DistributionGroups.getContentRoute(content?._id))
  }

  useEffect(() => logEvent(AnalyticsAction.OPEN_CURRICULAR_CONTENT), [])

  return (
    <Box mt={2.5}>
      <DocumentTitle titles={[t('home:tabs.content')]} />
      {error ? (
        <ErrorEmptyState />
      ) : loading ? (
        <Box width="100%">
          <Loader />
        </Box>
      ) : contents?.length ? (
        <ContentList contents={contents} onClick={handleClick} />
      ) : (
        <EmptyState
          title={t('curricular:empty-state.no-content')}
          subtitle={t('curricular:empty-state.no-content-subtitle')}
        />
      )}
    </Box>
  )
}
