import { useSnackbar } from 'notistack'

import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { projectStoreContext } from '../../../contexts/projectStore'
import { AnalyticsAction, logEvent } from '@/lib/analytics'

import { Project } from '@models/Project'
import { Role } from '@models/Role'
import { User } from '@models/User'

export const useDeleteProject = (
  onDelete: (project: Project) => Promise<void>
) => {
  const { deleteProject } = useContext(projectStoreContext)

  const { t } = useTranslation(['home', 'common'])
  const { enqueueSnackbar } = useSnackbar()

  const confirmDelete = useCallback(
    async (project: Project) => {
      try {
        await deleteProject(project)
        await onDelete(project)
        enqueueSnackbar(t('home:snackbar.delete.success'))
      } catch (e) {
        enqueueSnackbar(t('common:snackbar.ocurrio-error'))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDelete, deleteProject, enqueueSnackbar]
  )
  return { confirmDelete }
}

export const useHiddenProject = (
  {
    project,
    currentUser,
  }: {
    project: Project
    currentUser: User
  },
  onHide: (project: Project) => Promise<void>
) => {
  const { saveProject } = useContext(projectStoreContext)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['home', 'common'])

  const getSnackbarTranslation = (role, context, hasClassroom) => {
    switch (role) {
      case 'content-creator': {
        switch (context) {
          case 'show':
            return t('home:snackbar.content-creator_show')
          case 'hide':
            return t('home:snackbar.content-creator_hide')
        }
        break
      }
      case 'teacher': {
        switch (context) {
          case 'show':
            return hasClassroom
              ? t('home:snackbar.teacher_classroom_show')
              : t('home:snackbar.teacher_show')
          case 'hide':
            return t('home:snackbar.teacher_hide')
        }
        break
      }
    }
  }

  const projectToSave = project.assign({ hidden: !project.hidden })

  const toggleProjectVisibility = useCallback(
    async (hasClassroom?: boolean) => {
      try {
        const projectSaved = await saveProject(projectToSave)
        await onHide(projectSaved)

        const context = project.hidden ? 'show' : 'hide'
        const role = currentUser.hasRole(Role.CONTENT_CREATOR)
          ? 'content-creator'
          : 'teacher'

        enqueueSnackbar(getSnackbarTranslation(role, context, hasClassroom))
      } catch (e) {
        enqueueSnackbar(t('common:snackbar.ocurrio-error'))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUser,
      enqueueSnackbar,
      onHide,
      project.hidden,
      projectToSave,
      saveProject,
    ]
  )

  return { toggleProjectVisibility }
}

export const useCopiedProject = (
  project: Project,
  onCopy: (project: Project, copiedProjects: Project[]) => Promise<void>
) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('home')

  const { copyProject: copyProjectStore } = useContext(projectStoreContext)

  const copyProject = useCallback(
    async (
      projectTitle: string,
      destination?: { jurisdictionIds?: string[]; schoolIds?: string[] }
    ): Promise<void> => {
      try {
        const copiedProjects = await copyProjectStore(
          project,
          projectTitle,
          destination
        )
        await onCopy(project, copiedProjects)

        enqueueSnackbar(t('snackbar.copy.success', { project: projectTitle }))

        logEvent(
          project.isTemplate()
            ? AnalyticsAction.COPY_TEMPLATE
            : AnalyticsAction.COPY_PROJECT
        )
      } catch (e) {
        enqueueSnackbar(t('snackbar.copy.error'))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copyProjectStore, enqueueSnackbar, onCopy, project]
  )

  return { copyProject }
}
