import { Avatar, Chip } from '@material-ui/core'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import React, { useCallback, useEffect, useState } from 'react'
import * as ReactHookForm from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { ticmasColor } from '@/skins/default'

type OptionId = string | number

interface UncontrolledAutocompleteSelectProps<T = unknown>
  extends Omit<
    AutocompleteProps<T, boolean, boolean, undefined>,
    'value' | 'onChange'
  > {
  options: T[]
  defaultValue?: T[]
  getOptionValue: (option: T) => OptionId
  value: OptionId[]
  onChange: (selectedIds: OptionId[]) => void
  classroomTagForIds?: string[]
}

const UncontrolledAutocompleteSelect = <T,>({
  options,
  getOptionValue,
  value = [],
  onChange,
  multiple,
  classroomTagForIds,
  ...props
}: UncontrolledAutocompleteSelectProps<T>) => {
  const classroomAvatarStyles = {
    width: 'fit-content',
    borderRadius: '10px',
    color: '#fff',
    background: ticmasColor.blue,
    padding: '5px',
    fontWeight: 'bold',
  } as React.CSSProperties

  const getInitialSelection = useCallback(() => {
    if (multiple)
      return options.filter(option => value.includes(getOptionValue(option)))

    return options.find(option => value.includes(getOptionValue(option)))
  }, [value, options, getOptionValue, multiple])

  const [currentSelection, setCurrentSelection] = useState(
    getInitialSelection()
  )

  useEffect(
    () => setCurrentSelection(getInitialSelection()),
    [getInitialSelection, options]
  )

  const handleChange = (e, selection) => {
    setCurrentSelection(selection)
    const selectionValue = multiple
      ? selection.map(getOptionValue)
      : getOptionValue(selection)
    onChange(selectionValue)
  }

  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      value={currentSelection}
      onChange={handleChange}
      renderTags={(selectedOption, getTagProps) => {
        return selectedOption.map((option, index) => {
          const isFromClassroom = classroomTagForIds?.find(
            id => id === option.id
          )
          return (
            <Chip
              label={option.name}
              size="small"
              key={index}
              avatar={
                isFromClassroom ? (
                  <Avatar style={classroomAvatarStyles}>Classroom</Avatar>
                ) : (
                  <></>
                )
              }
              {...getTagProps({ index })}
            />
          )
        })
      }}
      {...props}
    />
  )
}

export interface AutocompleteSelectProps<T = unknown>
  extends Omit<UncontrolledAutocompleteSelectProps<T>, 'value' | 'onChange'> {
  name: string
  control: ReactHookForm.Control
  multiple?: boolean
}
export const AutocompleteSelect = <T,>({
  name,
  control,
  multiple = true,
  ...props
}: AutocompleteSelectProps<T>) => (
  <Controller
    name={name}
    control={control}
    defaultValue={multiple ? [] : ''}
    render={({ ...controllerProps }) => (
      <UncontrolledAutocompleteSelect
        {...props}
        {...controllerProps}
        multiple={multiple}
      />
    )}
  />
)
