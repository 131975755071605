import { createSkin } from './skinBuilder'

export const lomasSkin = createSkin(
  {
    primaryColor: '#1D6EA9',
    // Se suman 6 unidades a cada valor para crear una sombra mas clara
    primaryHoverColor: '#2374AF',
    secondaryColor: '#4097BC',
    primaryBtnTextColor: '#FFF',
  },
  {
    logo: '/skins/lomas/logo-equipo.svg',
    favicon: '/skins/lomas/favicon-ted.ico',
  }
)
