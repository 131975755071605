import { logEvent } from '@amplitude/analytics-browser'
import { Checkbox, makeStyles, Box, FormControlLabel } from '@material-ui/core'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Control, Controller } from 'react-hook-form'
import { AnalyticsAction } from '@/lib/analytics'
import { Language } from '@models/Language'
import { Level } from '@models/Level'
import { Subject } from '@models/Subject'

interface Props {
  name: string
  items: Subject[] | Level[] | Language[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<Record<string, any>>
  selectedTab: string
}

const useStyles = makeStyles({
  tabPanelContent: {
    overflowY: 'auto',
    height: '200px',
    padding: '4px 0',
  },
  item: {
    borderBottom: '1px solid #EDEDED',
    padding: '4px 36px',
  },
})

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export const FilterTabPanel: React.FC<Props> = ({
  name,
  items,
  control,
  selectedTab,
}) => {
  const classes = useStyles()

  const handleFiltersChange = (field, e) => {
    if (field.value.includes(e.target.value)) {
      field.onChange(field.value.filter(item => item !== e.target.value))
    } else {
      field.onChange([...field.value, e.target.value])
    }
    logEvent(AnalyticsAction.APPLY_PROJECT_FILTERS, {
      tab: selectedTab,
    })
  }

  return (
    <Box
      className={classes.tabPanelContent}
      // We're using the "display" style here instead of using MUI's TabPanel component
      // because the default behavior of the TabPanel component destroys the content of each tab
      // when it switch to another.
      // This would cause the selected filters to be lost every time a different tab is selected.
      style={{ display: selectedTab === name ? 'block' : 'none' }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={field => (
          <>
            {items.map(option => (
              <Box key={option.id} className={classes.item}>
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value.some(value => value === option.id)}
                      value={option.id}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      onChange={e => handleFiltersChange(field, e)}
                    />
                  }
                  label={option.name}
                />
              </Box>
            ))}
          </>
        )}
      />
    </Box>
  )
}
