import { BaseModel } from './BaseModel'
import { Module } from './InstitutionModules'
import { Jurisdiction } from './Jurisdiction'

export class Institution extends BaseModel {
  static getPath() {
    return '/v1/is/institutions'
  }

  id: string
  name: string
  jurisdictionId: string
  jurisdiction?: Jurisdiction
  roles: string[]
  modules?: Module[]
}
