import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { Language } from '@models/Language'
import { Level } from '@models/Level'
import { Subject } from '@models/Subject'

export interface ProjectFilters {
  languages?: Language['id'][]
  levels?: Level['id'][]
  subjects?: Subject['id'][]
  title?: string
}

interface ProjectSearch {
  filters: ProjectFilters
  setFilters(filters: ProjectFilters): void
  clearFilters(): void
  shouldClearFilters: boolean
  setShouldClearFilters(shouldClear: boolean)
}

export const projectSearchContext = React.createContext<ProjectSearch>(
  null as unknown as ProjectSearch
)

const { Provider } = projectSearchContext

export const ProjectSearchProvider = props => {
  const [filters, setFilters] = useState<ProjectFilters>({})

  const clearFilters = useCallback(() => {
    setFilters({})
  }, [setFilters])

  const [shouldClearFilters, setShouldClearFilters] = useState(true)

  const router = useRouter()

  useEffect(() => {
    router.beforePopState(() => {
      setShouldClearFilters(false)
      return true
    })
  }, [router])

  return (
    <Provider
      value={{
        filters,
        setFilters,
        clearFilters,
        shouldClearFilters,
        setShouldClearFilters,
      }}
      {...props}
    />
  )
}
