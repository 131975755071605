import { useTranslation } from 'react-i18next'
import { LibraryProjects } from './LibraryProjects'
import { DocumentTitle } from '@common/DocumentTitle'

export const TabLibraryDisney = () => {
  const { t } = useTranslation(['home'])

  return (
    <>
      <DocumentTitle titles={[t('home:tabs.biblioteca-disney')]} />
      <LibraryProjects organization="DISNEY" />
    </>
  )
}
