import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CustomDialog } from '../CustomDialog'
import TeacherAvatar from '../TeacherAvatar'

import { useImpersonateTeacher, useTeacherExpand } from './hooks'
import { Teacher } from '@models/Teacher'

interface Props {
  teachers: Teacher[]
}

const useStyles = makeStyles(theme => ({
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  delete: {
    color: theme.palette.grey[600],
    fontSize: '0.875rem',
  },
}))

export const TeacherList: React.FC<Props> = ({ teachers }) => {
  const classes = useStyles()
  const { t } = useTranslation('home')

  const {
    confirmImpersonate,
    selectedTeacher: selected,
    impersonateDialogOpen,
    openImpersonateDialog,
    closeImpersonateDialog,
  } = useImpersonateTeacher()

  const { toggleExpand, teachersOpen } = useTeacherExpand()

  return (
    <>
      <List className={classes.list}>
        {teachers.map(teacher => {
          const { userId, givenName, familyName, email } = teacher
          return (
            <div key={userId}>
              <ListItem
                divider
                {...(email
                  ? {
                      button: true as true & undefined, //https://github.com/mui-org/material-ui/issues/14971
                      onClick: () => toggleExpand(userId),
                    }
                  : {})}
              >
                <ListItemAvatar>
                  <TeacherAvatar
                    givenName={givenName}
                    familyName={familyName}
                  />
                </ListItemAvatar>

                <ListItemText
                  id={userId}
                  primary={Teacher.fromData(teacher).name}
                />

                <ListItemSecondaryAction>
                  <Button
                    component="label"
                    startIcon={<AssignmentIndIcon color="action" />}
                    onClick={() => openImpersonateDialog(teacher)}
                  >
                    {t('tutor.ver-como-docente')}
                  </Button>
                  {!!email && (
                    <IconButton
                      edge="end"
                      aria-label="expand"
                      onClick={() => toggleExpand(userId)}
                    >
                      {teachersOpen.includes(userId) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>

              {!!email && (
                <Collapse
                  in={teachersOpen.includes(userId)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div">
                    <ListItem divider className={classes.nested}>
                      <ListItemText primary={email} />
                    </ListItem>
                  </List>
                </Collapse>
              )}
            </div>
          )
        })}
      </List>

      <CustomDialog
        title={t('tutor.ver-como-docente')}
        content={
          (
            <Typography className={classes.delete}>
              {t('tutor.ver-como-docente-confirmar', {
                docente: selected ? Teacher.fromData(selected)?.name : '',
              })}
            </Typography>
          ) as unknown as string
        }
        closeHandler={closeImpersonateDialog}
        actionHandler={() => {
          closeImpersonateDialog()
          confirmImpersonate(selected!)
        }}
        open={impersonateDialogOpen}
        actionLabel={t('tutor.impersonate')}
      />
    </>
  )
}
