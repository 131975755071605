import SvgIcon from '@material-ui/core/SvgIcon'

export const NewFeatureArrow = props => {
  return (
    <SvgIcon width="52" height="36" viewBox="0 0 52 36" fill="none" {...props}>
      <path
        d="M36.7142 20.4642C32.347 25.297 25.9311 29.3312 19.7991 31.4357C16.2975 32.5626 12.6697 33.2291 9.00822 33.4182C7.12886 33.5548 5.24517 33.5968 3.36435 33.544C2.80328 33.5384 0.57283 33.017 0.265028 33.4973C-0.769463 35.1129 7.49918 35.3401 8.39078 35.3014C12.1566 35.1454 15.9885 34.781 19.6364 33.6924C25.7555 31.8672 32.1782 28.6386 36.8409 24.2019C39.5578 21.6168 42.3054 19.0555 44.4286 15.9164C45.3233 14.593 46.0926 13.1906 46.7264 11.7277C47.1895 10.6905 47.5885 9.62686 47.9211 8.5427C48.1523 7.74222 48.0401 6.60195 48.3871 5.88068C49.341 6.90864 49.3768 8.84146 50.2806 9.97673C51.3589 11.3289 51.847 9.95322 51.677 8.81707C51.3281 7.01562 50.872 5.23797 50.3112 3.49349C49.8906 1.9953 49.7162 -0.0307163 47.7881 0.106742C46.1025 0.234681 44.4049 1.02496 42.818 1.49299C41.7447 1.80871 39.2522 1.94338 38.7887 3.19447C37.3433 7.10288 45.4628 2.88193 46.1383 2.83085C46.0724 3.4886 45.6729 4.16019 45.4938 4.80228C45.2375 5.73329 45.1144 6.6907 44.8521 7.62121C44.3451 9.39643 43.6647 11.1201 42.8207 12.7673C41.3017 15.6928 39.2311 18.3042 36.7241 20.456C35.1348 22.2144 37.0639 20.1684 36.7142 20.4642Z"
        fill="white"
      />
    </SvgIcon>
  )
}
