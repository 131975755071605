import { createTheme, ThemeOptions } from '@material-ui/core/styles'
import { CustomThemeOptions, Skin } from './types'

const defaultTheme = createTheme({})
const bodyFont = 'Roboto'

const defaultThemeOptions: CustomThemeOptions = {
  primaryColor: '#000066',
  primaryHoverColor: '#000077',
  secondaryColor: '#336f99',
  primaryBtnTextColor: '#000',
  headingsFont: 'Poppins',
}

const defaultSkinOptions: Omit<Skin, 'theme'> = {
  logo: '/skins/default/logo.svg',
  favicon: '/skins/default/favicon-ticmas.ico',
  welcomeImages: {
    newProject: '/skins/default/new-project.gif',
    library: '/skins/default/library.gif',
    projects: '/skins/default/projects.gif',
  },
  subhead: {
    background:
      'linear-gradient(90.02deg, #1E2442 0.02%, #1E2442 21.4%, #1E2442 51.92%, #1E2442 77.6%, #29BDEF 99.99%)',
    textColor: '#fff',
    paddingLeft: 3,
    paddingRight: 3,
    home: {
      tabIndicatorColor: '#fff',
      height: '106px',
    },
    rubrics: {
      height: '80px',
    },
  },
  projectDetail: {
    phaseBackgroundColor: '#1E2442',
    textColor: '#fff',
    iconColor: '#fff',
    buttonAddResourceBgColor: '#fff',
    buttonAddResourceTextColor: '#1E2442',
    buttonAddPhaseBgColor: '#1E2442',
    buttonAddPhaseTextColor: '#fff',
  },
}

export const createSkin = (
  themeOptions: Partial<CustomThemeOptions>,
  skinOptions: Partial<Omit<Skin, 'theme'>>
): Skin => {
  const {
    primaryColor,
    primaryHoverColor,
    secondaryColor,
    primaryBtnTextColor,
    headingsFont,
  } = Object.assign({}, defaultThemeOptions, themeOptions)

  const skin = Object.assign({}, defaultSkinOptions, skinOptions)

  const baseThemeOptions: ThemeOptions = {
    typography: {
      allVariants: {
        color: '#000',
        fontFamily: bodyFont,
      },
      body1: {
        fontSize: '14px',
        fontFamily: bodyFont,
      },
      body2: {
        fontSize: '12px',
        fontFamily: bodyFont,
      },
      button: {
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '15px',
      },
      h1: {
        fontFamily: headingsFont,
        fontSize: '20px',
        fontWeight: 500,
      },
      h2: {
        fontFamily: headingsFont,
        fontSize: '18px',
        fontWeight: 500,
      },
      subtitle1: {
        fontWeight: 700,
      },
    },
    palette: {
      grey: {
        '200': '#e3e4e8',
        '300': '#d8d8d8',
        '400': '#c4c4c4',
        '500': '#999999',
      },
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
    props: {
      MuiTextField: {
        color: 'secondary',
        variant: 'outlined',
      },
      MuiSelect: {
        color: 'secondary',
        variant: 'outlined',
      },
      MuiFormControl: {
        color: 'secondary',
        variant: 'outlined',
      },
      MuiCircularProgress: {
        color: 'secondary',
      },
      MuiTypography: {
        variantMapping: {
          subtitle1: 'p',
          subtitle2: 'p',
        },
      },
    },
    overrides: {
      MuiOutlinedInput: {
        input: {
          paddingTop: '14.5px',
          paddingBottom: '14.5px',
        },
      },
      MuiDialogContentText: {
        root: {
          fontSize: '14px',
          lineHeight: 'normal',
        },
      },
      MuiDialogTitle: {
        root: {
          padding: '0',
        },
      },
      MuiDialogContent: {
        root: {
          padding: '0',
          overflowY: 'unset',
          '&:first-child': {
            paddingTop: '0',
          },
        },
      },
      MuiDialogActions: {
        root: {
          padding: '0',
        },
      },
      MuiFab: {
        primary: {
          color: primaryBtnTextColor,
        },
      },
      MuiButton: {
        root: {
          padding: `${defaultTheme.spacing(1)}px ${defaultTheme.spacing(3)}px`,
        },
        containedPrimary: {
          color: primaryBtnTextColor,
          opacity: '1',
          boxShadow: 'none',
          backgroundColor: primaryColor,
          '&:hover': {
            backgroundColor: primaryHoverColor,
          },
          '&$disabled': {
            opacity: '0.6',
          },
        },
        outlinedPrimary: {
          color: primaryColor,
          borderColor: primaryColor,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          textTransform: 'capitalize',
        },
        daysHeader: {
          textTransform: 'capitalize',
        },
      },
      MuiDialog: {
        paper: {
          margin: '32px 16px',
        },
        paperFullWidth: {
          width: 'calc(100% - 32px)',
        },
      },
      MuiBreadcrumbs: {
        li: {
          '& .MuiTypography-colorPrimary': {
            color: secondaryColor,
          },
        },
      },
    },
  }

  return {
    theme: createTheme(baseThemeOptions),
    ...skin,
  }
}
