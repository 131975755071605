import { ClassConstructor } from 'class-transformer'
import { ContentItem, ContentItemType } from './ContentItem'
import { FileContentItem } from './FileContentItem'
import { LinkContentItem } from './LinkContentItem'
import { RichTextContentItem } from './RichTextContentItem'
import { VideoContentItem } from './VideoContentItem'

export const contentItemTypes = new Map<
  ContentItemType,
  ClassConstructor<ContentItem> & { fromData(data): ContentItem }
>([
  [ContentItemType.RICH_TEXT, RichTextContentItem],
  [ContentItemType.LINK, LinkContentItem],
  [ContentItemType.VIDEO, VideoContentItem],
  [ContentItemType.FILE, FileContentItem],
])
